import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Backdrop, Box, CircularProgress, Stack, Typography, useMediaQuery } from '@mui/material'
import DropdownBox from '../InvestorOnly/Component/DropdownBox'
import { useSelector, useDispatch } from "react-redux";
import { GetApiParam, updateApi } from '../Api/Api';
import { updateStep } from "../features/auth/tooltipSlice"


export default function DealTerms({ dealTerms, fromPage, isLoan, isParcel, isSale, isFraction, isFundFraction }) {
    const [tolPosition, setTolPosition] = useState(0)
    const isXs = useMediaQuery('(max-width:600px)');
    const step = useSelector((state) => {
        if (isLoan) {
            return state.tooltipSlice.loan_step_number
        } else if (isFraction) {
            return state.tooltipSlice.re_step_number
        } else if (isFundFraction) {
            return state.tooltipSlice.funds_step_number
        }
    }
    );
    const isActive = useSelector((state) => state.tooltipSlice.active_how_it_work);

    const [loading, setLoading] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);

    // Get the dispatch function from the Redux store
    const dispatch = useDispatch();

    const localData = localStorage.getItem("user_data")
        ? JSON.parse(localStorage.getItem("user_data"))
        : "";


    useEffect(() => {
        if ((tolPosition > 13 || tolPosition <= 12) && isLoan) {
            setLoading(false)
        } else if ((tolPosition > 11 || tolPosition <= 10) && isFraction) {
            setLoading(false)
        } else if ((tolPosition > 9 || tolPosition <= 8) && isFundFraction) {
            setLoading(false)
        }
    }, [tolPosition])

    useEffect(() => {
        async function getStep() {
            try {
                setLoadingApi(true)
                const data = await GetApiParam("/user/currentTooltipPosition", { "user_id": localData.id });
                if (data?.data?.data && isActive) {
                    if (data.data?.data?.loan_step_number == 13 && isLoan) {
                        setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                        setLoading(true)
                        scrollToPosition("scroll-to-dealterm");
                    }
                    else if ((data.data?.data?.re_step_number == 11) && isFraction) {
                        setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                        setLoading(true)
                        scrollToPosition("scroll-to-dealterm");
                    }
                    else if ((data.data?.data?.funds_step_number == 9) && isFundFraction) {
                        setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                        setLoading(true)
                        scrollToPosition("scroll-to-dealterm");
                    }
                }
                setLoadingApi(false)
            } catch (error) {
                console.log('error', error)
                setLoadingApi(false)
            }
        }
        // Call getStep initially
        const timer = setTimeout(() => {
            if (localData && localData?.id) {
                getStep();
            }
        }, 100);
        return () => clearTimeout(timer)
    }, [step, isActive])

    async function setStep(tolPositionData) {
        try {
            let body = { "loan_step_number": tolPositionData };
            if (isFraction) {
                body = { "re_step_number": tolPositionData };
            } else if (isFundFraction) {
                body = { "funds_step_number": tolPositionData };
            }
            const data = await updateApi("/user/updateUser/" + localData?.id, body);
            if (data?.data?.data) {
                if (data.data?.data?.loan_step_number == 13 && isLoan) {
                    setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                    setLoading(true)
                }
                else if ((data.data?.data?.re_step_number == 11) && isFraction) {
                    setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                    setLoading(true)
                } else if ((data.data?.data?.funds_step_number == 9) && isFundFraction) {
                    setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                    setLoading(true)
                }
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    const scrollToPosition = (findId) => {
        setTimeout(() => {
            const element = document.getElementById(`${findId}`);
            if (element) {
                const offset = 0;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        }, 200);
    }
    const handleTolPosition = (data) => {
        setTolPosition(pre => pre + data)
        setStep(tolPosition + data)
        if (isLoan)
            dispatch(updateStep({ key: 'loan_step_number', value: tolPosition + data }));
        if (isSale)
            dispatch(updateStep({ key: 'sale_step_number', value: tolPosition + data }));
        if (isParcel)
            dispatch(updateStep({ key: 'parcel_step_number', value: tolPosition + data }));
        if (isFraction)
            dispatch(updateStep({ key: 're_step_number', value: tolPosition + data }));
        if (isFundFraction)
            dispatch(updateStep({ key: 'funds_step_number', value: tolPosition + data }));
        if (data < 0) {
            scrollToPosition("scroll-to-assetdetails");
        } else {
            scrollToPosition("scroll-to-repayment-term");
        }
    }

    return (
        <>
            {loading ?
                <Box className="pd-sidebar-dealTerms" id="scroll-to-dealterm" sx={{
                    position: 'relative', backgroundColor: "#06487E",
                    color: "white", borderRadius: "8px", zIndex: "3",
                    padding: '4px'
                }}>
                    <DropdownBox
                        render={
                            <Box>
                                <Stack className="pd-sidebar-heading" direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                    <Typography className="title" component={'h4'}>Deal Terms</Typography>
                                </Stack>
                                {fromPage && fromPage === "llcDetails" ?
                                    <Box className="pd-sidebar-detailsList" mt={0.5}>
                                        {
                                            dealTerms?.map((item, index) => {
                                                if (item.value) {
                                                    let detailsData = []
                                                    if (item.title === "Type of Capital") {
                                                        detailsData = JSON.parse(item.value) || []
                                                    } else {
                                                        detailsData.push(item.value)
                                                    }
                                                    return (
                                                        <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={index}>
                                                            <Typography className='title'>{item.title}</Typography>
                                                            <Box sx={{ marginLeft: '32px !important', textAlign: 'right' }}>
                                                                {
                                                                    detailsData?.map((items, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <Box component={"span"} className='subtitle'>{items}</Box>
                                                                            {index < detailsData.length - 1 && ', '}
                                                                        </React.Fragment>
                                                                    ))
                                                                }
                                                            </Box>
                                                        </Stack>
                                                    )
                                                }
                                            })
                                        }
                                    </Box>
                                    :
                                    fromPage && fromPage === "fundDetails" ?
                                        <Box className="pd-sidebar-detailsList" mt={0.5}>
                                            {
                                                dealTerms?.map((item, index) => {
                                                    let detailsData = []
                                                    if (item.value) {
                                                        if (item.title === "No. of Share Classes") {
                                                            detailsData = item?.value?.replace(/"/g, '')
                                                            return (
                                                                <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={index}>
                                                                    <Typography className='title'>{item.title}</Typography>
                                                                    <Box>
                                                                        <Typography className='subtitle' key={index}>{`${item.num_of_class} ${item.num_of_class > 1 ? "classes" : "class"}`}</Typography>
                                                                    </Box>
                                                                </Stack>
                                                            )
                                                        }

                                                        else {
                                                            detailsData.push(item.value)
                                                            return (
                                                                <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={index}>
                                                                    <Typography className='title'>{item.title}</Typography>
                                                                    <Box>
                                                                        {
                                                                            detailsData?.map((items, index) => {
                                                                                return <Typography className='subtitle' key={index}>{items}</Typography>
                                                                            })
                                                                        }
                                                                    </Box>
                                                                </Stack>
                                                            )
                                                        }
                                                    }
                                                })
                                            }
                                        </Box>
                                        :
                                        <Box className="pd-sidebar-detailsList" mt={0.5}>
                                            {
                                                dealTerms?.map((item, index) => {
                                                    return (
                                                        <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={index}>
                                                            <Typography className='title'>{item?.title}</Typography>
                                                            <Typography className='subtitle'>{item?.value}</Typography>
                                                        </Stack>
                                                    )
                                                })
                                            }
                                        </Box>
                                }
                            </Box>
                        }
                        setState={handleTolPosition}
                        description={`The key terms of the ${isLoan ? "loan request" : isFundFraction ? 'fund request' : 'fraction'}`} position={"left"}
                    />
                </Box>
                :
                <Box className="pd-sidebar-dealTerms" id="scroll-to-dealterm">
                    <Stack className="pd-sidebar-heading" direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                        <Typography className="title" component={'h4'}>Deal Terms</Typography>

                    </Stack>
                    {fromPage && fromPage === "llcDetails" ?
                        <Box className="pd-sidebar-detailsList" mt={0.5}>
                            {
                                dealTerms?.map((item, index) => {
                                    if (item.value) {
                                        let detailsData = []
                                        if (item.title === "Type of Capital") {
                                            detailsData = JSON.parse(item.value) || []
                                        } else {
                                            detailsData.push(item.value)
                                        }
                                        return (
                                            <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={index}>
                                                <Typography className='title'>{item.title}</Typography>
                                                <Box sx={{ marginLeft: '32px !important', textAlign: 'right' }}>
                                                    {
                                                        detailsData?.map((items, index) => (
                                                            <React.Fragment key={index}>
                                                                <Box component={"span"} className='subtitle'>{items}</Box>
                                                                {index < detailsData.length - 1 && ', '}
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </Box>
                                            </Stack>
                                        )
                                    }
                                })
                            }
                        </Box>
                        :
                        fromPage && fromPage === "fundDetails" ?
                            <Box className="pd-sidebar-detailsList" mt={0.5}>
                                {
                                    dealTerms?.map((item, index) => {
                                        let detailsData = []
                                        if (item.value) {
                                            if (item.title === "No. of Share Classes") {
                                                detailsData = item?.value?.replace(/"/g, '')
                                                return (
                                                    <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={index}>
                                                        <Typography className='title'>{item.title}</Typography>
                                                        <Box>
                                                            <Typography className='subtitle' key={index}>{`${item.num_of_class} ${item.num_of_class > 1 ? "classes" : "class"}`}</Typography>
                                                        </Box>
                                                    </Stack>
                                                )
                                            }

                                            else {
                                                detailsData.push(item.value)
                                                return (
                                                    <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={index}>
                                                        <Typography className='title'>{item.title}</Typography>
                                                        <Box>
                                                            {
                                                                detailsData?.map((items, index) => {
                                                                    return <Typography className='subtitle' key={index}>{items}</Typography>
                                                                })
                                                            }
                                                        </Box>
                                                    </Stack>
                                                )
                                            }
                                        }
                                    })
                                }
                            </Box>
                            :
                            <Box className="pd-sidebar-detailsList" mt={0.5}>
                                {
                                    dealTerms?.map((item, index) => {
                                        return (
                                            <Stack className="detailsList" direction="row" spacing={1} justifyContent="space-between" alignItems="center" key={index}>
                                                <Typography className='title'>{item?.title}</Typography>
                                                <Typography className='subtitle'>{item?.value}</Typography>
                                            </Stack>
                                        )
                                    })
                                }
                            </Box>
                    }
                </Box>
            }
            {loading &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => 2 }}
                    open={loading}
                >
                </Backdrop>
            }
            {loadingApi &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => 2 }}
                    open={loadingApi}
                >
                    <CircularProgress />
                </Backdrop>
            }
        </>
    )
}

DealTerms.propTypes = {
    editable: PropTypes.bool,
    dealTerms: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string || PropTypes.number,
    }))
}
