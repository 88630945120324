import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Box, Button, InputAdornment, IconButton, Typography, FormGroup, Checkbox, TextField, FormControl, FormControlLabel, RadioGroup, Radio, Stack, Input, } from "@mui/material";
import { ArrowBack, Clear } from '@mui/icons-material';
import "./StaticModal.css";

export default function LawFirmForm({ handleSubmitParent, goBack }) {
    const location = useLocation();
    const fragment = location.hash;
    const [experienceWithBlock, setExperienceWithBlock] = useState("Extensive");
    const [collaborationModel, setCollaborationModel] = useState("Direct client engagement");
    const [legalExpertiseArea, setLegalExpertiseArea] = useState([]);
    const [error1, setError1] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [customText, setCustomText] = useState("");
    const [otherValue, setOtherValue] = useState('');
    const [errorMsg, setErrorMsg] = useState(false);
    const [errorMsgOther, setErrorMsgOther] = useState(false);

    const [textFields, setTextFields] = useState({
        attorney_profile: { value: '', count: 0, checkError: false },
        main_interest: { value: '', count: 0, checkError: false },
        services_offered: { value: '', count: 0, checkError: false },
        additional_information: { value: '', count: 0, checkError: false },
    });


    const handleOtherInputChange = (event) => {
        if (event.target.value) {
            setErrorMsgOther(false)
        } else {
            setErrorMsgOther(true)
        }
        setOtherValue(event.target.value);
    };

    const LegalExpertiseAreas = ["Blockchain & Cryptocurrency", "Intellectual Property", "Real Estate", 'Corporate Law', 'Tax Law', 'Securities Law', 'Other:'];

    const handleCheckboxInterestChange = (item) => {
        if (item === "Other:") {
            // Handle "Others:" being checked
            const isOthersChecked = legalExpertiseArea.includes(item);
            if (isOthersChecked) {
                // If "Others:" was checked and is now unchecked, remove it from the selected items
                const updatedItems = legalExpertiseArea.filter(
                    (checkedItem) => checkedItem !== item
                );
                setLegalExpertiseArea(updatedItems);
            } else {
                // If "Others:" is checked, set it in the selected items
                setLegalExpertiseArea([...legalExpertiseArea, item]);
            }
        } else {
            // Handle other items being checked
            if (legalExpertiseArea.includes(item)) {
                // If the item was checked and is now unchecked, remove it from the selected items
                const updatedItems = legalExpertiseArea.filter(
                    (checkedItem) => checkedItem !== item
                );
                setLegalExpertiseArea(updatedItems);
            } else {
                // If the item is checked, set it in the selected items
                setLegalExpertiseArea([...legalExpertiseArea, item]);
            }
        }
    };

    const isAssetFormValid = legalExpertiseArea.length > 0;

    useEffect(() => {
        if (fragment) {
            scrollToSection(fragment.replace("#", ""));
        }
    }, [fragment]);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const onSubmit = (data) => {
        let userData = "";
        // userData = data
        if (!customText && legalExpertiseArea.includes("Other:")) {
            setErrorMsg(true);
        }
        if (!otherValue && collaborationModel === "Other (Please specify):") {
            setErrorMsgOther(true);
        }
        if (legalExpertiseArea.length > 0 && textFields.attorney_profile.count > 0 && textFields.main_interest.count > 0 && textFields.services_offered.count > 0 && textFields.additional_information.count > 0 && (customText && legalExpertiseArea.includes("Other:") || !legalExpertiseArea.includes("Other:")) && (otherValue && collaborationModel === "Other (Please specify):" || (collaborationModel !== "Other (Please specify):"))) {
            userData = {}

            userData.legal_expertise_area = legalExpertiseArea;
            userData.collaboration_model = collaborationModel;
            userData.experience_with_blockchain = experienceWithBlock;
            userData.main_interest = textFields.main_interest.value;
            userData.services_offered = textFields.services_offered.value;
            userData.attorney_profile = textFields.attorney_profile.value;
            // other 
            if (legalExpertiseArea.includes("Other:"))
                userData.expertise_area_other_asset = customText;
            if (collaborationModel === "Other (Please specify):")
                userData.collaboration_other_asset = otherValue;

            userData.additional_information = textFields.additional_information.value;
            userData.law_attorney_name = data?.law_attorney_name;
            userData.years_of_legal_practice = data?.years_of_legal_practice;
            userData.jurisdictions_covered = data?.jurisdictions_covered;

        }
        if (userData) {
            handleSubmitParent(userData);
        }
    };


    useEffect(() => {
        if (legalExpertiseArea.length === 0 && isSubmitted === true) {
            setError1(true);
        } else if (error1 && legalExpertiseArea.length > 0) {
            setError1(false);
        }
    }, [isSubmitted, legalExpertiseArea])

    /**
     * handleChange function for the text fields
     * @param {*} e
     * @param {string} fieldIdentifier - Identifier for the text field
     */
    const handleChangeText = (e, fieldIdentifier) => {
        const textData = e.target.value;

        if (textData.length <= 200) {
            setTextFields((prevFields) => ({
                ...prevFields,
                [fieldIdentifier]: { value: textData, count: textData.length, checkError: textData.length === 0 ? true : false },
            }));
        }
    };

    /**
     * Clear Text field on close icon click
     * @param {string} fieldIdentifier - Identifier for the text field
     */
    const handleClear = (fieldIdentifier) => {
        setTextFields((prevFields) => ({
            ...prevFields,
            [fieldIdentifier]: { value: '', count: 0, checkError: false },
        }));
    };

    const handleCustomTextChange = (event) => {
        // Update the custom text state when the text field value changes
        if (event.target.value) {
            setErrorMsg(false);
        } else {
            setErrorMsg(true);
        }
        setCustomText(event.target.value);
    };

    return (
        <Box className="investor-modal-container" sx={{ ml: { md: '50px', sm: '40px', xs: '5px' }, maxHeight: '70vh', overflowY: 'auto', marginTop: { md: '-75px' } }} >
            <Box className="staticHomepages provider-details-form" sx={{ maxWidth: '730px', mx: 'auto', mt: 2, }}>
                <Box p={{ xs: 2, sm: 2 }} sx={{ maxWidth: "730px" }} mt={{ xs: 2, sm: 3.5 }}
                >
                    <Typography className="title text-blue-900 font-36" color={"#fff"} component={"h2"}>
                        Join us as an Law Firm / Attorney
                    </Typography>
                    <Box mt={{ xs: 2, sm: 3.5 }} component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Typography className="font-18 text-white-900" mb={2}>
                            Please submit the information below so we can optimize your experience as a Law Firm / Attorney on the RACE platform.
                        </Typography>

                        {/* Law Firm/Attorney Name */}
                        <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }}>
                            <Box
                                className="form-group label-field staticProposerModel" mt={2} sx={{ width: "fit-" }}>
                                <TextField
                                    label={'Law Firm/Attorney Name'}
                                    placeholder="Enter your Law Firm/Attorney name"
                                    InputProps={{
                                        style: { color: "#fff" },
                                    }}
                                    InputLabelProps={{
                                        style: { color: "#fff" }
                                    }}
                                    name="law_attorney_name"
                                    {...register("law_attorney_name", {
                                        required: "Law Firm/Attorney Name is required.",
                                    })}
                                    error={Boolean(errors.law_attorney_name)}
                                    helperText={errors.law_attorney_name?.message}
                                />
                            </Box>
                        </Box>

                        {/* Legal Expertise */}
                        <Typography className="font-18 text-white-900" mt={3}>
                            Legal Expertise Area(s): (Select all that apply)
                        </Typography>
                        <Stack direction="row" spacing={0} flexWrap={'wrap'}>
                            {LegalExpertiseAreas.map((item, index) => {
                                return (
                                    <FormGroup key={index}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={legalExpertiseArea.includes(item)}
                                                    onChange={() => handleCheckboxInterestChange(item)}
                                                    style={{ color: "#fff" }}
                                                />
                                            }
                                            label={<Box display={"flex"} alignItems={"center"} >
                                                <Box component={'span'}>{item}</Box>
                                                {item === "Other:" ? (
                                                    // <Box ml={1} className="staticProposerModel" sx={{ display: { xs: 'none', md: 'block', sm: 'block' } }}>
                                                    //     <TextField
                                                    //         placeholder="Please type the other"
                                                    //         value={customText}
                                                    //         InputLabelProps={{
                                                    //             style: { color: "#fff" }, // Set the label text color to white
                                                    //         }}
                                                    //         InputProps={{
                                                    //             style: { color: "#fff" }, // Set the input text color to white
                                                    //         }}
                                                    //         sx={{ width: '130%' }}
                                                    //         onChange={handleCustomTextChange}
                                                    //     ></TextField>
                                                    // </Box>
                                                    <Box display={{ xs: 'flex' }}>
                                                        <Input
                                                            className="dashedTextField"
                                                            id="other-label"
                                                            style={{ borderBottom: "2px dashed #fff", marginLeft: "10px", color: "#fff" }}
                                                            underline={false}
                                                            value={customText}
                                                            onChange={handleCustomTextChange}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box></Box>
                                                )}
                                            </Box>}
                                            style={{ color: "#fff" }}
                                        ></FormControlLabel>
                                        {/* <Box display={"flex"} alignItems={"center"} sx={{ display: { md: 'none', xs: 'block', sm: 'none' } }} >
                                          
                                            {item === "Other:" ? (
                                                <Box ml={1} className="staticProposerModel" >
                                                    <TextField
                                                        placeholder="Please type the other "
                                                        value={customText}
                                                        InputLabelProps={{
                                                            style: { color: "#fff" }, // Set the label text color to white
                                                        }}
                                                        InputProps={{
                                                            style: { color: "#fff" }, // Set the input text color to white
                                                        }}

                                                    // onChange={handleCustomTextChange}
                                                    ></TextField>
                                                </Box>
                                            ) : (
                                                <Box></Box>
                                            )}
                                        </Box> */}
                                    </FormGroup>
                                );
                            })}
                            <Typography className="font-12" color={"red"} sx={{ mt: { md: '60px', xs: '5px' }, ml: { md: "-200px", xs: '5px' } }}>
                                {errorMsg ? "Other title is required." : ""}
                            </Typography>
                        </Stack>
                        {error1 === true && <Typography style={{ color: '#d32f2f', fontSize: '12px', margin: '3px 0px 0px 14px' }}>{'Legal Expertise Area(s) is required'}</Typography>}

                        {/* Years of Legal Practice */}
                        <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }}>
                            <Box
                                className="form-group label-field staticProposerModel"
                                mt={2}
                                sx={{ width: "fit-" }}
                            >
                                <TextField
                                    label={'Years of Legal Practice'}
                                    placeholder="Enter no. of years here"
                                    InputProps={{
                                        style: { color: "#fff" },
                                    }}
                                    InputLabelProps={{
                                        style: { color: "#fff" }
                                    }}
                                    name="years_of_legal_practice"
                                    {...register("years_of_legal_practice", {
                                        required: "Years of Legal Practice is required.",
                                        pattern: {
                                            value: /^[0-9]*\.?[0-9]*$/, // Allows only numeric values with an optional dot for decimals
                                            message: "Please enter a valid number.",
                                        },
                                    })}
                                    error={Boolean(errors.years_of_legal_practice)}
                                    helperText={errors.years_of_legal_practice?.message}
                                />
                            </Box>
                        </Box>

                        {/* Jurisdictions Covered */}
                        <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }}>
                            <Box
                                className="form-group label-field staticProposerModel"
                                mt={2}
                                sx={{ width: "fit-" }}
                            >
                                <TextField
                                    label={'Jurisdictions Covered'}
                                    placeholder="Indicate the jurisdictions where you are licensed to practice law"
                                    InputProps={{
                                        style: { color: "#fff" },
                                    }}
                                    InputLabelProps={{
                                        style: { color: "#fff" }
                                    }}
                                    name="jurisdictions_covered"
                                    {...register("jurisdictions_covered", {
                                        required: "Jurisdictions Covered is required.",
                                    })}
                                    error={Boolean(errors.jurisdictions_covered)}
                                    helperText={errors.jurisdictions_covered?.message}
                                />
                            </Box>
                        </Box>

                        {/*  Experience with Blockchain and Digital Assets */}
                        <Box>
                            <Box>
                                <Typography
                                    className="font-18 text-white-900"
                                    whiteSpace={{ lg: "nowrap", md: "normal" }}
                                    mr={2}
                                    mt={3}
                                >
                                    Experience with Blockchain and Digital Assets
                                </Typography>
                                <FormControl sx={{ marginTop: "2px" }} >
                                    <RadioGroup
                                        className="darkCheckbox text-white-900"
                                        row
                                        sx={{ flexWrap: "nowrap", display: '-webkit-box' }}
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        defaultValue={"Extensive"}
                                    >
                                        <FormControlLabel
                                            value="Extensive"
                                            onChange={() => setExperienceWithBlock("Extensive")}
                                            control={<Radio style={{ color: "#fff" }} />}
                                            label="Extensive"
                                        />
                                        <FormControlLabel
                                            value="Moderate"
                                            onChange={() => setExperienceWithBlock("Moderate")}
                                            control={<Radio style={{ color: "#fff" }} />}
                                            label="Moderate"
                                        />
                                        <FormControlLabel
                                            value="Limited"
                                            onChange={() => setExperienceWithBlock("Limited")}
                                            control={<Radio style={{ color: "#fff" }} />}
                                            label="Limited"
                                        />
                                        <FormControlLabel
                                            value="None (Interested in learning)"
                                            onChange={() => setExperienceWithBlock("None (Interested in learning)")}
                                            control={<Radio style={{ color: "#fff" }} />}
                                            label="None (Interested in learning)"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>

                        {/*   Law Firm/Individual Attorney Profile */}
                        <Typography className="font-18 text-white-900" mt={3}>
                            Law Firm/Individual Attorney Profile
                        </Typography>
                        <Box
                            className="form-group label-field staticProposerModel"
                            mt={2}
                            sx={{ width: "fit-" }}
                        >
                            <TextField
                                multiline
                                value={textFields.attorney_profile.value}
                                onChange={(e) => handleChangeText(e, 'attorney_profile')}
                                minRows={2}
                                placeholder="Briefly describe your firm's or your individual expertise and experience in the legal field"
                                InputProps={{
                                    style: { color: "#fff" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClear('attorney_profile')}
                                                size="small"
                                            >
                                                <Clear className='multileClearText-btn' />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                            />
                            {(textFields.attorney_profile.checkError === true || (isSubmitted && textFields.attorney_profile.count === 0)) ? <Typography style={{ color: '#d32f2f', fontSize: '12px' }} padding={'4px 16px'}>{'Law Firm/Individual Attorney Profile is required'}</Typography> :
                                <Typography padding={'4px 16px'}>{textFields.attorney_profile.count}/200</Typography>
                            }

                        </Box>

                        {/* Services Offered */}
                        <Typography className="font-18 text-white-900" >
                            Services Offered
                        </Typography>
                        <Box
                            className="form-group label-field staticProposerModel"
                            mt={2}
                            sx={{ width: "fit-" }}
                        >
                            <TextField
                                minRows={2}
                                multiline
                                value={textFields.services_offered.value}
                                onChange={(e) => handleChangeText(e, 'services_offered')}
                                placeholder="Outline the legal services you can provide to users on the RACE platform"
                                InputProps={{
                                    style: { color: "#fff" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClear('services_offered')}
                                                size="small"
                                            >
                                                <Clear className='multileClearText-btn' />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                            />
                            {(textFields.services_offered.checkError === true || (isSubmitted && textFields.services_offered.count === 0)) ? <Typography padding={'4px 16px'} style={{ color: '#d32f2f', fontSize: '12px' }}>{'Services Offered is required'}</Typography> :
                                <Typography padding={'4px 16px'}>{textFields.services_offered.count}/200</Typography>
                            }
                        </Box>

                        {/* main interests */}
                        <Typography className="font-18 text-white-900" >
                            What are your main interests in the RACE platform? How do you envision your role?
                        </Typography>
                        <Box
                            className="form-group label-field staticProposerModel"
                            mt={2}
                            sx={{ width: "fit-" }}
                        >
                            <TextField
                                placeholder="Type here"
                                multiline
                                minRows={2}
                                value={textFields.main_interest.value}
                                onChange={(e) => handleChangeText(e, 'main_interest')}
                                InputProps={{
                                    style: { color: "#fff" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClear('main_interest')}
                                                size="small"
                                            >
                                                <Clear className='multileClearText-btn' />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                            />
                            {(textFields.main_interest.checkError === true || (isSubmitted && textFields.main_interest.count === 0)) ? <Typography style={{ color: '#d32f2f', fontSize: '12px' }} padding={'4px 16px'}>{'Main interests is required'}</Typography> :
                                <Typography padding={'4px 16px'}>{textFields.main_interest.count}/200</Typography>
                            }
                        </Box>

                        {/*  Preferred Collaboration Model */}
                        <Box >
                            <Box>
                                <Typography
                                    className="font-18 text-white-900"
                                    whiteSpace={{ lg: "nowrap", md: "normal" }}
                                    mr={2}
                                    mt={3}
                                >
                                    Preferred Collaboration Model
                                </Typography>
                                <FormControl sx={{ marginTop: "2px" }} >
                                    <RadioGroup
                                        className="darkCheckbox text-white-900"
                                        row
                                        sx={{ flexWrap: "nowrap", display: '-webkit-box' }}
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        defaultValue={"Direct client engagement"}
                                    >
                                        <FormControlLabel
                                            value="Direct client engagement"
                                            onChange={() => setCollaborationModel("Direct client engagement")}
                                            control={<Radio style={{ color: "#fff" }} />}
                                            label="Direct client engagement"
                                        />
                                        <FormControlLabel
                                            value="Partnership with other firms"
                                            onChange={() => setCollaborationModel("Partnership with other firms")}
                                            control={<Radio style={{ color: "#fff" }} />}
                                            label="Partnership with other firms"
                                        />
                                        <FormControlLabel
                                            value="Advisory role"
                                            onChange={() => setCollaborationModel("Advisory role")}
                                            control={<Radio style={{ color: "#fff" }} />}
                                            label="Advisory role"
                                        />
                                        <FormControlLabel
                                            value="Other (Please specify):"
                                            onChange={() => setCollaborationModel("Other (Please specify):")}
                                            control={<Radio style={{ color: "#fff" }} />}
                                            label={<Box display={{ xs: 'flex' }}>
                                                <span >
                                                    Other (Please specify):
                                                </span >
                                                <Input
                                                    disabled={collaborationModel === "Other (Please specify):" ? false : true}
                                                    className="dashedTextField"
                                                    id="other-label"
                                                    style={{ borderBottom: "2px solid #94A3B8", marginLeft: "10px", color: "#fff" }}
                                                    underline={false}
                                                    value={otherValue}
                                                    onChange={handleOtherInputChange}
                                                />
                                            </Box>}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <Typography className="font-12" color={"red"} padding={'4px 0px 0px 16px'}>
                                    {errorMsgOther ? "Other title is required." : ""}
                                </Typography>
                            </Box>
                        </Box>

                        {/* Additional Information */}
                        <Typography className="font-18 text-white-900" mt={3}>
                            Additional Information
                        </Typography>
                        <Box
                            className="form-group label-field staticProposerModel"
                            mt={2}
                            sx={{ width: "fit-" }}
                        >
                            <TextField
                                placeholder="Any other information you wish to provide or questions about the RACE platform."
                                multiline
                                minRows={2}
                                value={textFields.additional_information.value}
                                onChange={(e) => handleChangeText(e, 'additional_information')}
                                InputProps={{
                                    style: { color: "#fff" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClear('additional_information')}
                                                size="small"
                                            >
                                                <Clear className='multileClearText-btn' />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                            />
                            {(textFields.additional_information.checkError === true || (isSubmitted && textFields.additional_information.count === 0)) ? <Typography style={{ color: '#d32f2f', fontSize: '12px' }} padding={'4px 16px'}>{'Additional Information is required'}</Typography> :
                                <Typography padding={'4px 16px'}>{textFields.additional_information.count}/200</Typography>
                            }
                        </Box>

                        {/* submit button */}
                        <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 6 }}>
                            <Button
                                fullWidth
                                className="btn-rounded btn-large btn-blue-600 btn-text-white"
                                type="submit"
                                onClick={() => setIsSubmitted(true)}
                            >
                                Submit and join waitlist
                            </Button>
                        </Box>
                        <Button sx={{
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            marginTop: '-25px',
                            '@media screen and (max-width: 600px)': {
                                // Styles for screens smaller than 600px (adjust the value as needed)
                                width: '100%', // Make the button full width on smaller screens
                                marginTop: '0', // Adjust margin top for smaller screens
                            },
                        }} onClick={goBack}><ArrowBack style={{ marginRight: '13px' }} /> <span>Go Back</span></Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
