import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Box, Grid, Typography, TextField, Button, Stack, InputAdornment, IconButton, MenuItem, FormControl, RadioGroup, FormControlLabel, Radio, Autocomplete } from "@mui/material";
import { NewLogo } from "../../Components/Images/Images";
import { postApi } from "../../Api/Api";
import SnackbarAlert from "../../Components/Common/SnackbarAlert";
import { ArrowBack, PhoneIphone } from "@mui/icons-material";

//country code file
import CountryCodes from "../../Data/countries.json";
import { isSupportedCountry, isValidPhoneNumber } from "libphonenumber-js";
import Flag from "react-world-flags";
import { SortCountries } from "../../Components/Common/SortCountries";

const country_codes = CountryCodes;

export default function ForgotPassword() {

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbar, setSnackbar] = useState({ severity: 'success', message: "" });
  const [recoverBy, setRecoverBy] = useState('email');

  /**
   * Form configuration using the useForm hook.
   */
  const { register, handleSubmit, formState: { errors }, control, watch } = useForm({
    defaultValues: {
      email: "",
      // countryCode: country_codes[0].code,
      phoneNumber: "",
    }
  });
  const countryCodeNum = watch("countryCode");
  const [countryIso, setCountryIso] = useState("");
  const [sortedCountryCodes, setSortedCountryCodes] = useState([]);


  useEffect(() => {
    const sorted = SortCountries([...CountryCodes]);
    setSortedCountryCodes(sorted);
  }, []);

  /**
   *  validate phone number with libphonenumber-js library
   * @param {string} value  phone number
   * @param {string} country_iso  country code
   * @returns
   */
  const validatePhoneNumber = (value) => {
    const res = isSupportedCountry(countryIso);
    return isValidPhoneNumber(`+${countryCodeNum}${value}`, countryIso);
  };

  /**
   * Function to handle the form submission.
   * Initiates the password recovery process based on the selected recovery method.
   * @param {Object} data - Form data submitted by the user.
   */
  const onSubmit = (data) => {
    if (recoverBy === 'email') {
      handleClick({ email: data.email });
    } else {
      handleClick({ phoneNumber: data.phoneNumber, countryCode: data.countryCode });
    }
  }

  /**
   * Function to handle the "Forget Password" action.
   * Sends a password reset link to the specified email.
   * @param {string} email - The email address for which to send the password reset link.
   */
  const handleClick = (payload) => {
    const body = recoverBy === 'email' ? { email: payload.email, type: "email", is_dic: false } : { phone_number: `${payload.phoneNumber}`, country_code: `+${payload.countryCode}`, type: "phone_number", is_dic: false };

    postApi(`/user/forget_password`, body).then((response) => {
      if (response?.data?.code === 200) {
        setOpenSnackbar(true);
        setSnackbar({ severity: 'success', message: recoverBy === 'email' ? "Password reset link sent to email successfully" : "Password reset link sent to phone successfully" });
      } else {
        setOpenSnackbar(true);
        setSnackbar({ severity: 'error', message: response?.data?.message });
      }
    });
  };

  /**
  * handles snackbar close
  * @param {*} event 
  * @param {*} reason 
  * @returns 
  */
  const handleClose = (event, reason) => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Box className="forgotPassword-page auth-page">
        <Box className="auth-section">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Box className="auth-left">
                <Box className="content">
                  <Box className="heading-wrap" textAlign={"left"}>
                    <Typography component="h1" className="title">
                      Supercharged Investments Powered By The Blockchain
                    </Typography>
                    <Typography component="p" className="sub-title">
                      Lower fees • Simple process • Crypto & fiat friendly
                    </Typography>
                  </Box>
                </Box>
                {/* Logo */}
                <Box className="auth-logo">
                  <Link to="/">
                    <Box component="img" src={NewLogo} alt="Race logo" />
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box className="auth-right">
                <Box className="backIcon-btn">
                  <Link component="a" to={-1}>
                    <ArrowBack />
                  </Link>
                </Box>
                <Box className="auth-form-wrap">
                  <Box className="heading-wrap">
                    <Typography component="h1" className="title">
                      Forgot your password?
                    </Typography>
                    <Typography component="p" className="sub-title">
                      Enter your email address below to reset your password
                    </Typography>
                  </Box>

                  <FormControl>
                    <RadioGroup
                      aria-labelledby="radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      row={true}
                      value={recoverBy}
                      onChange={(e) => setRecoverBy(e.target.value)}
                    >
                      {/* <FormControlLabel value="phone" control={<Radio />} label="Phone Number" /> */}
                      <FormControlLabel value="email" control={<Radio />} label="Email Address" />
                    </RadioGroup>
                  </FormControl>

                  {/* Start Form */}
                  <Box
                    className="auth-form"
                    mt={4}
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {
                      recoverBy === 'email' ?
                        <Box className="form-group label-field">
                          <TextField
                            type="email"
                            id="outlined-required"
                            label="Email Address"
                            name="email"
                            placeholder="Enter your email address"
                            {...register("email", {
                              required: "Email address is required.",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: "Please enter valid email address.",
                              },
                            })}
                            error={Boolean(errors.email)}
                            helperText={errors.email?.message}
                          />
                        </Box>
                        : // else phone number
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={5} md={4}>
                              <Controller
                                name="countryCode"
                                control={control}
                                defaultValue={sortedCountryCodes[0].code}
                                rules={{
                                  required: "Country Code is required",
                                }}
                                render={({ field: { onChange, value, ref } }) => (
                                  <Autocomplete
                                    options={sortedCountryCodes}
                                    getOptionLabel={(option) =>
                                      `+${option.code} (${option.iso})`
                                    }
                                    renderOption={(props, option) => (
                                      <li {...props}>
                                        {/* Use react-world-flags to display the country flag based on the ISO code */}
                                        <Flag
                                          code={option.iso_1 ? option.iso_1 : option.iso}
                                          style={{ width: '20px', marginRight: '8px' }}
                                          alt={`${option.country} flag`}
                                        />
                                        {` (${option.iso}) +${option.code}`}
                                      </li>
                                    )}
                                    value={
                                      sortedCountryCodes.find(
                                        (option) => (value == 1 ? (option.code === value && option.iso === countryIso) : option.code === value || option.iso === value)
                                      ) || null
                                    }
                                    onChange={(_, newValue) => {
                                      onChange(newValue ? newValue.code : "");
                                      setCountryIso(newValue ? newValue.iso : "");
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.code === value.code
                                    }
                                    filterOptions={(options, state) => {
                                      const inputValue = state.inputValue.toLowerCase();

                                      // Check for exact ISO code match first
                                      const isoMatch = options.find(option =>
                                        option.iso?.toLowerCase() === inputValue || option.country?.toLowerCase() === inputValue
                                      );

                                      if (isoMatch) {
                                        // Return an array with only the matched ISO code option
                                        return [isoMatch];
                                      } else {
                                        // Perform general filtering if no exact ISO code match
                                        return options.filter(option =>
                                          [option.country, option.iso, option.code].some(field =>
                                            field.toLowerCase().includes(inputValue)
                                          )
                                        );
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputRef={ref}
                                        label="Country Code"
                                        placeholder="Select Country Code"
                                        error={Boolean(errors.countryCode)}
                                        helperText={errors.countryCode?.message}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        InputProps={{
                                          ...params.InputProps,
                                          startAdornment: value ? (
                                            <Flag
                                              code={value == 44 ? 'GB' : sortedCountryCodes.find(option => option.code === value && option.iso === countryIso)?.iso}
                                              style={{ width: '20px', marginRight: '8px' }}
                                              alt=""
                                            />
                                          ) : null,
                                        }}
                                        inputProps={{
                                          ...params.inputProps,
                                          inputMode: "text",
                                          type: "text",
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>
                              <Box className="form-group label-field">
                                <TextField
                                  // autoComplete="off"
                                  id="outlined-required"
                                  label="Phone Number"
                                  type="text"
                                  pattern="[0-9]*"
                                  placeholder="Enter your phone number"
                                  name="phone_number"
                                  {...register("phoneNumber", {
                                    required: "Phone number is required.",
                                    // pattern: {
                                    //   value: /^\d{4,15}$/,
                                    //   message:
                                    //     "Please enter valid phone number in digits.",
                                    // },
                                    validate: (value) =>
                                      validatePhoneNumber(value) ||
                                      "Please enter valid phone number",
                                  })}
                                  // onChange={(e) => setPhoneNumber(e.target.value)}
                                  autoComplete="tel" // this line for auto-fill
                                  error={Boolean(errors.phoneNumber)}
                                  helperText={errors?.phoneNumber?.message}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PhoneIphone className="text-blue-600" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  onInput={(event) => {
                                    event.target.value = event.target.value
                                      .replace(/\D/g, "")
                                      .slice(0, 15);
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                          {/* <Stack
                            className="registeredNumber-des"
                            direction={{ xs: "column", sm: "row" }}
                            useFlexGap
                            flexWrap="wrap"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={3}
                          >
                            <Typography>Registered Phone Number</Typography>
                            <Typography>{validatePhoneNumber(phoneNumber, countryCodeNum) ? `xxxx-xxxx-${phoneNumber?.slice(-4)}` : "xxxx-xxxx-xxxx"}</Typography>
                          </Stack> */}
                        </>
                    }


                    <Box className="btn-wrap">
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        className="btn-rounded btn-blue-600"
                      >
                        {/* Send Recovery link via text message */}
                        Send Recovery Link
                      </Button>
                    </Box>
                    <Box className="authForgot-des" mt={4}>
                      <Typography>
                        For security, this link expires in 1 hour. Open it
                        immediately or wait until you're ready to complete the
                        password reset process before requesting a password reset.
                      </Typography>
                    </Box>
                  </Box>
                  {/* End Form */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <SnackbarAlert open={openSnackbar} message={snackbar.message} severity={snackbar.severity} onClose={handleClose} />
    </>
  );
}