import React, { createContext, useContext, useState , useEffect } from 'react';
import SnackbarAlert from '../Components/Common/SnackbarAlert';


// Create the Snackbar context
export const SnackbarContext = createContext({
    showSnackbar: /** @type {(message: string, severity?: 'info' | 'success' | 'error' | 'warning' , duration?: number) => void} */ (() => { }),
});

// Create a custom hook for using the Snackbar context
export function useSnackbar() {
    return useContext(SnackbarContext);
}

// Create the Snackbar provider
export function SnackbarProvider({ children }) {

    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "info", duration: 4000 });

    /**
     * To show the snackbar 
     * @param {string} message message to be shown in snackbar
     * @param {string} severity severity of snackbar
     */
    const showSnackbar = (message, severity = 'info', duration = 4000) => {
        setSnackbar({ open: true, message, severity, duration });
    };

    /**
     * To hide the snackbar
     */
    const hideSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            {children}
            {/* Render the MUI Snackbar here */}
            <SnackbarAlert open={snackbar.open} message={snackbar.message} duration={snackbar.duration} severity={snackbar.severity} onClose={hideSnackbar} />
        </SnackbarContext.Provider>
    );
}
