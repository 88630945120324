import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Stack, Avatar, Grid } from "@mui/material";
import { NewLogo, ftrDiscord, ftrFacebook, ftrLinkedin, ftrTwitter } from "../../Components/Images/Images";
import "./Footer.css";

// Component for rendering social media links
const SocialMediaLink = ({ to, src, alt }) => (
    <Box className="footer-nav-logo">
        <Link to={to} target="_blank" rel="noopener noreferrer">
            <Avatar variant="square" alt={alt} src={src} sx={{ width: 24, height: 24 }} />
        </Link>
    </Box>
);

// Component for rendering footer links
const FooterLink = ({ to, label, onClick }) => (
    <Box className="menu-item">
        <Link to={to} onClick={onClick}>
            {label}
        </Link>
    </Box>
);

// Component for rendering a column with multiple links
const FooterColumn = ({ title, links }) => (
    <Box className="footer-colum">
        <Typography className="title">{title}</Typography>
        <Box className="ftr-menus">
            {links.map((link, index) => (
                <FooterLink key={index} {...link} />
            ))}
        </Box>
    </Box>
);

// Main Footer component
const Footer = () => {
    // Array of social media links
    const socialMediaLinks = [
        { to: "https://www.facebook.com/login/", src: ftrFacebook, alt: "Facebook" },
        { to: "https://twitter.com/i/flow/login", src: ftrTwitter, alt: "Twitter" },
        { to: "https://www.linkedin.com/login", src: ftrLinkedin, alt: "Linkedin" },
        { to: "https://discord.com/login", src: ftrDiscord, alt: "Discord" },
    ];

    // Array of footer links
    const FooterLinks = [
        { to: "/terms-and-conditions", label: "Terms", onClick: topFunction },
        { to: "/privacy-policy", label: "Privacy", onClick: topFunction },
        { to: "/feedback", label: "Feedback", onClick: topFunction },
        { to: "/cookies", label: "Cookies", onClick: topFunction },
    ];

    // Configuration for Invest & Lend column
    const investAndLendColumn = {
        title: "Invest & Lend",
        links: [
            { to: "", label: "Sale", onClick: topFunction },
            { to: "", label: "Collateralization", onClick: topFunction },
            { to: "", label: "Parcelling", onClick: topFunction },
            { to: "", label: "Fractionalization", onClick: topFunction },
        ],
    };

    // Configuration for Invest & Lend subcategory
    const investAndLendSubcategory = {
        title: "Invest & Lend",
        links: [
            { to: "/asset-category/real-estate", label: "Real Estate", onClick: topFunction },
            { to: "/asset-category/art", label: "Art", onClick: topFunction },
            { to: "/asset-category/watch", label: "Watch", onClick: topFunction },
            { to: "/asset-category/gold", label: "Gold", onClick: topFunction },
            { to: "/asset-category/funds", label: "Funds", onClick: topFunction },
        ],
    };

    // Configuration for Other Features section
    const otherFeatures = {
        title: "Other Features",
        links: [
            // { to: "/project-proposer", label: "Propose A Project", onClick: topFunction },
            // { to: "/service-provider", label: "Provide A Service", onClick: topFunction },
            { to: "#", label: "Propose A Project", onClick: topFunction },
            { to: "#", label: "Provide A Service", onClick: topFunction },
            { to: "/investment-committee-dashboard", label: "Investment Committee Dashboard", onClick: topFunction },
        ],
    };

    // Configuration for Learn section
    const learningSection = {
        title: "Learn",
        links: [
            { to: "", label: "FAQ", onClick: topFunction },
            { to: "/guides", label: "Guides", onClick: topFunction },
        ],
    };

    // Scroll to the top of the page on component mount
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Function to scroll to the top of the page
    function topFunction() {
        document.documentElement.scrollTop = 0;
    }

    return (
        <Box className="main-footer" pt={{ xs: 4, sm: 8 }} pb={1} mb={-3} padding='8px 16px'>
            <Box className="container">
                <Box className="ftr-menu-section">
                    <Grid container>
                        <Grid item xs={12} sm={3}>
                            <FooterColumn {...investAndLendColumn} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FooterColumn {...investAndLendSubcategory} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FooterColumn {...otherFeatures} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FooterColumn {...learningSection} />
                        </Grid>
                    </Grid>
                </Box>
                <Box className="ftr-termsPrivacy-section" mb={3}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} sm={8}>
                            <Stack direction="row" alignItems="center" justifyContent={{ xs: "center", sm: "flex-start" }} mt={1.5}>
                                <Box className="ftr-logo" mr={2}>
                                    <Link to="/" onClick={topFunction}>
                                        <img src={NewLogo} alt="Logo" width={26} />
                                    </Link>
                                </Box>
                                <Stack className="ftr-termsPrivacy-menus" direction="row" alignItems="center" spacing={4} flexWrap="wrap">
                                    {FooterLinks.map((item, index) => (
                                        <FooterLink key={index} {...item} />
                                    ))}
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} sm={4}>
                            <Stack direction="row" alignItems="center" justifyContent={{ xs: "center", sm: "flex-end" }} spacing={{ xs: 2, sm: 3 }} flexWrap="wrap" mt={1.5}>
                                {socialMediaLinks.map((link, index) => (
                                    <SocialMediaLink key={index} {...link} />
                                ))}
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;
