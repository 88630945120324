import React, { useEffect, useState } from 'react';
import { Box, Fade, Grid, IconButton, InputAdornment, Modal, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import './Password.css';
import { updateApi } from '../../../Api/Api';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { logout } from "../../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useAccount, useDisconnect } from "wagmi";
import { RemoveRedEye, VisibilityOff } from '@mui/icons-material';
import CommonBackdropLoader from '../../../Components/Common/CommonBackdropLoader';

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiFilledInput-root': {
        backgroundColor: '#05131B',
        borderRadius: '8px',
    },
    '& .MuiFilledInput-root:hover': {
        backgroundColor: '#05131B',
    },
    '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: '#05131B',
    },
    '& .MuiInputBase-input.MuiFilledInput-input': {
        color: '#ffffff',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '16px',
    },
    '& .MuiInputBase-input.Mui-focused': {
        backgroundColor: '#05131B'
    },
    '& .MuiFilledInput-underline:after': {
        border: '1px solid #C5E4FF'
    },
    '& .MuiFilledInput-underline:hover:after': {
        border: '1px solid #C5E4FF'
    },
    '& label.MuiInputLabel-root': {
        color: '#64748B',
    },
}));

const Password = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [passwordError, setPasswordError] = useState({ status: false, message: '' });
    const userData = JSON.parse(localStorage.getItem('user_data'))
    const [open, setOpen] = useState(false);
    const [showPassword, setShowPassword] = useState({ 'new_password': false, 'confirm_password': false, 'current_password': false });
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const account = useAccount();
    const { disconnect } = useDisconnect();
    const [loading, setLoading] = useState(false);


    /**
* function to clear Local storage
*/
    const ClearLocalStorageData = () => {
        localStorage.removeItem("chatbot-thread-id")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("rememberMeToken")
        localStorage.removeItem("Diclogin")
        localStorage.removeItem("user_data_dic")
        localStorage.removeItem("user_data")
        localStorage.removeItem("token")
        localStorage.removeItem("login")
        localStorage.removeItem("kyc_modal_closed")
    }

    /**
     * handle user inputs and call change password api
     * @param {object} data 
     */
    const onSubmit = async (data) => {
        // Handle form submission
        if (data.new_password !== data.confirm_password) {
            // Display error message when passwords do not match
            setPasswordError({ status: true, message: "The new password and confirm password don't seem to match." });
        } else {
            setPasswordError({ status: false, message: '' });
            const body = {
                newpassword: data.new_password,
                oldpassword: data.current_password
            };
            try {
                setLoading(true)
                const res = await updateApi(`/user/changePassword/${userData.id}`, body);
                if (res.data.code === 200) {
                    // localStorage.clear();
                    ClearLocalStorageData()
                    setOpen(true)
                    // Reset the form after submission
                    reset({ current_password: '', new_password: '', confirm_password: '' });
                } else {
                    setPasswordError({ status: true, message: res.data.message });
                }
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
    };

    // Removing error message after 5 secs
    useEffect(() => {
        if (passwordError.status) {
            setTimeout(() => setPasswordError({ status: false, message: '' }), 5000)
        }
    }, [passwordError]);


    /**
     * handle user confirmation
     */
    const handleConfirm = () => {
        setOpen(false)
        if (account.isConnected) {
            //disconnect wallet if connected
            disconnect();
        }
        dispatch(logout());
        navigate("/login");

        // Reload the page after a short delay (e.g., 500 milliseconds)
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    return (
        <Grid container>
            <Grid item md={8} xs={12} sm={12}>
                <Box className='stPw-tab'>
                    <Typography className='stCp-text'>Change your password here</Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box className='stInput-fields'>
                            <CustomTextField
                                type={showPassword.current_password ? 'text' : 'password'}
                                label='Current Password'
                                variant='filled'
                                autoComplete='new-password'
                                {...register('current_password', { required: 'Current password is required.' })}
                                error={!!errors.current_password}
                                helperText={errors.current_password?.message}
                                className='passwords-field-input'
                                sx={{ input: { color: '#FFFFFF !important' } }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton style={{ padding: '2px' }} onMouseDown={() => setShowPassword({ ...showPassword, 'current_password': !showPassword.current_password })}>
                                                {showPassword.current_password ?
                                                    <VisibilityOff style={{ color: '#fff', marginRight: '8px' }} />
                                                    :
                                                    <RemoveRedEye style={{ color: '#fff', marginRight: '8px' }} />
                                                }
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box className='stInput-fields'>
                            <CustomTextField
                                label='New Password'
                                type={showPassword.new_password ? 'text' : 'password'}
                                variant='filled'
                                autoComplete='off'
                                {...register('new_password', {
                                    required: 'New password is required.',
                                    pattern: {
                                        value:
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^+#])[A-Za-z\d@$!%*?&^+#]{8,}$/,
                                        message:
                                            "Should contain digit, lowercase letter, uppercase letter, and special character",
                                    },
                                    minLength: {
                                        value: 8,
                                        message: "You need to add minimum 8 character",
                                    },
                                })}
                                error={!!errors.new_password}
                                helperText={errors.new_password?.message}
                                className='passwords-field-input'
                                sx={{ input: { color: '#FFFFFF !important' } }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton style={{ padding: '2px' }} onMouseDown={() => setShowPassword({ ...showPassword, 'new_password': !showPassword.new_password })}>
                                                {showPassword.new_password ?
                                                    <VisibilityOff style={{ color: '#fff', marginRight: '8px' }} />
                                                    :
                                                    <RemoveRedEye style={{ color: '#fff', marginRight: '8px' }} />
                                                }
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box className='stInput-fields'>
                            <CustomTextField
                                label='New Password Confirmation'
                                variant='filled'
                                type={showPassword.confirm_password ? 'text' : 'password'}
                                autoComplete='off'
                                {...register('confirm_password', {
                                    required: 'Confirm password is required.',
                                })}
                                error={!!errors.confirm_password}
                                helperText={errors.confirm_password?.message}
                                className='stIf-input'
                                sx={{ input: { color: '#FFFFFF !important' } }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton style={{ padding: '2px' }} onMouseDown={() => setShowPassword({ ...showPassword, 'confirm_password': !showPassword.confirm_password })}>
                                                {showPassword.confirm_password ?
                                                    <VisibilityOff style={{ color: '#fff', marginRight: '8px' }} />
                                                    :
                                                    <RemoveRedEye style={{ color: '#fff', marginRight: '8px' }} />
                                                }
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box mb={2} mt={3}>
                            <Button className='btn-rounded btn-teal-100' type='submit'>
                                Change Password
                            </Button>
                            <Typography mt={1} variant='subtitle2' color={'red'} >{passwordError.status ? passwordError.message : ''}</Typography>
                        </Box>
                    </form>
                </Box>
                <Modal
                    open={open}
                    className="kyc-modal"
                    // onClose={handleModalCloseAfterWallet}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Fade in={open} timeout={300}>
                        <Box className="modal-body" style={{ borderRadius: '24px' }}>
                            <Box className="modalContent " paddingTop={'25px'}>
                                <Box className="main-content" >
                                    <Typography component="h3" className="">Your Password will be reset, you will have to login again after confirming</Typography >
                                </Box>
                                <Box mt={-2} mb={1}>
                                    <Button onClick={handleConfirm} className='btn-rounded'>
                                        Confirm
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </Grid>

            <CommonBackdropLoader loading={loading} />

        </Grid>
    );
};

export default Password;
