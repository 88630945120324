import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Modal, Fade } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import './Deactivate.css';
import { updateApi } from '../../../Api/Api';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useAccount, useDisconnect } from "wagmi";
import { useDispatch } from 'react-redux';
import { logout } from "../../../features/auth/authSlice";
import CommonBackdropLoader from '../../../Components/Common/CommonBackdropLoader';

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiFilledInput-root': {
        backgroundColor: '#05131B',
        borderRadius: '8px',
    },
    '& .MuiFilledInput-root:hover': {
        backgroundColor: '#05131B',
    },
    '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: '#05131B',
    },
    '& .MuiInputBase-input.MuiFilledInput-input': {
        color: '#ffffff',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '16px',
    },
    '& .MuiInputBase-input.Mui-focused': {
        backgroundColor: '#05131B'
    },
    '& .MuiFilledInput-underline:after': {
        border: '1px solid #C5E4FF'
    },
    '& .MuiFilledInput-underline:hover:after': {
        border: '1px solid #C5E4FF'
    },
    '& label.MuiInputLabel-root': {
        color: '#64748B',
    },
}));


// validation schema for current password
const Deactivate = () => {
    const { register, handleSubmit, formState: { errors }, reset, } = useForm();
    const userData = JSON.parse(localStorage.getItem('user_data') ? localStorage.getItem('user_data') : '{}')
    const [passwordError, setPasswordError] = useState({ status: false, message: '' });
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()
    const account = useAccount();
    const { disconnect } = useDisconnect();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);


    /**
     * handle user inputs and call delete user api
     * @param {object} data 
     */
    const onSubmit = async (data) => {
        // Handle form submission
        try {
            setLoading(true)
            const res = await updateApi(`/user/deleteUser/${userData.id}`, { password: data.current_password });
            if (res.data.code === 200) {
                // localStorage.clear();
                ClearLocalStorageData()
                setOpen(true)
                // Reset the form after submission
                reset({ current_password: '', });
            } else {
                setPasswordError({ status: true, message: res.data.message })
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    };

    // removing error message after 5 secs
    useEffect(() => {
        if (passwordError.status) {
            setTimeout(() => setPasswordError({ status: false, message: '' }), 5000)
        }
    }, [passwordError])

    /**
    * handle user confirmation
    */
    const handleConfirm = () => {
        setOpen(false)
        if (account.isConnected) {
            //disconnect wallet if connected
            disconnect();
        }
        dispatch(logout());
        navigate("/");

        // Reload the page after a short delay (e.g., 500 milliseconds)
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    /**
* function to clear Local storage
*/
    const ClearLocalStorageData = () => {
        localStorage.removeItem("chatbot-thread-id")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("rememberMeToken")
        localStorage.removeItem("Diclogin")
        localStorage.removeItem("user_data_dic")
        localStorage.removeItem("user_data")
        localStorage.removeItem("token")
        localStorage.removeItem("login")
        localStorage.removeItem("kyc_modal_closed")
    }

    return (
        <>
            <Grid container>
                <Grid item md={7} xs={12} sm={12}>
                    <Box className='stDelete-tab'>
                        <Typography className='stSorry-msg'>Sorry to see you go, {userData?.first_name}!</Typography>
                        <Typography className='stAlert-msg'>
                            For your security, please enter your current password to confirm account deactivation.
                        </Typography>
                        <Box className='stPassword'>
                            <CustomTextField
                                type='password'
                                id='filled-basic'
                                label='Current Password'
                                variant='filled'
                                autoComplete='off'
                                className='stPassword-input'
                                {...register('current_password', { required: 'Current password is required.' })}
                                error={!!errors.current_password}
                                helperText={errors.current_password?.message}
                                sx={{ input: { color: '#FFFFFF !important' } }}
                            />
                        </Box>
                        <Typography mt={1} color={'red'} textAlign={'center'}>{passwordError.status ? passwordError.message : ''}</Typography>
                        <Box mt={3}>
                            <Button className='btn-rounded btn-teal-100' onClick={handleSubmit(onSubmit)}>
                                Deactivate Account
                            </Button>
                        </Box>
                    </Box>
                    <Modal
                        open={open}
                        className="kyc-modal"
                        // onClose={handleModalCloseAfterWallet}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Fade in={open} timeout={300}>
                            <Box className="modal-body" style={{ borderRadius: '24px' }}>
                                <Box className="modalContent " paddingTop={'25px'}>
                                    <Box className="main-content" >
                                        <Typography component="h3" className="">Your account has been deactivated successfully , you will be logged out once you confirm the changes.</Typography >
                                    </Box>
                                    <Box mt={-2} mb={1}>
                                        <Button onClick={handleConfirm} className='btn-rounded btn-teal-100'>
                                            Confirm
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Fade>
                    </Modal>
                </Grid>
            </Grid>

            <CommonBackdropLoader loading={loading} />
        </>
    );
};

export default Deactivate;
