import React from 'react'
import { Chip, Box, Typography, Button, Stack, IconButton, Table, TableBody, TableContainer, TableHead, TableRow, Paper, styled, TableCell, tableCellClasses, Grid, Modal, Backdrop, CircularProgress, Avatar } from '@mui/material';
import { RemoveRedEye, Close, ArrowBack } from '@mui/icons-material';
import "./Investor.css"

// Styles for table columns
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#05131B",
        color: "#94A3B8",
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: "0px",
    },
}));

// Styles for table rows
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#032744",
        color: "#FFFFFF",
        borderBottom: "0px",
    },
    "&:nth-of-type(even)": {
        backgroundColor: "#032744",
        color: "#FFFFFF",
        borderBottom: "0px",
    },
}));



export default function DemoSignDocuments() {
    return (
        <>
            <Paper elevation={3} sx={{ p: 2 }} className='rc-basic-container' >
                <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                    <IconButton sx={{ color: '#ffffff' }}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant='h6' sx={{ flexGrow: 1 }}>Sign Documents</Typography>
                </Stack>
                <Box component={'hr'} className='rc-hr' />
                <TableContainer component={Paper} className='TableContainer' style={{ boxShadow: 'none', paddingTop: '16px' }}>
                    <Table aria-label="customized table">
                        <TableHead className='TableHeader'>
                            <TableRow>
                                <StyledTableCell>Document Name</StyledTableCell>
                                <StyledTableCell align="center">Status</StyledTableCell>
                                <StyledTableCell align="center">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow className='TableRow'>
                                <StyledTableCell component="th" scope="row">{'Test'}</StyledTableCell>
                                <StyledTableCell align="center">
                                    -
                                </StyledTableCell>
                                <StyledTableCell align='center'>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        spacing={{ sx: 0, md: 0, lg: 1 }}
                                        width={'120px'}
                                        margin={'auto'}
                                    >
                                        <Button>Sign Here</Button>
                                    </Stack>
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    )
}