// getRulesData.js

import { readContracts } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import convertToDecimal from "../Common/convertToDecimal";

/**
 * Fetch user wallet balance
 * @param {Array} selectedCountries country list
 * @returns user wallet balance
 */
async function getRulesData(restrictCountries, transferAddress, transferFees) {
    let restrictCountryList = [];
    // allowCountries?.forEach(country => {
    //     let data = country.code.split(", ");
    //     data.forEach(range => { allowCountryList.push(parseInt(range.replace("-", ""))) });
    // })
    // disAllowCountries?.forEach(country => {
    //     let data = country.code.split(", ");
    //     data.forEach(range => { disAllowCountryList.push(parseInt(range.replace("-", ""))) });
    // })
    restrictCountries?.forEach(country => {
        let data = country.code.split(", ");
        data.forEach(range => { restrictCountryList.push(parseInt(range.replace("-", ""))) });
    })
    // unRestrictCountries?.forEach(country => {
    //     let data = country.code.split(", ");
    //     data.forEach(range => { unRestrictCountryList.push(parseInt(range.replace("-", ""))) });
    // })
    // allowUserList = allowUser?.map((item) => item.wallet_address);
    // disAllowUserList = disAllowUser?.map((item) => item.wallet_address);
    console.log("transferFees * 100,transferAddress", transferFees, transferAddress)
    try {
        const wagmigotchiContract = {
            address: CONTRACTS.HELPER_MODULE,
            abi: CONTRACT_ABI[CONTRACTS.HELPER_MODULE],
            chainId: 6806,
        }
        const data = await readContracts({
            contracts: [
                // {
                //     ...wagmigotchiContract,
                //     functionName: 'batchAllowCountry',
                //     args: [
                //         allowCountryList
                //     ]
                // },
                // ,
                // {
                //     ...wagmigotchiContract,
                //     functionName: 'batchDisAllowCountry',
                //     args: [
                //         disAllowCountryList
                //     ]
                // },
                {
                    ...wagmigotchiContract,
                    functionName: 'batchRestricCountry', //avail 1
                    args: [
                        restrictCountryList
                    ]
                },
                // {
                //     ...wagmigotchiContract,
                //     functionName: 'batchUnRestricCountry',
                //     args: [
                //         unRestrictCountryList
                //     ]
                // },
                // {
                //     ...wagmigotchiContract,
                //     functionName: 'batchAllowUser',
                //     args: [
                //         allowUserList
                //     ]
                // },
                // {
                //     ...wagmigotchiContract,
                //     functionName: 'batchDisallowUsers',
                //     args: [
                //         disAllowUserList
                //     ]
                // },
                // {
                //     ...wagmigotchiContract,
                //     functionName: 'setMaxBalance',
                //     args: [
                //         maxBalance
                //     ]
                // },
                // {
                //     ...wagmigotchiContract,
                //     functionName: 'setSupplyLimit',
                //     args: [
                //         supplyLimit
                //     ]
                // },
                {
                    ...wagmigotchiContract,
                    functionName: 'setFees',
                    args: [
                        transferFees * 100,
                        transferAddress,

                    ]
                }
            ],
        })
        console.log("rules", data);
        return data;
    } catch (error) {
        console.error('Error getting byte data:', error);
        throw error; // propagate the error
    }
}

export { getRulesData };
