import { Avatar, Box, Button, MenuItem, FormGroup, Checkbox, FormControlLabel, Grid, TextField, Typography, InputAdornment, IconButton, Stack } from '@mui/material'
import React, { useState, useCallback, useMemo } from 'react'
import { CircleCheckIcon } from '../../Components/Images/Images'
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { Controller, useForm } from "react-hook-form";
import CountryCodes from "../../Data/countries.json"
import { LoadingButton } from '@mui/lab'
import { ArrowBack, Cancel, Upload } from '@mui/icons-material';
import { useDropzone } from "react-dropzone";
import countryList from "react-select-country-list";
import './StaticModal.css'
import { isSupportedCountry, isValidPhoneNumber } from "libphonenumber-js";


const countryCodeVal = CountryCodes;

export default function OtherRoleForm({ handleSubmitParent, goBack }) {

    const [uploadedImage, setUploadedImage] = useState([])
    const [deletedDoc, setDeletedDoc] = useState([])
    const [docFiles, setDocFiles] = useState([])
    const [loading, setLoading] = useState(false);
    const [isUploadedDocument, setIsUploadedDocument] = useState(false)

    // Getting country name
    const selectedCountries = ['United States', 'United Kingdom', 'Canada', 'India', 'Singapore', 'Brazil'];
    const initialCountryList = countryList().getData();

    // arranging country according to client requirement 
    const customSort = (a, b) => {
        const indexA = selectedCountries.indexOf(a.label);
        const indexB = selectedCountries.indexOf(b.label);

        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        } else if (indexA !== -1) {
            return -1;
        } else if (indexB !== -1) {
            return 1;
        }

        return a.label.localeCompare(b.label);
    };

    const sortedCountryList = initialCountryList.sort(customSort);
    // Getting country name- end


    const {
        register,
        control,
        reset,
        watch,
        handleSubmit,
        clearErrors,
        formState: { errors },
    } = useForm(
        {
            defaultValues: {

            }
        }
    );

    const countryCodeNum = watch("country_code");

    const onSubmit = (data) => {
        let userData = "";

        if (files.length === 0) {
            // Show an error message or take the necessary action
            setIsUploadedDocument(true)
        } else {
            setLoading(true);
            const country_data = data.country_code.split('-')
            userData = {
                country_code: country_data[0],
                country: data.country_of_residence,
                phone_number: data.phoneNumber,
                other_expertise: data.other_expertise,
                linked_in: data.Linkedin,
                portfolio: data.portfolio,
                files: uploadedImage
            };
        }
        if (userData) {
            handleSubmitParent(userData)
        }
    }

    // handle uploaded docs 
    const onDrop = useCallback(acceptedFiles => {
        const pdfFilesArray = acceptedFiles.filter(file => file.type === 'application/pdf');
        let dataDetail = [...uploadedImage, ...pdfFilesArray]
        setUploadedImage(dataDetail);
    }, [uploadedImage])

    const { getRootProps, open, getInputProps } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: { "image/jpeg": [".pdf"] },
        onDrop
    });

    // handle deleted docs 
    const handleDeleteImage = (file) => {
        if (file) {
            const newDocFile = docFiles.filter((docFile) => {
                setDeletedDoc([...deletedDoc, docFile.id])
                return docFile.image !== file
            })
            setDocFiles(newDocFile);
        }
        const newUploadedImages = uploadedImage.filter((image) => image.path !== file.path);
        setUploadedImage(newUploadedImages);
    };

    // preparing to show uploaded docs
    const files = uploadedImage?.map((file) => (
        <Box className="showUploadedDocuments">
            <Box key={file.path} className="showUploadedDocuments-wrap" display={'flex'} alignItems={"center"}>
                <Box className="fileName" mr={1}>
                    {file?.path?.includes("proposer/uploads/") ? file.original_name : file.name}
                </Box>
                <Cancel onClick={() => handleDeleteImage(file)} className="removeIcon" />
            </Box>
        </Box >
    ))

    const isUploadDocuments = files.length > 0;

    const [countryIso, setCountryIso] = useState('');
    /**
    *  validate phone number with libphonenumber-js library
    * @param {string} value  phone number
    * @param {string} country_iso  country code
    * @returns 
    */
    const validatePhoneNumber = (value) => {
        const res = isSupportedCountry(countryIso);
        const countryCodeNumUpdate = countryCodeNum.split('-')[0];
        return isValidPhoneNumber(`+${countryCodeNumUpdate}${value}`, countryIso);
    }


    return (
        <Box className="investor-modal-container" sx={{ ml: { md: '50px', sm: '40px', xs: '5px' }, maxHeight: '70vh', overflowY: 'auto', marginTop: { md: '-70px' } }} >
            <Box className="staticHomepages provider-details-form" sx={{
                maxWidth
                    : '730px', mx: 'auto', mt: 2,
            }}>
                <Box p={{ xs: 2, sm: 2 }}
                    // bgcolor={'#000'} borderRadius={'16px'}
                    sx={{ maxWidth: "730px" }}
                    mt={{ xs: 2, sm: 3.5 }}
                >
                    <Typography className='title text-blue-900 font-36' color={'#fff'} component={'h2'}>Join us on the RACE Platform</Typography>

                    {/*Form starts from here*/}
                    <Box mt={3.5} component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Typography className='font-16 text-blue-900' color={'#fff'} mb={2}>Please submit the information below so we can optimize your experience on the RACE platform.</Typography>

                        <Typography className='font-18 text-blue-900' color={'#fff'} mb={2}>Personal Details</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={3} md={4} lg={3}>
                                <Box className="form-group label-field staticProposerModel">

                                    <Controller name="country_code" control={control}
                                        defaultValue={`${countryCodeVal[0].code}-${countryCodeVal[0].iso}`}
                                        rules={{
                                            required: "Country Code is required",
                                        }}
                                        InputLabelProps={{
                                            style: { color: '#fff' }, // Set the label text color to white
                                        }}
                                        InputProps={{
                                            style: { color: '#fff' }, // Set the input text color to white
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                select
                                                name="country_code"
                                                {...field}
                                                error={Boolean(errors.country_code)}
                                                helperText={errors.country_code?.message}
                                                label="Country Code"
                                                InputLabelProps={{
                                                    style: { color: '#fff' }, // Set the label text color to white
                                                }}
                                                InputProps={{
                                                    style: { color: '#fff' }, // Set the input text color to white
                                                }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200, // Adjust the maximum height according to your needs
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                {countryCodeVal.map((option, index) => (
                                                    <MenuItem

                                                        key={index}
                                                        value={`${option.code}-${option.iso}`}
                                                        onClick={() => {
                                                            setCountryIso(option.iso)
                                                        }}
                                                    >
                                                        {`+${option.code} ${option.country}  `}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )} />

                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={9} md={8} lg={9}>
                                <Box className="form-group label-field staticProposerModel">
                                    <TextField
                                        name="phoneNumber"
                                        label="Phone Number"
                                        type="text"
                                        pattern="[0-9]*"
                                        placeholder="Enter phone number"
                                        {...register("phoneNumber", {
                                            required: "Phone number is required..",
                                            validate: (value) => validatePhoneNumber(value, countryCodeNum) || "Please enter valid phone number",
                                        })}
                                        autoComplete="tel" // this line for auto-fill
                                        error={Boolean(errors.phoneNumber)}
                                        helperText={(errors?.phoneNumber?.message)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneIphoneIcon className="text-blue-600" />
                                                </InputAdornment>
                                            ),
                                            style: { color: '#fff' },
                                        }}
                                        onInput={(event) => {
                                            event.target.value = event.target.value
                                                .replace(/\D/g, "")
                                                .slice(0, 15);
                                        }}
                                        InputLabelProps={{
                                            style: { color: "#fff" }, // Set the label text color to white
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Box mb={3} >
                            <TextField
                                className='roles_select'
                                InputLabelProps={{
                                    // shrink: true,
                                    style: { color: '#fff' }, // Set the label text color to white
                                }}
                                label="Country of Residence"
                                id="outlined-select-country-native"
                                select
                                name='country_of_residence'
                                {...register("country_of_residence", {
                                    required: "Country of Residence is required.",
                                })}
                                error={Boolean(errors.country_of_residence)}
                                helperText={errors.country_of_residence?.message}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200, // Adjust the maximum height according to your needs
                                                maxWidth: '50%',
                                            },
                                        },
                                    },
                                }}
                                InputProps={{
                                    style: { color: '#fff' }, // Set the input text color to white
                                }}
                                fullWidth
                                onChange={() => clearErrors("country_of_residence")}
                            >
                                {
                                    sortedCountryList?.map((item, index) => (
                                        <MenuItem key={index} value={item?.label}>{item?.label}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Box>



                        <Box className="form-group label-field staticProposerModel" >
                            <TextField
                                name="other_expertise"
                                label="Expertise"
                                placeholder="Enter your expertise"
                                {...register("other_expertise", {
                                    required: "Expertise is required.",
                                })}
                                autoComplete='on'
                                error={Boolean(errors.other_expertise)}
                                helperText={errors.other_expertise?.message}
                                InputLabelProps={{
                                    style: { color: '#fff' }, // Set the label text color to white
                                }}
                                InputProps={{
                                    style: { color: '#fff' }, // Set the input text color to white
                                }}
                            />
                        </Box>

                        <Grid item xs={12} sm={12} >
                            <Typography className='font-18 text-blue-900' color={'#fff'} marginTop={"15px"} whiteSpace={{ lg: 'nowrap', md: 'normal' }} mr={2}>Professional Profiles</Typography>
                            {/* <Grid item xs={12} sm={12}> */}
                            <Box className="form-group label-field staticProposerModel" sx={{ marginTop: '12px' }}>
                                <TextField
                                    name="Linkedin"
                                    label="Linkedin Profile"
                                    placeholder="Paste your Linkedin URL here"
                                    {...register("Linkedin", {
                                        required: "Linkedin URL is required.",
                                        pattern: {
                                            // value: /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/,
                                            message: "Please enter valid url",
                                        },
                                        minLength: {
                                            value: 3,
                                            message: "Please enter valid Address",
                                        },
                                    })}
                                    autoComplete='url'
                                    error={Boolean(errors.Linkedin)}
                                    helperText={errors.Linkedin?.message}
                                    InputLabelProps={{
                                        style: { color: '#fff' }, // Set the label text color to white
                                    }}
                                    InputProps={{
                                        style: { color: '#fff' }, // Set the input text color to white
                                    }}

                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Box className="form-group label-field staticProposerModel">
                                <TextField
                                    name="portfolio"
                                    label="Portfolio Link"
                                    placeholder="Paste link to your investment/portfolio showcase"
                                    {...register("portfolio", {
                                        required: "Portfolio URL is required.",
                                        pattern: {
                                            // value: /^(https?:\/\/)?[\w.-]+\.[a-z]{2,}$/i,
                                            message: "Please enter valid url",
                                        },

                                    })}
                                    autoComplete='url'
                                    error={Boolean(errors.portfolio)}
                                    helperText={errors.portfolio?.message}
                                    InputLabelProps={{
                                        style: { color: '#fff' }, // Set the label text color to white
                                    }}
                                    InputProps={{
                                        style: { color: '#fff' }, // Set the input text color to white
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Typography variant='h6' color={'#fff'} sx={{ marginBottom: "14px" }}>Upload Supporting Documents</Typography>
                        <Box  {...getRootProps({
                            className: "dropzone",
                        })}>
                            <input {...getInputProps()} />
                            <Stack direction="row" spacing={0} flexWrap={'wrap'}>
                                <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 3 }}>
                                    <Button className='btn-rounded btn-blue-600 btn-text-white' onClick={open} startIcon={<Upload />} sx={{ color: 'white' }}>
                                        Browse Files
                                    </Button>
                                </Box>
                                <Box sx={{ padding: '2px' }} ml={{ sm: 0, md: 5 }}>
                                    <Typography variant='body2' sx={{ color: '#94A3B8' }}>Resume, Portfolio, Track Record etc.</Typography>
                                    <Typography variant='body2' sx={{ color: '#94A3B8' }}>Supported Formats: PDF</Typography>
                                </Box>
                            </Stack>
                        </Box>
                        {files !== "" ? (
                            <Box
                                sx={{
                                    paddingBottom: "8px",
                                    overflow: "hidden",
                                }}
                            >
                                {files?.map((fileName, ind) => {
                                    return (<Box alignItems={"center"} mt={1} mb={2} key={ind} sx={{ display: 'flex' }}>
                                        <Avatar
                                            src={CircleCheckIcon}
                                            alt="Icon"
                                            sx={{ width: 24, height: 24 }}
                                        />
                                        <Box
                                            className="dD-file-name text-black"
                                            ml={1}
                                            sx={{ color: '#fff' }}
                                        >
                                            {fileName}
                                        </Box>
                                    </Box>
                                    )
                                })
                                }
                                {isUploadedDocument && !isUploadDocuments && (
                                    <Typography variant='body2' style={{ color: '#d32f2f' }}>Upload document is required</Typography>
                                )}
                            </Box>
                        ) : (
                            ""
                        )}

                        <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 6 }}>
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                fullWidth
                                className="btn-rounded btn-large btn-blue-600 btn-text-white"
                                type="submit"
                            >
                                Submit and join waitlist
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Button sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        marginTop: '-25px',
                        '@media screen and (max-width: 600px)': {
                            // Styles for screens smaller than 600px (adjust the value as needed)
                            width: '100%', // Make the button full width on smaller screens
                            marginTop: '0', // Adjust margin top for smaller screens
                        },
                    }} onClick={goBack}><ArrowBack style={{ marginRight: '13px' }} /> <span>Go Back</span></Button>
                </Box>
            </Box >
        </Box>
    )
}

