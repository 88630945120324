// Registration.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { SP_CONTRACTS, SP_CONTRACT_ABI } from "../ServiceProviderWeb3/ServiceProviderContract"

const termTypeEnum = ["milestone", 'project']
/**
 * Call the withdrawAmount method to transfer the parcel to investor and money to proposer
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function DirectHire(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {

        let contract_address = SP_CONTRACTS.SERVICE_MARKETPLACE_CLIENT;
        let functionName = "directHire";

        console.log(assetData);
    
        let _ct = assetData?._ct;
        if (_ct.termType) {
            _ct.termType = termTypeEnum.indexOf(_ct.termType)
        }
        console.log('directHire---',
            assetData?._serviceProviderAddress,
            _ct,
            assetData?._deliveryDate,
            assetData?._dbProjectId
        );

        const { request: changeservicefee } = await prepareWriteContract({
            address: contract_address,
            abi: SP_CONTRACT_ABI[contract_address],
            account: address,
            chainId: 6806,
            functionName: functionName,
            args: [
                assetData?._serviceProviderAddress, // _serviceProviderAddress
                _ct,
                assetData?._deliveryDate,
                assetData?._dbProjectId
            ]
        })

        const { hash } = await writeContract(changeservicefee)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(2)
                setTimeout(() => {
                    handleSuccess(Number(data.logs[0].topics[1]))
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error in register user:', error);
        throw error; // propagate the error
    }
}

export { DirectHire };