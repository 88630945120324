import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, CircularProgress, Tooltip } from "@mui/material";
import { KeyboardArrowRight } from '@mui/icons-material';

// Import custom components for this page
import { NewLogo } from '../../../Components/Images/Images'
import { GetApi, assetDeleteApi } from "../../../Api/Api";
import SliderCard from '../../../Components/Common/SliderCard'
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css"
import { useSnackbar } from "../../../Contexts/SnackbarContext";


export default function SelectAssetType(props) {
  // Destructure props to obtain necessary functions and data
  const { handleNext, getProposalData, updateProposalData, getPrevAsset, updateAsset, saveProposal, getProgress, EditMode, updateAssetDeleted } = props;

  const { showSnackbar } = useSnackbar()

  // Initialize State variables to handle selected asset type and asset data
  const [selected, changeSelection] = useState("");
  const [assetData, setAssetData] = useState("");

  // getting current proposal and progress data from parent
  let propData = getProposalData();
  let currentProgress = getProgress();

  /** 
   * Function to set category id and name on asset selected
  */
  function setSelected(data) {

    // define category id and category names
    let categoryId = data.id
    let categoryName = data.url

    // update proposal data
    propData.category_id = categoryId;
    propData.selectedCategory = categoryName;
    // update it to main page
    updateProposalData(propData)

    // update it to local
    changeSelection(data);
  }

  // Fetching all asset categories from server
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await GetApi("/proposer/getCategory/");
        if (res?.data?.data && res.data?.data?.length) {
          setAssetData(res?.data?.data)
        } else {
          setAssetData([])
        }
      } catch (error) {
        showSnackbar("Error in fetching category details", "error")
      }
    }
    fetchData();
  }, [])

  /**
   *  Function to delete the asset type in edit mode
   * @param {Integer} asset_id 
   * @returns boolean
   */
  async function deleteAsset(asset_id) {
    if (asset_id) {
      try {
        const res = await assetDeleteApi(`/proposer/assetDelete?asset_id=${asset_id}`);
        if (res.status) {
          updateAssetDeleted(true)
          propData.asset_id = ''
        }
        return res.status
      } catch (error) {
        console.log(error)
        return error
      }
    }
  }

  /**
  * Function to handle the "Next" and "Save and Exit" button clicks.
  * Checks for changes in the category type and updates the proposal data accordingly.
  * Calls the 'handleNext', 'saveProposal', or 'updateAsset' functions based on the button clicked.
  * @param {Object} event - The event object from the button click.
  */
  const handleButtonClick = async (event) => {
    // Get the inner text of the clicked button
    const buttonText = event.currentTarget.textContent;
    // Get the previous asset type
    let prev = getPrevAsset();
    // Check if the current category_id is different from the previous one and if there's a category_id in propData
    if (prev?.category_id !== propData?.category_id && propData?.category_id) {
      if (prev?.category_id) {
        // Extract relevant data from propData
        let user_id = propData?.user_id;
        let category_id = propData?.category_id;
        let selectedCategory = propData?.selectedCategory
        let project_proposer = propData?.project_proposer
        let owner_id = ''

        // Check if you're in EditMode
        if (EditMode) {
          // If in EditMode, set the owner_id and delete the existing asset (if it exists)
          owner_id = propData?.owner_id
          if (propData?.asset_id) {
            deleteAsset(propData?.asset_id);
          }
        }

        // Clear the existing data in propData
        let data1 = Object.keys(propData).forEach(key => propData[key] = null);
        updateProposalData(data1)

        // Set the necessary data back in propData
        propData.user_id = user_id;
        propData.category_id = category_id;
        propData.selectedCategory = selectedCategory;
        propData.project_proposer = project_proposer;

        // If in EditMode, set the owner_id back in propData
        if (EditMode) {
          propData.owner_id = owner_id
        }
        // Update the proposal data
        updateProposalData(propData)
      }
      // Update the previous category_id with the current one
      prev.category_id = propData?.category_id;
      updateAsset(prev);
    }
    // Check the inner text to determine which button was clicked
    if (buttonText.includes("Save and Exit")) {
      // Handle the Save and Exit button click
      saveProposal();
    } else {
      // Handle the Next button click
      handleNext();
    }
  }

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" >
              {/* left-half-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  What type of asset <br /> would you like to list?
                </Typography>
              </Box>
              {/* Race logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  {/* if any asset type selected then allow save and exit click */}
                  <Button onClick={handleButtonClick} disabled={!EditMode && (selected !== "" || propData?.category_id) ? false : true} className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="questionnaire5 qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                <Box className="CategoryBox">
                  {!assetData?.length && <Box textAlign={"center"}> <CircularProgress /></Box>}
                  <Grid container spacing={2}>
                    {(() => {
                      const deals = [];
                      for (let i = 0; i < assetData?.length; i++) {
                        deals.push(
                          <Grid item xs={6} sm={6} md={4} key={i}
                            onClick={(assetData[i]?.url === "collections" || assetData[i]?.url === "energy" || assetData[i]?.url === "nft") ? () => { } : () => setSelected(assetData[i])}
                            className={(assetData[i]?.url === "collections" || assetData[i]?.url === "energy" || assetData[i]?.url === "nft") ? "disabled" : (propData?.category_id === assetData[i]?.id) ? "selected" : ""}
                          >
                            <SliderCard
                              image={`${process.env.REACT_APP_IMAGE_URL}${assetData[i]?.category_image?.path} `}
                              title={assetData[i]?.title} />
                          </Grid>
                        );
                      }
                      return deals;
                    })()}
                  </Grid>

                </Box>
              </Box>
              <Box>
                <Box textAlign={'center'} mb={3.2} className="text-black font-18">
                  *We currently don’t accept collection listings with single pieces.
                </Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'right' }} mt={4.7}>
                  <Box textAlign={"right"} className='quests-btn-box'>
                    {/* if any asset type selected then allow button click */}
                    {(selected !== "" || propData?.category_id) ?
                      <Button onClick={handleButtonClick} endIcon={<KeyboardArrowRight />} className='btn-rounded btn-text-white btn-blue-600'>
                        Next
                      </Button>
                      :
                      <Tooltip title="Select an asset type to continue." className="p-flow-next-btn-tooltip">
                        <span>
                          <Button disabled endIcon={<KeyboardArrowRight />} className='btn-rounded btn-text-white btn-blue-600'>
                            Next
                          </Button>
                        </span>
                      </Tooltip>
                    }
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
