// approve.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import convertToDecimal from "../Common/convertToDecimal";
import convertToSixDecimal from "../Common/convertToSixDecimal";
import getCurrencyTypeAddress from "../Common/getCurrencyTypeAddress";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Approve the tokens for spender contract
 * @param {String} address user wallet address
 * @param {String} spenderAddress spender contract address
 * @param {String} allowanceAmt  amount to approve
 * @returns transaction data
 */
async function approveToken(address, spenderAddress, allowanceAmt, feeTokenType) {
    try {

        let token_address = getCurrencyTypeAddress(feeTokenType);
        let stakeAmount = getCurrencyTypeDecimal(feeTokenType, allowanceAmt);

        // Now approve tokens
        const { request: approve } = await prepareWriteContract({
            address: token_address,
            abi: CONTRACT_ABI[token_address],
            account: address,
            chainId: 6806,
            functionName: "approve",
            args: [
                spenderAddress, // spender address
                stakeAmount // stake amount
            ]
        })

        const { hash } = await writeContract(approve);

        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            return data
        }
    } catch (error) {
        console.error('Error in calling approve:', error);
        throw error; // propagate the error
    }
}

export { approveToken };