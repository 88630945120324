import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { tableCellClasses } from '@mui/material/TableCell';
import { PaymentsOutlined } from '@mui/icons-material';
import {
    Chip, TableCell, Button, Box, Paper, TableRow, TableHead, TableContainer, Table, TableBody, Typography,
    Avatar, Modal, TextField
} from '@mui/material';
import { CAPITAL_TYPE_DISPLAY, CATEGORY_TYPE, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, OFFER_STATUS, SUB_CATEGORY_TYPE_ID } from '../../constants.js'
import { GetApiParam, postApi } from '../../Api/Api.js';
import TransactionProgressModal from '../../Components/SmartContract/TransactionProgressModal.jsx';
import SelectCurrencyType from './SelectCurrencyType.jsx';
import { Close, Done, EditSharp, Visibility } from '@mui/icons-material';
import { useSnackbar } from '../../Contexts/SnackbarContext';
import { NewLogo } from "../../Components/Images/Images";
import NumericFormatCustomDollar from '../../Components/Common/NumericFormatCustomDollar';
import './Transactions.css'
import { formatNumber } from '../../Components/Common/USFormat.js';
import { useAccount } from 'wagmi';
import { spaceXFundTokenTotalSupply } from '../../Components/SmartContract/spaceXFundTokenTotalSupply.js';
import { viewListing } from '../../Components/SmartContract/viewListing.js';
import { spaceXFundTokenBalanceOf } from '../../Components/SmartContract/spaceXFundTokenBalanceOf.js';
import CommonBackdropLoader from '../../Components/Common/CommonBackdropLoader.jsx';
import { CONTRACTS } from '../../Web3/Contracts.js';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

const StyledTableRow = styled(TableRow)(({ }) => ({
}));

/**
 * due status for the due date i.e 
 * ```
 * if the due date is in future then the due status will be future
 * if the due date is near then the due status will be near
 * if the due date is passed then the due status will be past
 * if the payment is done then the due status will be paid
 * ```
    @enum {string}
 */
const DUE_STATUS = {
    FUTURE: 'FUTURE',
    NEAR: 'NEAR',
    PAST: 'PAST',
    PAID: 'PAID'
}

/**
 * @param {string} date  Date String
 * @returns  {string} Formatted Date String in MM/DD/YYYY format
 */
const formatDate = (date) => {
    let d = new Date(date);
    let formattedDate = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
    return formattedDate;
}

const FundWithdrawal = ({ setLoading }) => {

    const navigate = useNavigate();
    const [offersData, setOffersData] = useState([])
    const [assetData, setAssetData] = useState([])
    const [offersDataNew, setOffersDataNew] = useState([])
    const [openModalWithdrawEscrow, setOpenModalWithdrawEscrow] = useState(false);
    const [propData, setPropData] = useState();

    let u = localStorage.getItem('user_data');
    let User = JSON.parse(u);

    /**
     * function to get offers
     */
    const getOffers = async (param) => {
        try {
            setLoading(true)
            let res = await postApi('/proposer/proposerFundWithdrawal', param);
            if (res?.data?.data?.length) {
                setOffersData(res.data.data);
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        try {
            if (User.id) {
                getOffers({ investor_id: User.id });
            }
        } catch (error) {
            console.error(error)
        }
    }, [])

    /**
    * Function to handle modal close
    */
    const handleCloseWithdrawAmount = (data) => {
        
        if (data?.warning) {
            navigate('/user/transactions', { replace: true })
        }
        setOpenModalWithdrawEscrow(false);
    }

    /**
     * Function call after transaction confirm
     */
    const confirmWithdrawAmount = async(data , asset_id) => {
         console.log("data...",data);
        console.log("asset_id...",asset_id);
        // setOpen(true);
        let spaceXWithdrawFund = ""

        let currencyCheck = ""

        function getCurrencyTypeAddress(address) {
            let CurrencyAmount = ""
            switch (address) {
                case (address == CONTRACTS.USDC):
                    CurrencyAmount = 1e6
                    break;
                case (address == CONTRACTS.USDT):
                    CurrencyAmount = 1e6
                    break;
                case (address == CONTRACTS.DAI):
                    CurrencyAmount = 1e18
                    break;
                default:
                    CurrencyAmount = 1e6
                    break;
            }
            return CurrencyAmount
        }

        if (data?.logs?.length && data?.logs[0]?.data) {
            spaceXWithdrawFund = Number(data?.logs[0]?.data);
            currencyCheck = getCurrencyTypeAddress(data?.logs[0]?.address)
        }
        
        console.log("spaceXWithdrawFund...",spaceXWithdrawFund);
        try {
            getOffers({ investor_id: User.id, category_id: CATEGORY_TYPE_ID.FUNDS });

            let userData = localStorage.getItem('user_data')
            userData = JSON.parse(userData);
            const gasFees = Number(data.gasUsed)
            let body = {
                amount: spaceXWithdrawFund/currencyCheck,
                sender_address: data.from,
                contract_address: data.to,
                transaction_hash: data.transactionHash,
                method_name: "space-x-FundWithdrawal",
                proposer_id: userData?.id,
                asset_id: asset_id,
                gas_fees: gasFees

            }

            const transactionCreate = await postApi("/user/createBlockchainTransaction", body);
        } catch (error) {
            console.error(error)
        }
    }

      /**
     * Function call after transaction confirm
     */
      const openFundconfirmWithdrawAmount = async(data , asset_id) => {
        console.log("data...",data);
       console.log("asset_id...",asset_id);
       // setOpen(true);
       let openFundAmountWithdrawal = ""
       let currencyCheck = ""

       function getCurrencyTypeAddress(address) {
           let CurrencyAmount = ""
           switch (address) {
               case (address == CONTRACTS.USDC):
                   CurrencyAmount = 1e6
                   break;
               case (address == CONTRACTS.USDT):
                   CurrencyAmount = 1e6
                   break;
               case (address == CONTRACTS.DAI):
                   CurrencyAmount = 1e18
                   break;
               default:
                   CurrencyAmount = 1e6
                   break;
           }
           return CurrencyAmount
       }

       if (data?.logs?.length && data?.logs[0]?.data) {
        openFundAmountWithdrawal = Number(data?.logs[0]?.data);
           currencyCheck = getCurrencyTypeAddress(data?.logs[0]?.address)
       }
     
       console.log("openFundAmountWithdrawal...",openFundAmountWithdrawal);
       try {
           getOffers({ investor_id: User.id, category_id: CATEGORY_TYPE_ID.FUNDS });

           let userData = localStorage.getItem('user_data')
           userData = JSON.parse(userData);
           const gasFees = Number(data.gasUsed)
           let body = {
               amount: openFundAmountWithdrawal/currencyCheck,
               sender_address: data.from,
               contract_address: data.to,
               transaction_hash: data.transactionHash,
               method_name: "openFundAmountWithdrawal",
               proposer_id: userData?.id,
               asset_id: asset_id,
               gas_fees: gasFees

           }

           const transactionCreate = await postApi("/user/createBlockchainTransaction", body);
       } catch (error) {
           console.error(error)
       }
   }


    /**
     * Function call after transaction confirm
     */
    const handleWithdrawAmount = (row) => {
        console.log(row)
        setPropData(row);
        setOpenModalWithdrawEscrow(true)
    }

    /**
     * function to handle status
     * @param {Object} row 
     * @returns 
     */
    const getStatusComponent = (row) => {
        return (
            <>
                {row?.disable_btn ?
                    <>
                        <Chip label={'Withdrawn'} sx={{ color: '#13202D', bgcolor: '#34D399', borderRadius: '8px' }} />
                    </>
                    :
                    <Button
                        onClick={() => handleWithdrawAmount(row)}
                        className="status-button"
                        disabled={row?.disable_btn ? true : false}
                        sx={{ color: 'gray', bgcolor: 'lightgray', borderRadius: '8px' }} // Updated color and bgcolor to gray
                    >
                        Withdrawal
                    </Button>
                }
            </>
        );
    };

    const getSpaceXToken = async (address, list_data, data) => {
        let offers = [];
        for (let i = 0; i < list_data.length; i++) {
            let totalToken = 0;
            const newData = list_data[i];
            console.log(newData);
            if (newData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
                let listingData = await viewListing(address, {
                    listing_id: newData?.listingId,
                    category_id: newData?.category_id,
                    asset_category_id: newData?.asset_sub_category,
                })
                if (listingData) {
                    let spaceXToken = await spaceXFundTokenTotalSupply(address, listingData?.fundType?.tokenAddress)
                    if (spaceXToken) {
                        totalToken += spaceXToken
                    }
                }
            } else {
                let listingData = await viewListing(address, {
                    listing_id: newData?.listingId,
                    category_id: newData?.category_id,
                    asset_category_id: newData?.asset_sub_category,
                })
                if (listingData && listingData?.fundType?.length && listingData?.fundType[newData?.class == '1' ? 1 : 0]) {
                    let tokenAddress = listingData?.fundType[newData?.class == '1' ? 1 : 0]?.tokenAddress
                    let spaceXToken = await spaceXFundTokenTotalSupply(address, tokenAddress)
                    if (spaceXToken) {
                        totalToken += spaceXToken
                    }
                }
            }
            if (totalToken > 0 || newData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS) {
                offers.push(newData)
            } else {
                offers.push({ ...newData, disable_btn: true })
            }
        }
        setOffersDataNew([...offers, ...data])
        setLoading(false)
    }

    let userData = JSON.parse(localStorage.getItem("user_data"));
    useEffect(() => {
        if ((offersData?.length || assetData?.length) && userData?.wallet_address) {
            getSpaceXToken(userData?.wallet_address, offersData, assetData)
        }
    }, [offersData, assetData, userData?.wallet_address])


    return (
        <>
            <Box className="filter-panel" mt={4}>
                <Box className="fp-left">
                    <Box className="heading-left">
                        {/* Changed from Fund WithDrawal to yield Withdrwal after client discussion */}
                        <Typography component={"h6"}>
                            Yield Withdrawal
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>

                        <TableRow>
                            <StyledTableCell width={'20%'}>Project Name</StyledTableCell>
                            <StyledTableCell width={'16%'} align="left">Fund Type</StyledTableCell>
                            <StyledTableCell width={'16%'} align="left">Fund Class</StyledTableCell>
                            <StyledTableCell width={'16%'} align="left">Investment Amount</StyledTableCell>
                            <StyledTableCell width={'16%'} align="left">Received Amount</StyledTableCell>
                            <StyledTableCell width={'16%'} align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {offersDataNew?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.asset_title}${i}`}>
                                <StyledTableCell component="th" scope="row">
                                    <Link to={`/project-details/${row.asset_id}`}>{row.asset_title}</Link>
                                </StyledTableCell>
                                <StyledTableCell align="left">{row?.asset_sub_category_name ? row?.asset_sub_category_name : '-'}</StyledTableCell>
                                <StyledTableCell align="left">{row?.class ? `Class ${row?.class == 1 ? 'A' : 'B'}` : '-'}</StyledTableCell>
                                <StyledTableCell align="left">${formatNumber(parseFloat(row.balance_sum ? row.balance_sum : 0))}</StyledTableCell>
                                <StyledTableCell align="left">{`$${formatNumber(row?.return_amount ? row?.return_amount : 0)}`}</StyledTableCell>
                                {/* <StyledTableCell align="center">{row.status == OFFER_STATUS.COMPLETED ? formatDate(row.updatedAt) : '-'}</StyledTableCell> */}
                                <StyledTableCell align="center">{
                                    getStatusComponent(row)
                                }</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >

            {/* code for pay remaining payment smart contract modal */}
            {openModalWithdrawEscrow ? <TransactionProgressModal identity={propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? 'space-x-withdraw-escrow' : 'open-fund-withdraw-escrow'} confirmStake={ propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? confirmWithdrawAmount : openFundconfirmWithdrawAmount } propData={propData} handleModalClose={handleCloseWithdrawAmount} openTransactionModal={openModalWithdrawEscrow} /> : null}

        </>
    )
}

export default FundWithdrawal
