// viewListing.js

import { readContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} address user wallet address
 * @param {String} assetData asset data
 * @returns user wallet allowance
 */
async function viewListing(address, assetData) {
    try {
        let token_address = '';
        let functionName = '';
        if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
            token_address = CONTRACTS.ART_PARCEL_MARKETPLACE
            functionName = 'viewListings';
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
            token_address = CONTRACTS.SPV_MARKETPLACE
            functionName = 'viewListing'
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.asset_category_id === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
            token_address = CONTRACTS.FUNDS_CLOSE_MARKETPLACE
            functionName = 'viewListing'
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.asset_category_id === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS) {
            token_address = CONTRACTS.FUNDS_OPEN_MARKETPLACE
            functionName = 'viewListing'
        }
        // Now check for allowance Tokens
        const listing = await readContract({
            address: token_address,
            abi: CONTRACT_ABI[token_address],
            // account: address,
            functionName: functionName,
            chainId: 6806,
            args: [
                assetData?.listing_id
            ]
        })
        return listing;
    } catch (error) {
        console.error('Error fetching allowance:', error);
        throw error; // propagate the error
    }
}

export { viewListing };
