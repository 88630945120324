import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, IconButton, } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import SliderCardProser from "../../../Components/Common/SliderCardProser";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import NumericFormatCustomDollar from "../../../Components/Common/NumericFormatCustomDollar";
import "./Components/Questionnaire.css";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../../constants";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import NumericFormatCustom from "../../../Components/Common/NumericFormatCustom";

export default function SetSaleDetails(props) {
  const { showSnackbar } = useSnackbar()

  // Destructure props to obtain necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

  // Get current proposal and progress data from the parent component
  let propData = getProposalData();
  let currentProgress = getProgress();

  // Initialize state for the sale price with a default value of 0
  const [salePrice, setSalePrice] = useState(propData?.sale_price ? propData?.sale_price : 0);
  const [estimatedError, setEstimatedError] = useState(propData?.sale_price ? propData?.sale_price : 0);
  const [investmentDuration, setInvestmentDuration] = useState(propData?.investment_duration ? propData?.investment_duration : 0)

  // state to hold max limit for project price
  const [isMaxLimitExceeded, setIsMaxLimitExceeded] = useState(false);
  const [inBetweenRange, setInBetweenRange] = useState(false);
  const [maxLimit, setMaxLimit] = useState(0);
  const [nextMaxLimit, setNextMinLimit] = useState(0);
  const [preMinLimit, setPreMaxLimit] = useState(0);

  /**
   * Function to handle change in sale price.
   * @param {string} type - Type of change, either 'inc' for increment or 'dec' for decrement.
   */
  const changeRaise = (type) => {
    if (type === "inc") {
      // Increase sale price by $500
      setSalePrice((prevCount) => prevCount ? (parseFloat(prevCount) + 500) : (500));
    } else {
      // Decrease sale price by $500, but ensure it doesn't go below 0
      setSalePrice((prevCount) => parseFloat(prevCount) >= 500 && prevCount ? parseFloat(prevCount) - 500 : prevCount);
    }
  };

  /**
   * Function to handle the click event for the "Next" button.
   * Updates 'propData' with the sale price and proceeds to the next step.
   */
  const handleClick = () => {
    propData.sale_price = salePrice;
    propData.investment_duration = investmentDuration;
    updateProposalData(propData);
    handleNext();
  };

  /**
   * Function to handle the click event for the "Save and Exit" button.
   * Updates 'propData' with the sale price and saves the proposal.
   */
  const SaveAndExit = () => {
    propData.sale_price = salePrice;
    propData.investment_duration = investmentDuration;
    updateProposalData(propData);
    saveProposal();
  };

  /**
 * Function to increment or decrement 'projectDuration' by 1 
 * @param {String} type 
 */
  const handleInvestmentDuration = (type) => {
    if (type === 'inc') {
      setInvestmentDuration(prevCount => prevCount ? (parseInt(prevCount) + 1) : 1)
    } else {
      setInvestmentDuration(prevCount => (parseInt(prevCount) >= 1 ? parseInt(prevCount) - 1 : prevCount))
    }
  }

  useEffect(() => {
    if (salePrice > 0 && salePrice > parseFloat(propData?.watchInformation?.watch_estimated) && propData?.category_id === CATEGORY_TYPE_ID.WATCH) {
      setEstimatedError(true)
    } else {
      setEstimatedError(false);
    }
  }, [salePrice])

  useEffect(() => {
    if (salePrice > 0 && !estimatedError) {
      // checking for max range
      let checkMaxLimitExceeded = true, checkMaxLimit = 0, checkPreMaxLimit = 0, checkNextMinLimit = 0;
      let stackAmtData = propData?.stackAmtData;
      stackAmtData?.filter(item => item.investment_type_id === INVESTMENT_TYPE_ID.SALE)
      let projectAmt = parseFloat(salePrice)
      if (stackAmtData?.length) {
        stackAmtData?.forEach((dataItem) => {
          checkPreMaxLimit = checkMaxLimit
          checkNextMinLimit = dataItem?.min_amount
          checkMaxLimit = dataItem?.max_amount
          if (parseFloat(dataItem?.min_amount) <= parseFloat(projectAmt) && parseFloat(projectAmt) <= parseFloat(dataItem?.max_amount)) {
            checkMaxLimitExceeded = false
          }
        })
        setIsMaxLimitExceeded(checkMaxLimitExceeded)
        setMaxLimit(checkMaxLimit)
        setNextMinLimit(checkNextMinLimit)
        setPreMaxLimit(checkPreMaxLimit)
      }

      if (!checkMaxLimitExceeded) {
        setInBetweenRange(false)
      }
      // check for max range exceeded
      if (checkMaxLimitExceeded && checkMaxLimit > 0 && (parseFloat(checkPreMaxLimit) < parseFloat(projectAmt) && parseFloat(projectAmt) < parseFloat(checkNextMinLimit))) {
        showSnackbar(`You cannot create a project in range from $${checkPreMaxLimit} to $${checkNextMinLimit}`, 'warning');
        setInBetweenRange(true)
        setIsMaxLimitExceeded(false)
      } else if (checkMaxLimitExceeded && checkMaxLimit > 0) {
        showSnackbar(`You cannot create a project that exceeds the set range configuration $${checkMaxLimit}`, 'error');
      } else if (checkMaxLimitExceeded && checkMaxLimit == 0) {
        showSnackbar(`Range configuration is not set for the asset type`, 'error');
      }
    }
  }, [salePrice])

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left">
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  {propData?.selectedListingType === "loan"
                    ? "Now, set your loan details"
                    : "Now, set your price"}
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right  quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button
                    onClick={SaveAndExit}
                    className="btn-rounded btn-text-white btn-blue-600"
                    disabled={
                      (salePrice != "" && Boolean(salePrice > 0) && !isMaxLimitExceeded && !inBetweenRange
                        // && (1 <= parseFloat(investmentDuration) && parseFloat(investmentDuration) <= 12)  // comment if no need of investment duration
                      ) ? false : true
                    }
                  >
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box sx={{ padding: "inherit" }} className="qsn-middle-content">
                <Box className="questionnaire5" sx={{ pt: 4, mb: 5 }}>
                  <Box className="CategoryBox">
                    <Grid container direction="row">
                      {propData?.selectedListingType === "loan" ? <>
                        <Grid item xs={1} sm={1} md={2} lg={2.5}></Grid>
                        <Grid item xs={10} sm={10} md={8} lg={7} className="priceDetailsImage">
                          <SliderCardProser
                            height={"100%"}
                            image={(propData?.asset_coverphoto_view?.startsWith("proposer/uploads/")) ? `${process.env.REACT_APP_IMAGE_URL}${propData?.asset_coverphoto_view}` : propData?.asset_coverphoto_view}
                            title={propData?.asset_title}
                          />
                        </Grid>
                        <Grid item xs={1} sm={1} md={2} lg={2.5}></Grid></>
                        :
                        <Grid item xs={12} sm={12} md={12} className="priceDetailsImage">
                          <SliderCardProser
                            height={"80.76%"}
                            image={
                              propData?.selectedCategory === "nft" ||
                                propData?.asset_coverphoto_view?.startsWith(
                                  "proposer/uploads/"
                                )
                                ? `${process.env.REACT_APP_IMAGE_URL}${propData?.asset_coverphoto_view}`
                                : propData?.asset_coverphoto_view
                            }
                            title={propData?.asset_title}
                          />
                        </Grid>
                      }
                    </Grid>
                  </Box>
                </Box>
                <Grid container spacing={3} my={2} direction={'column'}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography className="inputFieldTitle">Sale Price</Typography>
                    <Box className="NS-sale-details">
                      <Box className="NS-sale-details-icon">
                        <IconButton
                          onClick={() => {
                            changeRaise("dec");
                          }}
                          className="NS-sale-details-icon-btn"
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Box>
                      <Box>
                        <TextField
                          id="outlined-number"
                          type="text"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={salePrice}
                          size="small"
                          helperText={"Sale Price"}
                          onChange={(e) => setSalePrice(parseFloat(e.target.value))}
                          InputProps={{
                            inputComponent: NumericFormatCustomDollar,
                          }}
                          onKeyDown={(event) => {
                            if (event?.key === '-' || event?.key === '+') {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Box>
                      <Box className="NS-sale-details-icon">
                        <IconButton
                          onClick={() => {
                            changeRaise("inc");
                          }}
                          className="NS-sale-details-icon-btn"
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>

                  {/* <Grid item xs={12} md={12} lg={12}>
                    <Typography className="inputFieldTitle">Project Expiry Duration</Typography>
                    <Box className='NS-sale-details'>
                      <Box className='NS-sale-details-icon'>
                        <IconButton onClick={() => { handleInvestmentDuration('dec') }} className="NS-sale-details-icon-btn">
                          <RemoveIcon />
                        </IconButton>
                      </Box>
                      <Box>
                        <TextField
                          id="outlined-number"
                          type="text"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={investmentDuration}
                          size="small"
                          helperText={(investmentDuration > 1 ? "Months" : 'Month')}
                          onChange={(e) => setInvestmentDuration(parseInt(e.target.value))}
                          InputProps={{
                            inputComponent: NumericFormatCustom
                          }}
                          onKeyDown={(event) => {
                            if (event?.key === '-' || event?.key === '+' || event?.key === '.') {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Box>
                      <Box className='NS-sale-details-icon'>
                        <IconButton onClick={() => { handleInvestmentDuration('inc') }} className="NS-sale-details-icon-btn">
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid> */}
                </Grid>

                <Typography sx={{ color: 'red', fontSize: '11px', textAlign: 'center', paddingTop: '10px' }}>{Boolean(salePrice === '') ? "Please enter sale price." : estimatedError ? "Sale price can not be greater than estimated value."
                  // : Boolean(investmentDuration === '') ? "Please enter project expiry duration." : (1 > parseFloat(investmentDuration) || parseFloat(investmentDuration) > 12) ? 'Project expiry duration must be between 1 to 12 months.' // comment if no need of investment duration
                  : isMaxLimitExceeded ? `You cannot create the project above $${maxLimit}.` : inBetweenRange ? `You cannot create a project in range from $${preMinLimit} to $${nextMaxLimit}.` : ""}
                </Typography>
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  mt={3}
                >
                  <Box textAlign={"left"} className="quests6-btn-box">
                    <Button
                      className="btn-rounded back-btn"
                      onClick={handleback}
                    >
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className="quests-btn-box">
                    <Button
                      disabled={
                        (salePrice != "" && Boolean(salePrice > 0) && !isMaxLimitExceeded && !inBetweenRange
                          // && (1 <= parseFloat(investmentDuration) && parseFloat(investmentDuration) <= 12) // comment if no need of investment duration
                        ) ? false : true
                      }
                      onClick={handleClick}
                      endIcon={<KeyboardArrowRightIcon />}
                      className="btn-rounded btn-text-white btn-blue-600"
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
