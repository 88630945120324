import React, { useState } from 'react'
import { Box, Button, MenuItem, FormGroup, Checkbox, FormControlLabel, Grid, TextField, Typography, InputAdornment, IconButton } from '@mui/material'
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { Controller, useForm } from "react-hook-form";
import CountryCodes from "../../Data/countries.json"
import { LoadingButton } from '@mui/lab'
import { ArrowBack, Clear } from '@mui/icons-material';
import countryList from "react-select-country-list";
import { isSupportedCountry, isValidPhoneNumber } from "libphonenumber-js";
import './StaticModal.css'

const areaOfInterest = ['Audit', 'Contribute Code', 'Testing', 'Whitehat Hacking', 'Permissioned Rewards', 'Grants']
const countryCodeVal = CountryCodes;

export default function TechDeveloper({ handleSubmitParent, goBack }) {

    const [loading, setLoading] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const isFormValid = checkedItems.length > 0;

    const [questionText, setQuestionText] = useState("");
    const [textCount, setTextCount] = useState(["000"])

    // Getting country name
    const selectedCountries = ['United States', 'United Kingdom', 'Canada', 'India', 'Singapore', 'Brazil'];
    const initialCountryList = countryList().getData();

    // arranging country according to client requirement 
    const customSort = (a, b) => {
        const indexA = selectedCountries.indexOf(a.label);
        const indexB = selectedCountries.indexOf(b.label);

        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        } else if (indexA !== -1) {
            return -1;
        } else if (indexB !== -1) {
            return 1;
        }

        return a.label.localeCompare(b.label);
    };

    const sortedCountryList = initialCountryList.sort(customSort);
    // Getting country name- end

    /**
     * handleChange function for the Question/COmmenting text 
     * @param {*} e
     */
    const handleChangeText = (e) => {
        const textData = e.target.value
        if (textData.length < 200) {
            setQuestionText(e.target.value)

        }
        setTextCount(textData.length)
    }

    /**
     * clear Text field on close icon click
     */
    const handleClear = () => {
        setQuestionText("");
        setTextCount("000")
    }

    //handle checkbox logic
    const handleCheckboxChange = (item) => {
        setCheckedItems((prevSelected) => {
            if (prevSelected.includes(item)) {
                return prevSelected.filter((type) => type !== item);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    const {
        register,
        control,
        reset,
        watch,
        clearErrors,
        handleSubmit,
        formState: { errors },
    } = useForm(
        {
            defaultValues: {
            }
        }
    );
    const countryCodeNum = watch("country_code");
    const onSubmit = (data) => {
        let userData = "";

        if (checkedItems.length === 0) {
            // Show an error message or take the necessary action
            setIsSubmitted(true);
        } else {
            const country_data = data.country_code.split('-')
            userData = {
                country_code: country_data[0],
                phone_number: data.phoneNumber,
                area_of_interest: checkedItems,
                current_occupation: data.current_occupation,
                portfolio_url: data.portfolio,
                country: data.country_of_residence,
                year_of_experience: data.year_of_experience,
                primary_programming_language: data.primary_programmin_languages,
                previous_blockchain_experience: questionText,
            };
        }

        if (userData) {
            setLoading(false);
            handleSubmitParent(userData)
        }
    }

    const [countryIso, setCountryIso] = useState('');
    /**
    *  validate phone number with libphonenumber-js library
    * @param {string} value  phone number
    * @param {string} country_iso  country code
    * @returns 
    */
    const validatePhoneNumber = (value) => {
        const res = isSupportedCountry(countryIso);
        const countryCodeNumUpdate = countryCodeNum.split('-')[0];
        return isValidPhoneNumber(`+${countryCodeNumUpdate}${value}`, countryIso);
    }

    return (
        <Box className="investor-modal-container" sx={{ ml: { md: '50px', sm: '40px', xs: '5px' }, maxHeight: '70vh', overflowY: 'auto', marginTop: { md: '-70px' } }} >
            <Box className="staticHomepages provider-details-form" sx={{
                maxWidth
                    : '730px', mx: 'auto', mt: 2,
            }}>
                <Box p={{ xs: 2, sm: 2 }}
                    // bgcolor={'#000'} borderRadius={'16px'}
                    sx={{ maxWidth: "730px" }}
                    mt={{ xs: 2, sm: 3.5 }}
                >
                    <Typography className='title text-blue-900 font-36' color={'#fff'} component={'h2'}>Join us as a Tech Developer</Typography>

                    {/*Form starts from here*/}
                    <Box mt={3} component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Typography className='font-16 text-blue-900' color={'#fff'} mb={2}>Please submit the information below so we can optimize your experience as a Tech Developer on the RACE platform.</Typography>

                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={3} md={4} lg={3}>
                                <Box className="form-group label-field staticProposerModel">

                                    <Controller name="country_code" control={control}
                                        defaultValue={`${countryCodeVal[0].code}-${countryCodeVal[0].iso}`}
                                        rules={{
                                            required: "Country Code is required",
                                        }}
                                        InputLabelProps={{
                                            style: { color: '#fff' }, // Set the label text color to white
                                        }}
                                        InputProps={{
                                            style: { color: '#fff' }, // Set the input text color to white
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                select
                                                name="country_code"
                                                {...field}
                                                error={Boolean(errors.country_code)}
                                                helperText={errors.country_code?.message}
                                                label="Country Code"
                                                InputLabelProps={{
                                                    style: { color: '#fff' }, // Set the label text color to white
                                                }}
                                                InputProps={{
                                                    style: { color: '#fff' }, // Set the input text color to white
                                                }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200, // Adjust the maximum height according to your needs
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                {countryCodeVal.map((option, index) => (
                                                    <MenuItem

                                                        key={index}
                                                        value={`${option.code}-${option.iso}`}
                                                        onClick={() => {
                                                            setCountryIso(option.iso)
                                                        }}
                                                    >
                                                        {`+${option.code} ${option.country}  `}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )} />

                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={9} md={8} lg={9}>
                                <Box className="form-group label-field staticProposerModel">
                                    <TextField
                                        name="phoneNumber"
                                        label="Phone Number"
                                        type="text"
                                        pattern="[0-9]*"
                                        placeholder="Enter phone number"
                                        {...register("phoneNumber", {
                                            required: "Phone number is required..",
                                            validate: (value) => validatePhoneNumber(value, countryCodeNum) || "Please enter valid phone number",
                                        })}
                                        autoComplete="tel" // this line for auto-fill
                                        error={Boolean(errors.phoneNumber)}
                                        helperText={(errors?.phoneNumber?.message)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneIphoneIcon className="text-blue-600" />
                                                </InputAdornment>
                                            ),
                                            style: { color: '#fff' },
                                        }}
                                        onInput={(event) => {
                                            event.target.value = event.target.value
                                                .replace(/\D/g, "")
                                                .slice(0, 15);
                                        }}
                                        InputLabelProps={{
                                            style: { color: "#fff" }, // Set the label text color to white
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Box mb={3} >
                            <TextField
                                className='roles_select'
                                InputLabelProps={{
                                    // shrink: true,
                                    style: { color: '#fff' }, // Set the label text color to white
                                }}
                                label="Country of Residence"
                                id="outlined-select-country-native"
                                select
                                name='country_of_residence'
                                {...register("country_of_residence", {
                                    required: "Country of Residence is required.",
                                })}
                                error={Boolean(errors.country_of_residence)}
                                helperText={errors.country_of_residence?.message}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200, // Adjust the maximum height according to your needs
                                                maxWidth: '50%',
                                            },
                                        },
                                    },
                                }}
                                InputProps={{
                                    style: { color: '#fff' }, // Set the input text color to white
                                }}
                                fullWidth
                                onChange={() => clearErrors("country_of_residence")}
                            >
                                {
                                    sortedCountryList?.map((item, index) => (
                                        <MenuItem key={index} value={item?.label}>{item?.label}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Box>

                        <Typography className='font-18 text-blue-900' color={'#fff'} marginTop={"15px"} whiteSpace={{ lg: 'nowrap', md: 'normal' }} mr={2}>Professional Background</Typography>

                        {/* Current Occupation/Role field  */}
                        <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }}>
                            <Box
                                className="form-group label-field staticProposerModel"
                                mt={2}
                                sx={{ width: "fit-" }}
                            >
                                <TextField
                                    label={'Current Occupation/Role'}
                                    placeholder="Enter your current role"
                                    InputProps={{
                                        style: { color: "#fff" },
                                    }}
                                    InputLabelProps={{
                                        style: { color: "#fff" }
                                    }}
                                    name="current_occupation"
                                    {...register("current_occupation", {
                                        required: "Current Occupation/Role is required.",
                                    })}
                                    error={Boolean(errors.current_occupation)}
                                    helperText={errors.current_occupation?.message}
                                />
                            </Box>
                        </Box>

                        {/* Year Of Operation In development */}
                        <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }}>
                            <Box
                                className="form-group label-field staticProposerModel"
                                mt={2}
                                sx={{ width: "fit-" }}
                            >
                                <TextField
                                    label={'Years of Experience in Development'}
                                    placeholder="Enter your years of experience"
                                    InputProps={{
                                        style: { color: "#fff" },
                                    }}
                                    InputLabelProps={{
                                        style: { color: "#fff" }
                                    }}
                                    name="year_of_experience"
                                    {...register("year_of_experience", {
                                        required: "Years of Experience in Development is required.",
                                        pattern: {
                                            value: /^[0-9]*\.?[0-9]*$/, // Allows only numeric values with an optional dot for decimals
                                            message: "Please enter a valid number.",
                                        },
                                    })}
                                    error={Boolean(errors.year_of_experience)}
                                    helperText={errors.year_of_experience?.message}
                                />
                            </Box>
                        </Box>

                        {/* Primary Programming Languages*/}

                        <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }}>
                            <Box
                                className="form-group label-field staticProposerModel"
                                mt={2}
                                sx={{ width: "fit-" }}
                            >
                                <TextField
                                    label={'Primary Programming Languages'}
                                    placeholder="Enter your primary programming languages, separated by commas"
                                    InputProps={{
                                        style: { color: "#fff" },
                                    }}
                                    InputLabelProps={{
                                        style: { color: "#fff" }
                                    }}
                                    name="primary_programmin_languages"
                                    {...register("primary_programmin_languages", {
                                        required: "Primary Programming Languages is required.",
                                    })}
                                    error={Boolean(errors.primary_programmin_languages)}
                                    helperText={errors.primary_programmin_languages?.message}
                                />
                            </Box>
                        </Box>

                        <Typography className='font-18 text-blue-900 ' color={'#fff'} whiteSpace={{ lg: 'nowrap', md: 'normal' }} mr={2}>Areas of Interest in RACE</Typography>
                        <Box display={'flex'} gap={2} flexWrap={'wrap'}>
                            {
                                areaOfInterest?.map((item, index) => {
                                    return <FormGroup >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkedItems.includes(item)}
                                                    onChange={() => handleCheckboxChange(item)}
                                                    style={{ color: '#fff' }}
                                                />
                                            }
                                            label={item} style={{ color: '#fff' }}

                                        ></FormControlLabel>
                                    </FormGroup>
                                })
                            }
                        </Box>
                        {isSubmitted && !isFormValid && (
                            <Typography variant='body2' style={{ color: '#d32f2f' }}>Areas of Interest is required</Typography>
                        )}

                        <Typography className='font-18 text-blue-900 ' color={'#fff'} whiteSpace={{ lg: 'nowrap', md: 'normal' }} mr={2} mt={2}>Previous experience with Blockchain Technology (if any)</Typography>
                        <Box
                            className="form-group label-field staticProposerModel"
                            mt={3}
                            sx={{ width: "fit-" }}
                        >
                            <TextField
                                multiline
                                value={questionText}
                                onChange={(e) => handleChangeText(e)}
                                //   {...register("textContent")}
                                InputProps={{
                                    style: { color: "#fff" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClear}
                                                size="small"
                                            >
                                                <Clear className='multileClearText-btn' />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { color: "#fff" }, // Set the label text color to white
                                }}
                            />
                            {isSubmitted && !questionText ? <Typography style={{ color: '#d32f2f' }} padding={'4px 0px 0px 14px'}>List your experience with Blockchain Technology is required!</Typography> :
                                <Typography padding={'4px 0px 0px 14px'}>{textCount}/200</Typography>}
                        </Box>





                        {/* Portfolio Link Field */}
                        <Grid item xs={12} sm={12} mt={3}>
                            <Box className="form-group label-field staticProposerModel">
                                <TextField
                                    name="portfolio"
                                    label="Portfolio Link"
                                    placeholder="Paste link to your investment/portfolio showcase"
                                    {...register("portfolio", {
                                        required: "Portfolio URL is required.",
                                        pattern: {
                                            // value: /^(https?:\/\/)?[\w.-]+\.[a-z]{2,}$/i,
                                            message: "Please enter valid url",
                                        },

                                    })}
                                    autoComplete='url'
                                    error={Boolean(errors.portfolio)}
                                    helperText={errors.portfolio?.message}
                                    InputLabelProps={{
                                        style: { color: '#fff' }, // Set the label text color to white
                                    }}
                                    InputProps={{
                                        style: { color: '#fff' }, // Set the input text color to white
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 6 }}>
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                fullWidth
                                className="btn-rounded btn-large btn-blue-600 btn-text-white"
                                type="submit"
                                onClick={() => setIsSubmitted(true)}
                            >
                                Submit and join waitlist
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Button sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        marginTop: '-25px',
                        '@media screen and (max-width: 600px)': {
                            // Styles for screens smaller than 600px (adjust the value as needed)
                            width: '100%', // Make the button full width on smaller screens
                            marginTop: '0', // Adjust margin top for smaller screens
                        },
                    }} onClick={goBack}><ArrowBack style={{ marginRight: '13px' }} /> <span>Go Back</span></Button>
                </Box>
            </Box >
        </Box>
    )
}

