import React, { useEffect, useState } from "react";
import { Close, Done, Margin, Padding, Person, Repeat } from "@mui/icons-material";
import {
  Modal,
  Box,
  Stack,
  Avatar,
  Grid,
  IconButton,
  Typography,
  Alert,
  Button,
  Paper,
  CircularProgress,
  Fade,
  createTheme,
} from "@mui/material";
import { NewLogo, melbourneBlueprint } from "../../Components/Images/Images";
import { getNFTsForOwner } from "../../Web3/Alchemy";
import { updateApi } from "../../Api/Api";
import { useAccount, useDisconnect } from "wagmi";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
import { ThemeProvider } from "@emotion/react";
import { useSelector, useDispatch } from "react-redux";
import { update } from "../../features/auth/authSlice";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"; //eslint-disable-next-line
import { OwnedNft } from "alchemy-sdk"; //for type checking do not remove
import "./BluePrintNFT.css";
import { tokenOfOwnerByIndex } from "../../Components/SmartContract/tokenOfOwnerByIndex";
import BluePrintNftAll from "../BluePrintNFT/BluePrintNftAll.json"
import { nftBalanceOf } from "../../Components/SmartContract/nftBalanceOf";
import { tokenUri } from "../../Components/SmartContract/tokenUri";
import axios from "axios";

const style = {
  position: "relative",
  boxShadow: 24,
  borderRadius: "24px",
  // width: "900px",
  // Media query for small devices
  "@media (max-width: 600px)": {
    width: "350px", // Set a different height for small screens
    height: "800px",
    overflow: "auto",
    Padding: '10px',
  },
  // Additional media queries for other screen sizes if needed
  "@media (min-width: 601px) and (max-width: 900px)": {
    // Custom styles for devices between 601px and 900px width
    width: "580px",
  },

  "@media (min-width: 901px) and (max-width: 1200px)": {
    // Custom styles for devices between 901px and 1200px width
    width: "750px",
  },

  // Specify the maximum width for large screens
  "@media (min-width: 1201px)": {
    maxWidth: "1043px",
    // maxHeight: "687px",
  },
};

const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        filledInfo: {
          backgroundColor: "#1E293B",
        },
        filledSuccess: {
          backgroundColor: "#059669",
        },
        filledWarning: {
          backgroundColor: "#B45309",
        },
        filledError: {
          backgroundColor: "#B91C1C",
        },
      },
    },
  },
});

function BluePrintNFTSelection({ open, setOpen, onClose }) {
  // Importing necessary hooks and functions
  const userData = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const { showSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [alert, setAlert] = useState({
    message:
      "Clicking the link button will prompt your wallet - please make sure your Bluprint NFT is in the connected wallet.",
    severity: "info",
  });


  // useEffect to update the alert message based on the user's wallet address
  useEffect(() => {
    let u = JSON.parse(localStorage.getItem("user_data"));
    if (u?.wallet_address && u?.wallet_address > 10) {
      setAlert({
        message: `Clicking Fetch will get your BluePrint NFTs from associated wallet : ${u?.wallet_address} `,
        severity: "info",
      });
    }
  }, []);

  // useEffect to handle the setting of BluePrint NFT as a Persona
  useEffect(() => {
    console.log("userData?.persona_picture...", userData)
    console.log("userData?.persona_picture.length...", userData?.persona_picture?.length)
    if (userData?.persona_picture && userData?.persona_picture.length > 10) {
      setAlert({
        message: "BluePrint NFT has been set as a Persona successfully.",
        severity: "success",
      });
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
  }, [userData?.persona_picture]);

  // Wallet connection code start
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setWalletDialogOpen(true);
  };

  const handleCloseDialog = (value) => {
    setWalletDialogOpen(false);
  };

  /**
   * gets called when a wallet is connect successfully
   * @param {*} acc
   */
  const walletLogin = async (acc) => {
    setOpenModal(true); //ask for confirmation
    dispatch(update({ wallet_address: acc?.toLowerCase() }));
  };

  /**
   * Function updates user wallet address in db
   * @param {*} acc wallet address
   */
  const updateUserWallet = async (acc) => {
    let user = JSON.parse(localStorage.getItem("user_data"));
    try {
      const res = await updateApi("/user/updateUser/" + user?.id, {
        wallet_address: acc,
      });
      if (res?.data?.code === 200) {
        showSnackbar("Wallet connected successfully", "success");
        dispatch(update({ ...user, wallet_address: acc }));
        setAlert({
          message: `Clicking Fetch will get your BluePrint NFTs from associated wallet : ${acc} `,
          severity: "info",
        });
      } else if (res?.data?.code === 201) {
        showSnackbar(
          "Wallet is already registered with another account",
          "error"
        );
        dispatch(update({ wallet_address: null }));
        // setUserData({ ...userData, wallet_address: null });
        disconnect();
      } else {
        showSnackbar(res?.data?.message, "error");
        dispatch(update({ wallet_address: null }));
        disconnect();
      }
      handleConfirmationModalClose();
    } catch (error) {
      showSnackbar("Something went wrong", "error");
      handleConfirmationModalClose();
      disconnect();
    }
  };

  /**
   * Function handles close of confirmation modal
   * @param {*} reason  reason for modal close auto generated by material ui
   */
  const handleConfirmationModalClose = (reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return; //do nothing basically do not close modal
    } else {
      setOpenModal(false);
    }
  };

  /**
   * Function handles confirmation of wallet to be associated with user account
   * @param {*} answer  answer from user yes or no
   */
  const handleConfirmation = (answer) => {
    if (answer === "yes") {
      updateUserWallet(userData?.wallet_address);
    } else {
      handleConfirmationModalClose();
      dispatch(update({ wallet_address: null }));
      disconnect();
    }
  };
  // Wallet connection code end

  return (
    <Modal open={open} onClose={onClose} className="kyc-modal kyc-modal-main" display={"flex"}>
      <Fade in={open} timeout={300}>
        <Box className="" sx={style}>
          <Grid container spacing={0} height={"100%"}>
            <Grid item xs={12} sm={12} md={4} className="kycIpi-col-left">
              <Box className="kycIpi-left">
                <Box className="logo">
                  <Avatar
                    alt="Logo"
                    src={NewLogo}
                    variant="square"
                    sx={{ width: 39, height: 90 }}
                  />
                </Box>

                <Box>
                  <Typography className="font-28" mt={4}>
                    Just a few clicks away from your first investment.
                  </Typography>
                  <Typography className="font-16" mt={2}>
                    Other membership benefits, like events. Choose Bluprint
                    based on your career and your role on the RACE platform. To
                    learn more, click here.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} className="kycIpi-col-right">
              <Box className="kycIpi-right" height={"100%"}>
                <OverlayScrollbarsComponent defer className="kycPopup-scroll">
                  <Box className="kyc-individual-step-wrap step-01">
                    <Stack
                      className="navigation"
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <IconButton sx={{ mr: -1 }} onClick={onClose}>
                        <Close />
                      </IconButton>
                    </Stack>
                    <Box className="heading">
                      <Typography component={"h3"} className="title">
                        Authenticate your Bluprint as your persona.
                      </Typography>
                      <Typography component={"h3"} className="subtitle">
                        Don’t have a Bluprint NFT? Get yours here.
                      </Typography>
                    </Box>
                    <Box className="authenticate-bluprint-nft" mt={4} mb={4}>
                      <Stack
                        direction="row"
                        spacing={0}
                        alignItems={"center"}
                        justifyContent={"space-around"}
                        maxWidth={"280px"}
                        marginLeft={"auto"}
                        marginRight={"auto"}
                      >
                        <Box>
                          <Box
                            className="bg-golden"
                            p={2}
                            width={"96px"}
                            height={"96px"}
                            borderRadius={"28px"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Person
                              className="text-white"
                              sx={{ fontSize: "36px" }}
                            />
                          </Box>
                        </Box>
                        <Repeat />
                        <Box>
                          <Box
                            className="bg-golden"
                            width={"96px"}
                            height={"96px"}
                            borderRadius={"28px"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Avatar
                              alt="Logo"
                              src={melbourneBlueprint}
                              variant="square"
                              sx={{
                                width: "96px",
                                height: "96px",
                                borderRadius: "28px",
                              }}
                            />
                          </Box>
                        </Box>
                      </Stack>
                    </Box>
                    <Box className="btn-wrap" pt={3}>
                      <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        direction={{ md: "row", sm: "column" }}
                        spacing={1}

                      >
                        <Button
                          fullWidth={true}
                          className="btn-rounded btn-large btn-blueGray-400 btn-text-white w-100 "
                          onClick={() => setOpen(false)}
                          mb={2}
                        >
                          Connect Later
                        </Button>
                        {userData?.wallet_address ? (
                          <SelectBlueChipModal
                            setAlert={setAlert}
                            userData={userData}
                            dispatch={dispatch}
                            closePreModal={onClose}

                          />
                        ) : (
                          <Button
                            fullWidth={true}
                            className="btn-rounded btn-large btn-blue-600 btn-text-white w-100 "
                            onClick={handleClickOpen}
                            mt={2}
                          >
                            Connect Wallet
                          </Button>
                        )}
                      </Stack>
                    </Box>
                    <ThemeProvider theme={theme}>
                      <Box mt={2} mx={"auto"} width={"95%"}>
                        <Alert
                          variant="filled"
                          severity={alert.severity}
                          sx={{ borderRadius: "8px" }}
                        >
                          {alert.message}
                        </Alert>
                      </Box>
                    </ThemeProvider>
                  </Box>
                </OverlayScrollbarsComponent>
              </Box>
            </Grid>
          </Grid>
          <ConnectWalletInvestor
            open={walletDialogOpen}
            handleCloseDialog={handleCloseDialog}
            handleConnectedSuccess={walletLogin}
          />
          <Modal
            open={openModal}
            className="connectWallet-alert-modal"
            onClose={handleConfirmationModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEscapeKeyDown={true}
          >
            <Fade in={openModal} timeout={300}>
              <Box className="modal-body" sx={style}>
                <Box className="headContent">
                  <Box className="logo">
                    <Avatar
                      alt="Logo"
                      src={NewLogo}
                      variant="square"
                      sx={{ width: 39, height: 90 }}
                    />
                  </Box>
                </Box>
                <Box className="modalContent ">
                  <Box className="main-content">
                    <Typography component="h1" className="headText">
                      Confirmation
                    </Typography>
                    <Typography component="p" className="sub-headText">
                      Are you sure you want to associate this wallet :{" "}
                      {userData?.wallet_address} with your account!
                    </Typography>
                    <Box className="vote-btn-box">
                      <Button
                        className="wallet-button appr-btn"
                        onClick={() => {
                          handleConfirmation("yes");
                        }}
                      >
                        {" "}
                        <Box className="btn-icon">
                          <Done />
                          Yes, Associate
                        </Box>
                      </Button>
                      <Button
                        className="wallet-button rjct-btn"
                        onClick={() => {
                          handleConfirmation("no");
                        }}
                      >
                        <Box className="btn-icon">
                          <Close />
                          Cancel
                        </Box>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </Box>
      </Fade>
    </Modal>
  );
}

export default BluePrintNFTSelection;

const style2 = {
  position: "relative",
  boxShadow: 24,
  borderRadius: "24px",
  width: "670px",
  maxHeight: "90vh",
  "@media (max-width: 600px)": {
    margin: '0px 15px',
  },
};

function SelectBlueChipModal({ setAlert, userData, dispatch, closePreModal }) {
  const [open, setOpen] = React.useState(false);
  const [blueChipNFTs, setBlueChipNFTs] = useState([]);
  const [nftJson, setNftJson] = useState(BluePrintNftAll);
  const { address } = useAccount();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFetchBluePrint = async () => {
    setOpen(true); // open modal

    try {
      const res = await getBlueChipNFTs(userData?.wallet_address, userData?.id);
      console.log("res.length...fetch blueprint", userData?.wallet_address)
      console.log("res...fetch blueprint", res)
      if (res.length > 0) {
        setBlueChipNFTs(res);
      } else {
        handleClose();
        if (address) {
          setAlert({
            message: "No BluePrint NFT found in your wallet",
            severity: "error",
          });
        } else {
          setAlert({
            message: "Connect your Wallet",
            severity: "error",
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * func gets blue chip nfts of user
   * @param {string} walletAddress  wallet address of user
   * @param {string} userId  user id of user
   * @returns {Promise<OwnedNft[]>}
   */
  const getBlueChipNFTs = async (walletAddress, userId) => {
    let res = await nftBalanceOf(walletAddress);
    let calls = [];
    let metaData = [];
    let result;
    if (res >= 0) {
      for (let index = 0; index < Number(res); index++) {

        const nftData = async () => {
          let tokenIdByIndex = await tokenOfOwnerByIndex(walletAddress, index)
          let generateTokenURI = tokenUri(tokenIdByIndex);
          return generateTokenURI;
        }
        calls.push(nftData());
      }
      try {
        result = await Promise.all(calls);
    
        // Check for any undefined or null values instead
        const containsInvalid = result.some(item => item === undefined || item === null);
        console.log("Contains invalid values:", containsInvalid);
    
        if (containsInvalid) {
            return; // Exit the function if invalid values are found
        }

        const metaDataPromises = result.map(url => axios.get(url)); // Create an array of promises
        const metaDataResponses = await Promise.all(metaDataPromises); // Await all promises
        const metaDataVal = metaDataResponses.map(response => response.data); // Extract data from responses
        metaData.push(...metaDataVal); // Spread to push all metadata into the metaData array
      } catch (error) {
        console.log("Error", error);
      }

    }
    return metaData
  };

  /**
   * Function sets blue chip nft of user and updates in db and local storage
   * @param {string} imgUrl  image url of nft
   * @param {string} userId  user id of user
   */
  const setBlueChipNFT = async (imgUrl, userId) => {
    try {
      const res = await updateApi("/user/updateUser/" + userId, {
        persona_picture: imgUrl,
      });
      setAlert({
        message: "BluePrint NFT has been set as a Persona successfully.",
        severity: "success",
      });
      console.log("imgUrl...", imgUrl)
      dispatch(update({ persona_picture: imgUrl }));
      handleClose();
      closePreModal();
    } catch (error) {
      // showSnackbar('Something went wrong', 'error');
      console.log("error", error);
    }
  };

  return (
    <>
      <Button
        fullWidth
        className="btn-rounded btn-large btn-blue-600 btn-text-white w-100 fetch-blueprint"
        onClick={handleFetchBluePrint}
        sx={{ mt: { xs: '10px', sm: '0px' } }}
      >
        Fetch BluePrint NFTs
      </Button>
      <Modal
        open={open}
        className="connectWallet-alert-modal"
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open} timeout={300}>
          <Box className="modal-body" sx={style2}>
            <Box className="modalContent ">
              <Box className="w-100">
                {blueChipNFTs.length === 0 ? (
                  <>
                    <Typography
                      component="h1"
                      className="headText"
                      textAlign={"center"}
                      mb={3}
                    >
                      Fetching...
                    </Typography>
                    <Box textAlign={"center"} py={6}>
                      <CircularProgress />
                    </Box>
                  </>
                ) : (
                  <Typography
                    component="h1"
                    className="headText"
                    textAlign={"center"}
                    mb={3}
                  >
                    Select Blueprint NFT
                  </Typography>
                )}

                <Grid
                  container
                  spacing={2}
                  className="grid-container"
                  justifyContent={"center"}
                >
                  {console.log("blueChipNFTs...", blueChipNFTs)}
                  {blueChipNFTs?.map((nft, index) => {
                    return (
                      <Grid
                        item
                        key={index}
                        xs={4}
                        sm={4}
                        className="grid-item"
                      >
                        <Paper
                          elevation={3}
                          sx={{ p: 2, mb: 2 }}
                          className="rc-card-container "
                        >
                          <Avatar
                            variant="square"
                            src={`${nft.image}`}
                            alt="Img"
                            sx={{
                              width: "100%",
                              height: "137px",
                              borderRadius: "16px",
                            }}
                          />
                          <Box sx={{ mt: 1 }}>
                            <Typography variant="subtitle2" gutterBottom>
                              {nft.name}
                            </Typography>
                            {/* <Typography
                              variant="body2"
                              gutterBottom
                              color={"white"}
                            >
                              {nft.data.description[0].description}
                            </Typography> */}
                            <Button
                              className="btn-rounded  btn-blue-300 btn-text-white "
                              onClick={() =>
                                setBlueChipNFT(
                                  nft.image,
                                  userData?.id
                                )
                              }
                              variant="contained"
                            >
                              Select
                            </Button>
                          </Box>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
