import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Chip, Box, Avatar, Typography, Slider, Stack, Tooltip, IconButton, AvatarGroup, Button, } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import { HouseRounded, LightModeRounded, Bookmark, PhotoSizeSelectActual, Watch, Paid, Balance, ViewWeek, Edit, ColorLens } from "@mui/icons-material";
import { YellowWatchIcon, NFT } from "../../Components/Images/Images";
import { postApi, GetApiParam } from "../../Api/Api";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import SetRTs from "../Modals/Components/SetStackDic";
import ModalContent from "../Modals/ModalContent";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./DicCard.css";
import { CATEGORY_TYPE_ID } from "../../constants";

const theme = createTheme({
});

export const DicCard = (props) => {
  const navigate = useNavigate();
  const [openVotingModal, setOpenVotingModal] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [pendingReview, setPendingReview] = useState(false);
  const [openReviewSuggestionModal, setReviewSuggestionModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalFirst, setOpenModalFirst] = useState(false);
  const [allModalClosed, setAllModalClosed] = useState(false);
  const [updateReviewData, setUpdatedReviewData] = useState([])
  const { showSnackbar } = useSnackbar();
  //  Setting Functionality for the Clock Timer
  const [countdownTime, setCountdownTime] = useState("");
  let progressVal = "";

  const votingModal = () => {
    setOpenVotingModal(true);
  };
  const projectData = {
    project: props,
  };
  const DraftEditData = {
    category_id: props.category_id,
    id: props.id,
    EditMode: true,
  };

  const handleReviewSuggestionModal = (vals) => {
    setReviewSuggestionModal(vals)
  }

  /**
   * Checking for the condition where all the commitie mamber has reviewd the project .
   */
  useEffect(() => {
    if (props?.memberDetails?.length >= props?.dicMember?.length) {
      const userStatus = props.memberDetails?.every(
        (elem) => elem.status !== 0
          && elem.status !== 5
      );
      setPendingReview(userStatus);
    }
  }, [props]);


  /**
   * getting response of proposer
   */
  useEffect(() => {
    async function getSuggestReview() {
      try {
        const localData = JSON.parse(localStorage.getItem("user_data_dic"));
        if (localData.id && props.from === "dicproject") {
          const overviewPayload = {
            asset_id: props?.asset_id,
            proposer_id: props?.proposer_id,
          };

          const res = await GetApiParam(
            "/committee/getSuggestReview",
            overviewPayload
          );
          if (res?.data?.data[0]) {
            setUpdatedReviewData(res?.data?.data[0])
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    getSuggestReview()
  }, [])


  /**
   * Function for getting values  of hrs, minute and second for the Timer
   * @param {*} data 
   * @returns 
   */
  const countdownTimer = (data) => {
    if (props.investment_expiry_time == null) {
      document.removeEventListener("clocktime", countdownTimer);
      return;
    }

    const expiryDate = new Date(props.investment_expiry_time);
    const currentTime = new Date(data.detail);
    const remainingDayTime = expiryDate - currentTime;
    let totalDays = Math.ceil(remainingDayTime / (1000 * 60 * 60 * 24));
    let totalHours = Math.floor(Math.abs(expiryDate - currentTime) / 36e5);
    let totalMinutes = 60 - currentTime.getMinutes();
    let totalSeconds = 60 - currentTime.getSeconds();

    if (totalDays < 1) {
      document.removeEventListener("clocktime", countdownTimer);
      return;
    }
    if (totalDays > 1) {
      setCountdownTime(totalDays + " days");
      // Removing Event Listner for more then 1 day(24 hrs)
      document.removeEventListener("clocktime", countdownTimer);
    } else {
      if (totalSeconds < 10) {
        totalSeconds = "0" + totalSeconds;
      }
      if (totalMinutes < 10) {
        totalMinutes = "0" + totalMinutes;
      }
      if (totalSeconds == 60) {
        totalSeconds = "00";
      }
      if (totalMinutes == 60) {
        totalMinutes = "00";
      }
      setCountdownTime(totalHours + ":" + totalMinutes + ":" + totalSeconds);
    }
  };

  /**
   * Getting Dispatch value from project page and  assigning to the function
   */

  // if any listener is already there should be removed first
  document.removeEventListener("clocktime", countdownTimer);
  // then allocate next listener
  document.addEventListener("clocktime", countdownTimer);

  // Setting link into string
  const viewMore = (
    <Link
      className="orange-link"
      to={props.imgSrc.length > 0 ? props.link : ""}
    >
      here
    </Link>
  );

  /**
   * Rendering Icons According to condition
   */
  const iconPack = {
    "Real Estate": <HouseRounded />,
    Art: <ColorLens />,
    NFT: <PhotoSizeSelectActual />,
    Energy: <LightModeRounded />,
    Collection: <Watch />,
    Watch: <Watch />,
    Funds: <Paid />,
    LLC: <Balance />,
    Gold: <ViewWeek />,
  };

  /**
   * Onclick function for Editing Draft
   * @param {*} dataForDraft 
   */
  const handleClickDraft = (dataForDraft) => {
    navigate("/questionnaire-flow", {
      state: {
        category_id: dataForDraft?.category_id,
        id: dataForDraft?.id,
        EditMode: true,
      },
    });
  };

  // Function to get the first letter of a string
  const getFirstLetter = (str) => {
    return str.charAt(0);
  };

  /**
   *  Logic for the progress bar for inprogress
   **/

  if (props.checklistData) {

    let checklistDataLength = props.checklistData.length;
    let totalChecked = props.checklistData;

    let repaymentDataStatus = props.repaymentStatus;
    let assetCategory = props?.assetDataDetail?.assetDetails?.assets_category?.id;

    console.log("repaymentDataStatus....", repaymentDataStatus)
    console.log("assetCategory....", assetCategory)





    let checkedItem = 0;

    for (const item of totalChecked) {
      if (item.checked === true) {
        checkedItem++;
      }
    }

    if (props?.confirm_review === true && props?.is_lead === true) {
      checkedItem++;
    }
    if (props?.suggest_review === true && props?.is_lead === true) {
      checkedItem++;
    }
    if ((assetCategory === CATEGORY_TYPE_ID.ASSET || assetCategory === CATEGORY_TYPE_ID.WATCH || assetCategory === CATEGORY_TYPE_ID.GOLD) && repaymentDataStatus) {
      console.log("come inside")
      checkedItem++;
    }

    if (props?.is_lead) {
      checklistDataLength += 2;
    }

    if (assetCategory === CATEGORY_TYPE_ID.ASSET || assetCategory === CATEGORY_TYPE_ID.WATCH || assetCategory === CATEGORY_TYPE_ID.GOLD) {
      checklistDataLength += 1;
    }


    console.log("checkedItem...", checkedItem)
    console.log("checklistDataLength...", checklistDataLength)
    const progress = (checkedItem / checklistDataLength) * 100;
    progressVal = Math.round(progress);
  }

  /**
   * handleClick function for start review
   */
  const handleClickStartReview = async () => {
    const localData = JSON.parse(localStorage.getItem("user_data_dic"));
    if (localData.id) {
      const reviewBody = {
        committee_member_id: localData?.id,
        asset_id: props.id,
      };
      const resAssigned = await postApi("/committee/assignProject", reviewBody);
      if (resAssigned?.data?.code === 200) {
        // navigate(`/dic/project-details/`);
        navigate(`/dic/project-details/${props.id}`);
      } else {
        showSnackbar(`${resAssigned?.data?.massage}`, "error");
      }
    } else {
      showSnackbar("Member id is missing.", "error");
    }
  };

  /**
   * Function to set modal open
   */
  const handleStartReview = () => {
    setOpenModal(true);
  }

  /**
   * Handle User Response and open second modal
   * @param {Object} data 
   */
  const userResponse = (data) => {
    setOpenModal(false);
    navigate(`/dic/project-details/${props.id}`);
    // setOpenModalFirst(true);
    // setAllModalClosed(true);
  };

  /**
  * Function to handle model close
  */
  const handleClose = () => {
    setOpenModal(false);
  };

  /**
   * Onclick function for closing second Modal 
   */
  const handleModalCloseFirst = () => {
    setOpenModalFirst(false);
    setAllModalClosed(true);
  };

  // Both the modal closed and user filled all the required data then calling start review function
  useEffect(() => {
    if (allModalClosed) {
      // handleClickStartReview()
    }
  }, [allModalClosed])

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box className="product-commonCard-dic">
          <Box
            className="pc-card-body"
            position={"relative"}
            sx={{ marginBottom: props.from === "overview" ? "35px" : "0px" }}
          >
            {/* Edit mode for the draft project */}
            {props.isDraft && (
              <>
                <Box>
                  <IconButton
                    className="rc-asset-card-save-button"
                    onClick={() => handleClickDraft(props)}
                  >
                    <Edit />
                  </IconButton>
                </Box>
              </>
            )}
            {props.isSaved ? (
              <Box
                className="pc-bookmark"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Bookmark />
              </Box>
            ) : (
              ""
            )}
            <Box className="pc-thumbnail-wrap">
              {props.imgSrc.length > 0 ? (
                <Carousel
                  showThumbs={false}
                  showStatus={false}
                  showArrows={false}
                >
                  {(props.imgSrc || []).map((img, ind) =>
                    props.id ? (
                      <Link
                        to={props.isDraft ? "/questionnaire-flow" : props.link}
                        state={props.isDraft ? DraftEditData : projectData}
                        key={ind}
                      >
                        <Box className="pc-slide" sx={{ cursor: "pointer" }}>
                          <img src={img} alt="" />
                        </Box>
                      </Link>
                    ) : (
                      <Link
                        to={props.isDraft ? "/questionnaire-flow" : props.link}
                        state={props.isDraft ? DraftEditData : projectData}
                        key={ind}
                      >
                        <Box className="pc-slide 1" sx={{ cursor: "pointer" }}>
                          <img src={img} alt="" />
                        </Box>
                      </Link>
                    )
                  )}
                </Carousel>
              ) : (
                <Link
                  to={props.isDraft ? "/questionnaire-flow" : props.link}
                  state={props.isDraft ? DraftEditData : projectData}
                >
                  <Box className="pc-slide 1" sx={{ cursor: "pointer" }}>
                    <img src={NFT} alt="" />
                  </Box>
                </Link>
              )}
            </Box>
            <Box className="pc-content" position={"relative"}>
              {props.isClock && props.investment_expiry_time ? (
                <Box className="pc-timer">
                  <Chip
                    avatar={
                      <Avatar
                        component={"square"}
                        alt="Icon"
                        src={YellowWatchIcon}
                      />
                    }
                    label={countdownTime}
                  />
                </Box>
              ) : (
                ""
              )}
              {props.isSaveDeal && props.from !== "savedeals" ? (
                <Chip
                  className={
                    props.status === 2
                      ? "pc-chip-status"
                      : "pc-chip-status status-rejected"
                  }
                  label={props.is_publish && props.status === 2 ? "live" : !props.is_publish && props.status === 2 ? "in-vote" : "rejected"}
                // label={props.status === 2 ? "Approved" : "rejected"}
                />
              ) : (
                ""
              )}


              {/* code for live status on card */}
              {props.is_voted ? (
                <Chip
                  className={
                    props.status === 2
                      ? "pc-chip-status"
                      : "pc-chip-status status-rejected"
                  }
                  label={
                    props.is_publish && props.status === 2
                      ? "live"
                      : !props.is_publish && props.status === 2
                        ? "in-vote"
                        : "rejected"
                  }
                />
              ) : (
                ""
              )}


              <Typography component={"h5"} className="pc-title">
                {props.title}
              </Typography>
              {props?.category_id != CATEGORY_TYPE_ID.WATCH && props?.category_id != CATEGORY_TYPE_ID.ASSET ? <Typography className="pc-subtitle two-line-ellipsis">
                {/* Adding Watch model instead of address for the Watch Category and Car Category */}
                {props?.subTitle ? props.subTitle : props?.address ? props?.address : ""}
              </Typography> : <Typography className="pc-subtitle two-line-ellipsis">{props?.assetDataDetail?.watchData?.watch_brand ? props?.assetDataDetail?.watchData?.watch_brand : props?.assetDataDetail?.assetDetails?.watchData?.watch_brand ? props?.assetDataDetail?.assetDetails
                ?.watchData?.watch_brand : props?.assetDataDetail?.assetInformation?.car_brand ? props?.assetDataDetail?.assetInformation?.car_brand :
                props?.assetDataDetail?.assetDetails?.assetInformation?.car_brand ? props?.assetDataDetail?.assetDetails?.assetInformation?.car_brand : ''}</Typography>}

              {/* Adding condition to render only for completed projects */}
              {(props.status === 2 || props.status === 3 || (props.status === 5 && props?.completedStatus)) &&
                props.from !== "overview" ? (
                <Box className="pc-chip-wrap" mt={1.2}>
                  <Tooltip title={props.investType} arrow>
                    <Chip className="pc-chip" label={props.investType} />
                  </Tooltip>
                  <Tooltip title={props.isLoan} arrow>
                    <Chip
                      className="pc-chip with-icon"
                      avatar={
                        <Avatar aria-label="recipe">
                          {iconPack[props.isLoan] ?? iconPack["Real Estate"]}
                        </Avatar>
                      }
                      label={props.isLoan === "Asset" ? "Luxury Cars" : props.isLoan}
                    />
                  </Tooltip>
                </Box>
              ) : null}

              {/* Adding condition to render only for All  projects "in-progress"*/}
              {props.status === 5 && !props?.completedStatus ? (
                <Stack
                  className="pc-progreesSlider"
                  direction="row"
                  alignItems={"center"}
                  spacing={2}
                  width={"100%"}
                  mt={0.6}
                >
                  <Box className="value">
                    {progressVal ? `${progressVal}%` : "0%"}
                  </Box>
                  <Box width={"100%"}>
                    <Slider
                      style={{ maxWidth: 500 }}
                      value={`${progressVal}`}
                      disabled
                      step={1}
                      max={100}
                      valueLabelDisplay="auto"
                      aria-labelledby="input-slider"
                      aria-label="Default"
                    />
                  </Box>
                </Stack>
              ) : null}

              {/*  */}
              {props.isSaveDeal && props.from !== "savedeals" ? (
                <Box mb={'10px'}></Box>
                // <Box
                //   className="pc-user-wrap"
                //   display={"flex"}
                //   alignItems={"center"}
                //   mt={3}
                // >
                //   <Box
                //     className="user-thumbnail"
                //     display={"flex"}
                //     alignItems={"center"}
                //     justifyContent={"center"}
                //   >
                //     <Badge
                //       badgeContent={<Done />}
                //       anchorOrigin={{
                //         vertical: "bottom",
                //         horizontal: "right",
                //       }}
                //     >
                //       {/* <img src={SaveDeal} alt='' /> */}
                //       <Avatar
                //         className="user-avatar"
                //         alt="User"
                //         src={props.ownerImage ?? SaveDeal}
                //       />
                //     </Badge>
                //   </Box>
                //   <Box className="user-details" ml={1.8}>
                //     <Typography className="user-title">
                //       {props.ownerName}
                //     </Typography>
                //     <Typography className="user-subtitle">
                //       Project Proposer
                //     </Typography>
                //   </Box>
                // </Box>
              ) : (
                ""
              )}
              {props.pendingVote ? (
                <>
                  {!pendingReview ?
                    <Tooltip title="Waiting for other dic to complete the review" placement="bottom-start"
                      sx={{
                        '& .MuiTooltip-tooltip': {
                          margin: -16, // Remove the default margin if needed
                        }
                      }}>
                      <Box className="vote-btn" sx={{ marginBottom: '6px' }}>
                        {/* Applying  condition that every committee member has reviewed the project */}
                        <Button
                          disabled
                          className="btn-rounded btn-large btn-text-black btn-blue-300"
                        >
                          Vote Now
                        </Button>
                      </Box>
                    </Tooltip>
                    :
                    <Box className="vote-btn" sx={{ marginBottom: '6px' }}>
                      <Button
                        onClick={votingModal}
                        className="btn-rounded btn-large btn-text-black btn-blue-300 "
                      >
                        Vote Now
                      </Button>
                      <ModalContent
                        asset_id={props?.asset_id}
                        setOpenReviewModal={setOpenReviewModal}
                        openReviewModal={openReviewModal}
                        openVotingModal={openVotingModal}
                        setOpenVotingModal={setOpenVotingModal}
                        setReviewSuggestionModal={handleReviewSuggestionModal}
                        updateReviewData={updateReviewData}
                      />
                    </Box>
                  }
                </>
              ) : null}
              <>
                <Box className="avatar-box" style={{ overflowY: 'auto', paddingBottom: '4px' }}>
                  <AvatarGroup>
                    {props.memberDetails?.map((userData, ind) => (
                      <Avatar
                        className={
                          userData.status === 2 || userData.status === 3 ? "avatar voted" : "avatar"
                        }
                        alt="Remy Sharp"
                        key={ind}
                      >
                        {getFirstLetter(userData.memberDetails.first_name)}
                        {getFirstLetter(userData.memberDetails.last_name)}
                      </Avatar>
                    ))}
                  </AvatarGroup>
                </Box>
              </>
            </Box>
            {props.from === "overview" && (
              <Box position={"absolute"} bottom={"-40px"}>
                <Button
                  className="btn-rounded btn-blue-500 btn-text-white"
                  onClick={handleStartReview}
                >
                  Start Review
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </ThemeProvider>

      {/* Race Token Modal */}
      {openModal ? <SetRTs
        openRTs={openModal}
        handleClose={handleClose}
        userResponse={userResponse}
        assetId={props?.id}
      />
        : null}
      {/* Wallet Connect Modal */}
      <SetRTs
        openWallet={openModalFirst}
        handleModalCloseFirst={handleModalCloseFirst}
      />
    </>
  );
};
