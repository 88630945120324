// makeRemainingPayment.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import convertToDecimal from "../Common/convertToDecimal";
import convertToSixDecimal from "../Common/convertToSixDecimal";
import getCurrencyTypeAddress from "../Common/getCurrencyTypeAddress";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Call remaining payment method to complete the remaining amount on sale offer
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Number} remainingAmount sale price - deposit amount is remaining amount
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function makeRemainingPayment(address, assetData, remainingAmount, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // set active step to 2
        handleActiveStep(2);

        // let token_address = CONTRACTS.TOKEN;
        // let amount = convertToDecimal(remainingAmount)
        // if (assetData?.feeTokenType && (assetData?.feeTokenType === 'USDT' || assetData?.feeTokenType === 'USDC')) {
        //     token_address = CONTRACTS.USDT;
        //     amount = convertToSixDecimal(remainingAmount)
        // }

        let token_address = getCurrencyTypeAddress(assetData?.feeTokenType)
        let amount = getCurrencyTypeDecimal(assetData?.feeTokenType, remainingAmount)

        const { request: makeRemainingPayment } = await prepareWriteContract({
            address: CONTRACTS.ART_SALE_MARKETPLACE,
            abi: CONTRACT_ABI[CONTRACTS.ART_SALE_MARKETPLACE],
            account: address,
            chainId: 6806,
            functionName: "makeRemainingPayement",
            args: [
                assetData?.listing_id, // listing id / ipfs code id Integer
                amount, // amount
                token_address
            ]
        })

        const { hash } = await writeContract(makeRemainingPayment)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(3)
                setTimeout(() => {
                    handleSuccess(data, remainingAmount, assetData?.asset_id, assetData)
                    handleModalClose();
                    handleActiveStep(-1)
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error in paying remaining payment:', error);
        throw error; // propagate the error
    }
}

export { makeRemainingPayment };