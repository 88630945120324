import { Box, Button, Grid, Typography, TextField, MenuItem, InputAdornment, IconButton, FormControl, FormControlLabel, RadioGroup, Radio, Checkbox } from '@mui/material'
import React, { useState, useMemo } from 'react'
import { useEffect } from 'react'
import { GetApi, postApi } from '../../Api/Api'
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { Controller, useForm } from "react-hook-form";
//country code file
import CountryCodes from "../../Data/countries.json";
import NumericFormatCustomDollar from '../../Components/Common/NumericFormatCustomDollar'
import { useLocation } from 'react-router-dom'
import './StaticModal.css'
import { ArrowBack } from '@mui/icons-material';
import countryList from "react-select-country-list";
import { isSupportedCountry, isValidPhoneNumber } from "libphonenumber-js";

const countryCodeVal = CountryCodes;

export default function ProjectProposerModal({ handleSubmitParent, goBack }) {
    const [categoryData, setCategoryData] = useState();
    const [userType, setUserType] = useState(true);
    const [isSameCountry, setIsSameCountry] = useState(false);
    const location = useLocation();
    const fragment = location.hash;

    // Getting country name
    const selectedCountries = ['United States', 'United Kingdom', 'Canada', 'India', 'Singapore', 'Brazil'];
    const initialCountryList = countryList().getData();

    // arranging country according to client requirement 
    const customSort = (a, b) => {
        const indexA = selectedCountries.indexOf(a.label);
        const indexB = selectedCountries.indexOf(b.label);

        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        } else if (indexA !== -1) {
            return -1;
        } else if (indexB !== -1) {
            return 1;
        }

        return a.label.localeCompare(b.label);
    };

    const sortedCountryList = initialCountryList.sort(customSort);
    // Getting country name- end

    const {
        register,
        handleSubmit,
        control,
        getValues,
        reset,
        watch,
        clearErrors,
        formState: { errors },
    } = useForm(
        {
            defaultValues: {
                // first_name: "",
                // last_name: "",
                phoneNumber: "",
                // email: "",
                Address: "",
                suiteFloor: "",
                City: "",
                stateProvince: "",
                ZIPPostal: "",
                entity_name: "",
                valueOf_project: "",
                total_capital: "",
                country_of_asset: ''
            }
        }
    );

    const countryCodeNum = watch("country_code");
    const onSubmit = (data) => {
        let userData = "";

        if (userType) {
            const country_data = data.country_code.split('-')
            userData = {
                title: 'proposer',
                asset_type: data.asset_type,
                // first_name: data.first_name,
                // last_name: data.last_name,
                // email: data.email,
                country_code: country_data[0],
                phone_number: data.phoneNumber,
                country_of_residence: data.Country,
                // floor: data.suiteFloor,
                // city: data.City,
                // state: data.stateProvince,
                // zipcode: data.ZIPPostal,
                country_of_asset: isSameCountry ? data?.Country : data.country_of_asset,
                type: "Individual",

            }
        }
        else {
            const amount = data.total_capital;
            const cleanedAmount = amount.replace(/[$,]/g, "");
            const amountValue = data.valueOf_project;
            const cleanedAmountValue = amountValue.replace(/[$,]/g, "");
            const country_data = data.country_code.split('-')

            userData = {
                title: 'proposer',
                asset_type: data.asset_type,
                // first_name: data.first_name,
                // last_name: data.last_name,
                // email: data.email,
                country_code: country_data[0],
                phone_number: data.phoneNumber,
                country_of_residence: data.Country,
                // floor: data.suiteFloor,
                // city: data.City,
                // state: data.stateProvince,
                // zipcode: data.ZIPPostal,
                entity_name: data.entity_name,
                value_of_project: cleanedAmountValue,
                total_capital: cleanedAmount,
                country_of_asset: isSameCountry ? data?.Country : data.country_of_asset,
                type: "Entity",
            }

        }

        if (userData) {
            handleSubmitParent(userData)
        }

    }

    useEffect(() => {
        // window.scrollTo(0, 0)
        async function getCategoryData() {
            try {
                const res = await GetApi("/proposer/getCategory/");
                setCategoryData(res?.data?.data);
            } catch (error) {
                console.log(error);
            }
        }
        getCategoryData()

    }, [])

    const handleCheckboxChange = (event) => {
        // Toggle the value of isSameCountry when the checkbox is clicked
        setIsSameCountry(event.target.checked);
    };


    const [countryIso, setCountryIso] = useState('');
    /**
    *  validate phone number with libphonenumber-js library
    * @param {string} value  phone number
    * @param {string} country_iso  country code
    * @returns 
    */
    const validatePhoneNumber = (value) => {
        const res = isSupportedCountry(countryIso);
        const countryCodeNumUpdate = countryCodeNum.split('-')[0];
        return isValidPhoneNumber(`+${countryCodeNumUpdate}${value}`, countryIso);
    }

    return (
        <Box className="investor-modal-container" sx={{ ml: { md: '50px', sm: '40px', xs: '5px' }, maxHeight: '70vh', marginTop: { md: '-45px', xs: '16px' }, overflowY: 'auto' }}  >
            <Box sx={{ mx: 'auto', maxWidth: '714px' }} className='provider-details-form'>
                <Box >
                    <Typography className='title text-blue-900 font-36' color={'#fff'} component={'h2'}>Join us as a project proposer</Typography>

                    {/*Form starts from here*/}
                    <Box mt={{ xs: 2, sm: 3.5 }} component="form" autoComplete='on' onSubmit={handleSubmit(onSubmit)}>
                        <Typography className='font-18 text-blue-900' color={'#fff'} mb={2}>Please submit the information below so we can optimize your experience as a project proposer on the RACE platform.</Typography>
                        <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }} >
                            <FormControl fullWidth className='text-blue-300'>
                                <TextField
                                    fullWidth
                                    label="Asset Type"
                                    select
                                    className='roles_select'
                                    name="asset_type"
                                    {...register("asset_type", {
                                        required: "Asset type is required.",
                                    }
                                    )}
                                    error={Boolean(errors.asset_type)}
                                    helperText={errors.asset_type?.message}
                                    InputLabelProps={{
                                        style: { color: '#fff' }, // Set the label text color to white
                                    }}
                                    InputProps={{
                                        style: { color: '#fff' }, // Set the input text color to white
                                    }}
                                    onChange={() => clearErrors("asset_type")}
                                >
                                    {
                                        categoryData?.map((item, index) => (
                                            <MenuItem key={index} value={item?.id}>{item?.title}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </FormControl>
                        </Box>
                        <Box>
                            <Box display={{ lg: 'flex', md: 'block' }} alignItems={'center'} justifyContent={'space-between'} mb={2.5} mt={2}>
                                <Typography className='font-18 text-blue-900' color={'#fff'} whiteSpace={{ lg: 'nowrap', md: 'normal' }} mr={2}>Are you proposing as an individual or entity?</Typography>
                                <FormControl >
                                    <RadioGroup className='darkCheckbox text-blue-900' style={{ color: '#fff' }} row sx={{ flexWrap: 'nowrap' }} aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" defaultValue={"Individual"}>
                                        <FormControlLabel value="Individual" onChange={() => setUserType(!userType)}
                                            style={{ color: '#fff' }} control={<Radio style={{ color: '#fff' }} />} label="Individual" />
                                        <FormControlLabel value="Entity" onChange={() => setUserType(!userType)} control={<Radio style={{ color: '#fff' }} />} label="Entity" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>
                        <Typography className='font-18 text-blue-900' color={'#fff'} mb={2}>Please fill in the details below</Typography>
                        <Box className="form-group label-field" mt={2}>

                            {!userType ? <Box className="form-group label-field staticProposerModel" mt={2}>
                                <TextField sx={{ color: 'white' }}
                                    name="entity_name"
                                    label={
                                        <span style={{ color: 'white' }}>Entity Name</span>
                                    }
                                    placeholder="Enter your entity's name"
                                    {...register("entity_name", {
                                        required: "Entity name is required.",
                                        minLength: {
                                            value: 3,
                                            message: "Please enter valid entity name",
                                        },
                                    })}
                                    error={Boolean(errors.entity_name)}
                                    helperText={errors.entity_name?.message}
                                    InputProps={{
                                        style: {
                                            color: 'white',
                                        },
                                    }}
                                />
                            </Box> : ""
                            }
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3} md={4} lg={3}>
                                    <Box className="form-group label-field staticProposerModel">
                                        <Controller name="country_code" control={control}
                                            defaultValue={`${countryCodeVal[0].code}-${countryCodeVal[0].iso}`}
                                            rules={{
                                                required: "Country Code is required",
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    select
                                                    autoComplete='tel-country-code'
                                                    name="country_code"
                                                    {...field}
                                                    error={Boolean(errors.country_code)}
                                                    helperText={errors.country_code?.message}
                                                    label="Country Code"
                                                    InputLabelProps={{
                                                        style: { color: '#fff' }, // Set the label text color to white
                                                    }}
                                                    InputProps={{
                                                        style: { color: '#fff' }, // Set the input text color to white
                                                    }}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 200, // Adjust the maximum height according to your needs
                                                                },
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {countryCodeVal.map((option, index) => (
                                                        <MenuItem

                                                            key={index}
                                                            value={`${option.code}-${option.iso}`}
                                                            onClick={() => {
                                                                setCountryIso(option.iso)
                                                            }}
                                                        >
                                                            {`+${option.code} ${option.country}  `}
                                                        </MenuItem>
                                                    ))}

                                                </TextField>
                                            )} />

                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={9} md={8} lg={9}>
                                    <Box className="form-group label-field staticProposerModel">
                                        <TextField
                                            name="phoneNumber"
                                            label="Phone Number"
                                            type="text"
                                            pattern="[0-9]*"
                                            placeholder="Enter phone number"
                                            {...register("phoneNumber", {
                                                required: "Phone number is required..",
                                                validate: (value) => validatePhoneNumber(value, countryCodeNum) || "Please enter valid phone number",
                                            })}
                                            autoComplete="tel" // this line for auto-fill
                                            error={Boolean(errors.phoneNumber)}
                                            helperText={(errors?.phoneNumber?.message)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PhoneIphoneIcon className="text-blue-600" />
                                                    </InputAdornment>
                                                ),
                                                style: { color: '#fff' },
                                            }}
                                            onInput={(event) => {
                                                event.target.value = event.target.value
                                                    .replace(/\D/g, "")
                                                    .slice(0, 15);
                                            }}
                                            InputLabelProps={{
                                                style: { color: "#fff" }, // Set the label text color to white
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            {/* {!userType ? <Box className="form-group label-field staticProposerModel">
                                    <TextField
                                        name="business_address"
                                        label="Business Address"
                                        placeholder="Enter business address"
                                        {...register("Address", {
                                            required: "Business address is required.",
                                            // pattern: {
                                            //     value: /^[A-Za-z]+$/i,
                                            //     message: "Please enter valid Business Address",
                                            // },
                                            minLength: {
                                                value: 3,
                                                message: "Please enter valid Business Address",
                                            },
                                        })}
                                        error={Boolean(errors.Address)}
                                        helperText={errors.Address?.message}
                                        InputLabelProps={{
                                            style: { color: '#fff' }, // Set the label text color to white
                                        }}
                                        InputProps={{
                                            style: { color: '#fff' }, // Set the input text color to white
                                        }}
                                    />
                                </Box> : ""}

                            <Grid container spacing={{ xs: 0, sm: 1 }}>
                                <Grid item xs={12} sm={6}>
                                    {!userType ? <Box className="form-group label-field staticProposerModel">
                                        <Controller name='valueOf_project' control={control}
                                            rules={{
                                                required: "Size/value of project is required.",
                                                minLength: {
                                                    value: 3,
                                                    message: "Please enter valid value of project",
                                                },
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    autoComplete="off"
                                                    name="valueof_project"
                                                    label="Size/Value of Project"
                                                    {...field}
                                                    placeholder="Enter size value of project"
                                                    InputProps={{
                                                        inputComponent: NumericFormatCustomDollar,
                                                        style: { color: '#fff' },
                                                    }}
                                                    error={Boolean(errors.valueOf_project)}
                                                    helperText={errors.valueOf_project?.message}
                                                    InputLabelProps={{
                                                        style: { color: '#fff' }, // Set the label text color to white
                                                    }}
                                                />)
                                            } />
                                    </Box> : ""}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {!userType ? <Box className="form-group label-field staticProposerModel">                                            <Controller name='total_capital' control={control}
                                        rules={{
                                            required: "Please enter your total capital.",
                                            minLength: {
                                                value: 3,
                                                message: "Please enter valid value of capital",
                                            },

                                            validate: {
                                                priceCheck: (v) => {
                                                    let size = getValues("valueOf_project")
                                                    const cleanedSize = size.replace(/[$,]/g, "");
                                                    let cleanedV = v.replace(/[$,]/g, "");
                                                    return parseFloat(cleanedSize) > parseFloat(cleanedV) || "Raise capital should be less than size of project"
                                                }
                                            }
                                        }}
                                    />
                                </Box> : ""} */}

                            {/* country of residence */}
                            <Box mb={3} >
                                <FormControl fullWidth >
                                    <TextField
                                        className='roles_select'
                                        InputLabelProps={{
                                            // shrink: true,
                                            style: { color: '#fff' }, // Set the label text color to white
                                        }}
                                        label="Country of Residence"
                                        id="outlined-select-country-native"
                                        select
                                        name='Country'
                                        {...register("Country", {
                                            required: "Country of Residence is required.",
                                        })}
                                        error={Boolean(errors.Country)}
                                        helperText={errors.Country?.message}
                                        SelectProps={{
                                            MenuProps: {
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200, // Adjust the maximum height according to your needs
                                                        maxWidth: '50%',
                                                    },
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            style: { color: '#fff' }, // Set the input text color to white
                                        }}
                                        fullWidth
                                        onChange={() => clearErrors("Country")}
                                    >
                                        {
                                            sortedCountryList?.map((item, index) => (
                                                <MenuItem key={index} value={item?.label}>{item?.label}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </FormControl>
                            </Box>

                            <Box display='flex' alignItems={'center'} justifyContent={'space-between'} mb={2.5} mt={2}>
                                <Typography className='font-18 text-blue-900' color={'#fff'} whiteSpace={{ lg: 'nowrap', md: 'normal' }} mr={{ md: 2, xs: 0.5, sm: 1 }}>Check this box if the country of asset is the same as the Country of Residence</Typography>
                                <Checkbox
                                    style={{ color: "#fff" }}
                                    onChange={handleCheckboxChange}
                                    checked={isSameCountry}
                                />
                            </Box>

                            {/* country of asset */}
                            {isSameCountry === false && <Box mb={3} >
                                <FormControl fullWidth >
                                    <TextField
                                        className='roles_select'
                                        InputLabelProps={{
                                            // shrink: true,
                                            style: { color: '#fff' }, // Set the label text color to white
                                        }}
                                        label="Country of Asset"
                                        id="outlined-select-country-native"
                                        select
                                        name='country_of_asset'
                                        {...register("country_of_asset", {
                                            required: "Country of Asset is required.",
                                        })}
                                        error={Boolean(errors.country_of_asset)}
                                        helperText={errors.country_of_asset?.message}
                                        SelectProps={{
                                            MenuProps: {
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200, // Adjust the maximum height according to your needs
                                                        maxWidth: '50%',
                                                    },
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            style: { color: '#fff' }, // Set the input text color to white
                                        }}
                                        fullWidth
                                        onChange={() => clearErrors("country_of_asset")}
                                    >
                                        {
                                            sortedCountryList?.map((item, index) => (
                                                <MenuItem key={index} value={item?.label}>{item?.label}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </FormControl>
                            </Box>
                            }


                            {/* <Grid container spacing={{ xs: 0, sm: 1 }}>
                                <Grid item xs={12} sm={6}>
                                    <Box className="form-group label-field staticProposerModel">
                                        <TextField
                                            autoComplete="off"
                                            name="suiteFloor"
                                            label="Suite/Floor"
                                            placeholder="Enter Suite/Floor"
                                            {...register("suiteFloor", {
                                                required: "Suite floor is required.",
                                                // pattern: {
                                                //     value: /^[A-Za-z]+$/i,
                                                //     message: "Please enter valid suiteFloor",
                                                // },
                                                minLength: {
                                                    value: 1,
                                                    message: "Please enter valid suite floor",
                                                },
                                            })}
                                            error={Boolean(errors.suiteFloor)}
                                            helperText={errors.suiteFloor?.message}
                                            InputLabelProps={{
                                                style: { color: '#fff' }, // Set the label text color to white
                                            }}
                                            InputProps={{
                                                style: { color: '#fff' }, // Set the input text color to white
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box className="form-group label-field staticProposerModel">
                                        <TextField
                                            autoComplete="off"
                                            name="City"
                                            label="City"
                                            placeholder="Enter your city"
                                            {...register("City", {
                                                required: "City is required.",
                                                pattern: {
                                                    value: /^[A-Za-z ]+$/i,
                                                    message: "Please enter valid City",
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: "Please enter valid City",
                                                },
                                            })}
                                            error={Boolean(errors.City)}
                                            helperText={errors.City?.message}
                                            InputLabelProps={{
                                                style: { color: '#fff' }, // Set the label text color to white
                                            }}
                                            InputProps={{
                                                style: { color: '#fff' }, // Set the input text color to white
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid> */}
                            {/* <Grid container spacing={{ xs: 0, sm: 1 }}>
                                <Grid item xs={12} sm={6}>
                                    <Box className="form-group label-field staticProposerModel">
                                        <TextField
                                            autoComplete="off"
                                            name="stateProvince"
                                            label="State/Province"
                                            placeholder="Enter your state province"
                                            {...register("stateProvince", {
                                                required: "State/province is required.",
                                                // pattern: {
                                                //     value: /^[A-Za-z]+$/i,
                                                //     message: "Please enter valid stateProvince",
                                                // },
                                                minLength: {
                                                    value: 2,
                                                    message: "Please enter valid state province",
                                                },
                                            })}
                                            error={Boolean(errors.stateProvince)}
                                            helperText={errors.stateProvince?.message}
                                            InputLabelProps={{
                                                style: { color: '#fff' }, // Set the label text color to white
                                            }}
                                            InputProps={{
                                                style: { color: '#fff' }, // Set the input text color to white
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box className="form-group label-field staticProposerModel">
                                        <TextField
                                            autoComplete="off"
                                            name="ZIPPostal"
                                            label="ZIP/Postal"
                                            placeholder='Enter zip postal'
                                            inputProps={{
                                                maxLength: 8,
                                            }}
                                            {...register("ZIPPostal", {
                                                required: "Please enter your ZIP postal.",
                                                // pattern: {
                                                //     value: /^\d{10,15}$/,
                                                //     message: "Please enter valid ZIPPostal in digits.",
                                                // },
                                            })}
                                            error={Boolean(errors.ZIPPostal)}
                                            helperText={errors.ZIPPostal?.message}
                                            onInput={(event) => {
                                                event.target.value = event.target.value
                                                    .replace(/\D/g, "")
                                                    .slice(0, 7);
                                            }}
                                            InputLabelProps={{
                                                style: { color: '#fff' }, // Set the label text color to white
                                            }}
                                            InputProps={{
                                                style: { color: '#fff' }, // Set the input text color to white
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid> */}

                            {/* <Grid container spacing={{ xs: 0, sm: 1 }}>
                                <Grid item xs={12} sm={6}>
                                    {!userType ? <Box className="form-group label-field staticProposerModel">
                                        <Controller name='valueOf_project' control={control}
                                            rules={{
                                                required: "Size/value of project is required.",
                                                minLength: {
                                                    value: 3,
                                                    message: "Please enter valid value of project",
                                                },
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    autoComplete="off"
                                                    name="valueof_project"
                                                    label="Size/Value of Project"
                                                    {...field}
                                                    placeholder="Enter size value of project"
                                                    InputProps={{
                                                        inputComponent: NumericFormatCustomDollar,
                                                        style: { color: '#fff' },
                                                    }}
                                                    error={Boolean(errors.valueOf_project)}
                                                    helperText={errors.valueOf_project?.message}
                                                    InputLabelProps={{
                                                        style: { color: '#fff' }, // Set the label text color to white
                                                    }}
                                                />)
                                            } />
                                    </Box> : ""}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {!userType ? <Box className="form-group label-field staticProposerModel">                                            <Controller name='total_capital' control={control}
                                        rules={{
                                            required: "Please enter your total capital.",
                                            minLength: {
                                                value: 3,
                                                message: "Please enter valid value of capital",
                                            },
                                            validate: {
                                                priceCheck: (v) => {
                                                    let size = getValues("valueOf_project")
                                                    const cleanedSize = size.replace(/[$,]/g, "");
                                                    let cleanedV = v.replace(/[$,]/g, "");
                                                    return parseFloat(cleanedSize) > parseFloat(cleanedV) || "Raise capital should be less than size of project"
                                                }
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                autoComplete="off"
                                                name="total_capital"
                                                {...field}
                                                label="Total Capital to be Raised via RACE"
                                                placeholder="Enter total capital"
                                                InputProps={{
                                                    inputComponent: NumericFormatCustomDollar,
                                                    style: { color: '#fff' },
                                                }}
                                                InputLabelProps={{
                                                    style: { color: '#fff' }, // Set the label text color to white
                                                }}
                                                error={Boolean(errors.total_capital)}
                                                helperText={errors.total_capital?.message}
                                                onInput={(event) => {
                                                    event.target.value = event.target.value
                                                        .replace(/\D/g, "")
                                                }}
                                                prefix='$'
                                            />
                                        )} />
                                    </Box> : ""}
                                </Grid>
                            </Grid> */}
                            <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 6 }}>
                                <Button
                                    fullWidth
                                    className="btn-rounded btn-large btn-blue-600 btn-text-white"
                                    type="submit"
                                >
                                    Submit and join waitlist
                                </Button>
                            </Box>
                            <Button sx={{
                                color: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                                marginTop: '-25px',
                                '@media screen and (max-width: 600px)': {
                                    // Styles for screens smaller than 600px (adjust the value as needed)
                                    width: '100%', // Make the button full width on smaller screens
                                    marginTop: '0', // Adjust margin top for smaller screens
                                },
                            }} onClick={goBack}><ArrowBack style={{ marginRight: '13px' }} /> <span>Go Back</span></Button>
                        </Box>
                    </Box>
                </Box>
            </Box >
        </Box>
    )
}

