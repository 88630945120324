import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Close, Done, EditSharp, SwapVert, ViewComfy, Visibility, Search, Paid } from '@mui/icons-material';
import { Button, Box, Chip, Paper, InputBase, TableRow, TableCell, TableHead, TableContainer, Table, TableBody, Typography, MenuItem, ListItemText, ImageListItem, FormControl, InputLabel, Select, Avatar, Modal, Stack, Divider, RadioGroup, FormControlLabel, Radio, TextField, } from '@mui/material';
import { NewLogo, YellowWatchIcon } from "../../Components/Images/Images";
import { GetApi, postApi } from '../../Api/Api';
import { CAPITAL_TYPE_ID, CATEGORY_TYPE, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from '../../constants';
import { useSnackbar } from '../../Contexts/SnackbarContext';
import TransactionProgressModal from '../../Components/SmartContract/TransactionProgressModal';
import NumericFormatCustomDollar from '../../Components/Common/NumericFormatCustomDollar';
import { getRepaymentConfigDetail } from '../../Components/SmartContract/getRepaymentConfigDetail';
import { calculatePrepaymentAmount } from '../../Components/SmartContract/calculatePrepaymentAmount';
import { calculateMinimumPrepaymentAmount } from '../../Components/SmartContract/calculateMinimumPrepaymentAmount';
import './Transactions.css'
import { formatNumber } from '../../Components/Common/USFormat';
import { calculateRemainingAmount } from '../../Components/SmartContract/calculateRemainingAmount';

const sortByOptionsOffers = ['Project Name', 'Amount'];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

const StyledTableCellFirst = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
}));

/**
 * due status for the due date i.e 
 * ```
 * if the due date is in future then the due status will be future
 * if the due date is near then the due status will be near
 * if the due date is passed then the due status will be past
 * if the payment is done then the due status will be paid
 * ```
    @enum {string}
 */
const DUE_STATUS = {
    FUTURE: 'FUTURE',
    NEAR: 'NEAR',
    PAST: 'PAST',
    PAID: 'PAID'
}

/**
 * due status colors for the due date chip
 */
const DUE_STATUS_COLOR = {
    FUTURE: {
        color: '#FFFFFF',
        backgroundColor: '#05131B'
    },
    NEAR: {
        color: '#CCA829',
        backgroundColor: '#FFE8A3'
    },
    PAST: {
        color: '#FFFFFF',
        backgroundColor: '#DC2626'
    },
    PAID: {
        color: '#FFFFFF',
        backgroundColor: '#059669'
    }
}

const LoansPanel = ({ setValue, setLoading }) => {
    const [loansData, setLoansData] = useState([]);
    const [calculateEmiAmount, setCalculateEmiAmount] = useState('');
    const [loanTableData, setLoanTableData] = useState([])
    const [openMakePayment, setOpenMakePayment] = useState(false)
    const [makePaymentData, setMakePaymentData] = useState('')
    const [searchString, setSearchString] = useState('');
    const [sortBy, setSortBy] = useState('Default');
    const [categoryData, setCategoryData] = useState(); //category data
    const [filterCategoryId, setFilterCategoryId] = useState(0);

    let u = localStorage.getItem('user_data');
    let User = JSON.parse(u);

    useEffect(() => {
        /**
        * Api to get Category Type Data
        */
        const getCategoryData = async () => {
            try {
                const res = await GetApi("/proposer/getCategory/");
                setCategoryData(res?.data?.data);
            } catch (error) {
                console.log(error);
            }
        }
        getCategoryData();

        /**
        * Api to get current EMI data
        */
        const getCalculateEmiLoanTable = async () => {
            try {
                setLoading(true)
                const res = await postApi("proposer/getCalculateEmiAmount", { proposer_id: User?.id });
                if (res?.data?.data) {
                    setCalculateEmiAmount(res?.data?.data);
                }
                setLoading(false)
            } catch (error) {
                console.log(error);
                setLoading(false)
            }
        }
        setTimeout(() => {
            getCalculateEmiLoanTable();
        }, 100);
    }, [])

    // set the table when emi data is there
    useEffect(() => {
        if (calculateEmiAmount && calculateEmiAmount.length > 0) {
            let table = [];
            calculateEmiAmount.forEach((loan) => {
                // if (loan?.data) {
                let emiData = loan?.data ? loan?.data : {}

                //create a single table row i.e an loan emiData
                let tableRow = {
                    project_name: loan?.asset_title,
                    listing_id: loan?.listingId,
                    category_name: loan?.category_name,
                    category_id: loan?.category_id,
                    total_amount: emiData?.totalLoanAmt > 0 ? emiData?.totalLoanAmt : 0,
                    emi_amount: emiData?.totalAmount > 0 ? parseFloat(Math.ceil(emiData?.totalAmount * 100) / 100).toFixed(2) : 0,
                    monthly_interest: emiData?.interestAmt > 0 ? parseFloat(emiData?.interestAmt).toFixed(2) : 0,
                    monthly_principal: emiData?.principalAmt > 0 ? parseFloat(emiData?.principalAmt).toFixed(2) : 0,
                    user_id: User.id,
                    asset_id: loan?.asset_id,
                    data: loan?.response
                };
                if (tableRow.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                    tableRow.capital_type_id = CAPITAL_TYPE_ID.JUNIOR_DEBT;
                    tableRow.investment_type_id = INVESTMENT_TYPE_ID.FRACTION;
                } else if (tableRow?.category_name === CATEGORY_TYPE.WATCH || tableRow?.category_name === CATEGORY_TYPE.GOLD || tableRow?.category_name === CATEGORY_TYPE.ASSET) {
                    tableRow.investment_type_id = INVESTMENT_TYPE_ID.LOAN;
                }
                table.push(tableRow);
                // }
            });
            setLoanTableData(table);
            setLoansData(table);
        } else {
            setLoanTableData([]);
        }
    }, [calculateEmiAmount])

    /**
     * searches in the data [title]
     * @param {string} toSearch 
     * @returns {void}
     */
    const searchInData = (toSearch) => {
        setSearchString(toSearch)
        if (toSearch.length > 2) {
            if (filterCategoryId > 0) {
                setLoanTableData(loansData.filter(o => o.project_name.toLowerCase().includes(toSearch.toLowerCase()) && o.category_id === filterCategoryId))
            } else {
                setLoanTableData(loansData.filter(o => o.project_name.toLowerCase().includes(toSearch.toLowerCase())))
            }
        } else if (toSearch.length <= 2) {
            if (filterCategoryId > 0) {
                // getLoans({ user_id: User.id, category_id: filterCategoryId });
                setLoanTableData(loansData.filter(o => o.category_id === filterCategoryId))
            } else {
                // getLoans({ user_id: User.id });
                setLoanTableData(loansData)
            }
        }
    }

    // Getting filter data on category id change
    useEffect(() => {
        if (filterCategoryId > 0) {
            // getLoans({ user_id: User.id, category_id: filterCategoryId });
            setLoanTableData(loansData.filter(o => o.category_id === filterCategoryId))
        } else {
            // getLoans({ user_id: User.id });
            setLoanTableData(loansData)
        }
    }, [filterCategoryId])

    /**
     * sorting data by amount and project name
     * @param {event} event 
     */
    const handleSortByChange = (event) => {
        setSortBy(event.target.value);
        let value = event.target.value;
        if (value === 'Project Name') {
            setLoanTableData([...loanTableData].sort((a, b) => a.project_name.localeCompare(b.project_name)))
        }
        else if (value === 'Amount') {
            setLoanTableData([...loanTableData].sort((a, b) => parseFloat(a.total_amount) - parseFloat(b.total_amount)))
        }
        else {
            setLoanTableData(loanTableData)
        }
    }

    /**
     * opens the payment modal for making payment
     * @param {Object}  
     */
    const handleOpenMakePayment = (row) => {
        console.log(row);
        setMakePaymentData(row);
        setOpenMakePayment(true)
    }

    /**
     * closes the payment review modal
     */
    const handleCloseMakePayment = () => {
        setOpenMakePayment(false)
    }

    /**
    * Handle change in category filter
    * @param {Event} event 
    */
    const handleChangeCategory = (event) => {
        setFilterCategoryId(event.target.value)
    };

    return (
        <>

            <Box className="filter-panel" mt={4}>
                <Box className="fp-left">
                    <Box className="heading-left">
                        <Typography component={"h6"}>
                            Loan Received
                        </Typography>
                    </Box>
                </Box>

                <Box className="fp-right">
                    <Box className="filters-group-wrap">
                        <Box className="filters-group-wrap">
                            {/* Search bar filter */}
                            <Box className="filterSearch-form">
                                <Box component={"span"} className="search-icon">
                                    <Search />
                                </Box>
                                <InputBase
                                    // ref={searchBarRef}
                                    className="filterSearch"
                                    placeholder={"Search in loans"}
                                    value={searchString}
                                    onChange={(e) => searchInData(e.target.value)}
                                    inputProps={{ "aria-label": "search in transactions" }}
                                />
                            </Box>
                        </Box>

                        {/* Category filter */}
                        <Box className="select-group" ml={1.5}>
                            <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label">
                                    Category
                                    <ViewComfy />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    onChange={handleChangeCategory}
                                    value={filterCategoryId}
                                    label="Category"
                                    variant='outlined'
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    {
                                        categoryData?.length && categoryData?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title === "Asset" ? "Luxury Cars" : value.title}</ListItemText>
                                                    <Typography className="menu-icon">
                                                        <ImageListItem>
                                                            <img
                                                                src={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                                srcSet={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                                alt={value.category_icon?.original_name}
                                                                loading="lazy"
                                                            />
                                                        </ImageListItem>
                                                    </Typography>
                                                </MenuItem>
                                            );
                                        })

                                    }
                                </Select>
                            </FormControl>
                        </Box>


                        {/* Sorting  */}
                        <Box className="select-group" ml={1.5}>
                            <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label">
                                    Sort By
                                    <SwapVert />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    value={sortBy}
                                    onChange={handleSortByChange}
                                    label="Sort By"
                                    variant='outlined'
                                >
                                    <MenuItem value={'Default'}>Default</MenuItem>
                                    {
                                        sortByOptionsOffers?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value}>
                                                    <ListItemText>{value}</ListItemText>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>

                        </Box>
                    </Box>
                </Box>
            </Box>

            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>
                        <TableRow>
                            <StyledTableCell width={'22%'}>Project Name</StyledTableCell>
                            <StyledTableCell align="left">Asset Type</StyledTableCell>
                            <StyledTableCell align="left">Total Loan Amount</StyledTableCell>
                            <StyledTableCell align="left">Total EMI Amount</StyledTableCell>
                            <StyledTableCell align="left">Principal</StyledTableCell>
                            <StyledTableCell align="left">Interest</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loanTableData?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.name}${i}`}>
                                <StyledTableCell component="th" scope="row"><Link to={`/project-details/${row.asset_id}`} >{row?.project_name}</Link></StyledTableCell>
                                <StyledTableCell align="left">{row?.category_name}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >${formatNumber(parseFloat(row?.total_amount))}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >${formatNumber(parseFloat(row?.emi_amount))}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >${formatNumber(parseFloat(row?.monthly_principal))}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >${formatNumber(parseFloat(row?.monthly_interest))}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {
                                        row?.dueStatus === DUE_STATUS.PAID ?
                                            <Button onClick={() => { }} startIcon={<Visibility />} className='status-button'>View Transaction</Button>
                                            :
                                            <Button
                                                // disabled={true}
                                                onClick={() => handleOpenMakePayment(row)}
                                                disableFocusRipple
                                                disableRipple
                                                startIcon={<EditSharp />} className='status-button'>
                                                Make Payment
                                            </Button>
                                    }
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >

            {openMakePayment
                ? <MakePayment payEMIData={makePaymentData} openMakePayment={openMakePayment} handleCloseMakePayment={handleCloseMakePayment} setValue={setValue} />
                : null
            }

        </>
    )
}

export default LoansPanel

function DueDateChip({ duration, dueStatus }) {
    const [time, setTime] = useState(duration);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime((time) => time - 1000); // decrement time by 1 second, 1000 ms
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    /**
    * format time to days hours minutes seconds eg: 1d 2h 3m 4s
    * @param {number} duration date in milliseconds
    * @returns {string} formatted time
    */
    function formatTime(duration) {
        const seconds = Math.floor((duration / 1000) % 60);
        const minutes = Math.floor((duration / (1000 * 60)) % 60);
        const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        const days = Math.floor(duration / (1000 * 60 * 60 * 24));

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }

    return <>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            borderRadius: '7px',
            backgroundColor: DUE_STATUS_COLOR[dueStatus]?.backgroundColor,
        }}>
            {
                dueStatus === DUE_STATUS.PAID ?
                    <Chip
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: DUE_STATUS_COLOR[dueStatus]?.color,
                            backgroundColor: DUE_STATUS_COLOR[dueStatus]?.backgroundColor,
                        }}
                        icon={<Paid color='white' />}
                        label={'PAID'}
                    />
                    :
                    <Chip
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: DUE_STATUS_COLOR[dueStatus]?.color,
                            backgroundColor: DUE_STATUS_COLOR[dueStatus]?.backgroundColor,
                        }}
                        avatar={
                            <Avatar
                                sx={{
                                    width: '16px !important',
                                    height: 'auto !important',
                                    color: DUE_STATUS_COLOR[dueStatus]?.color,
                                }}
                                alt="Icon"
                                src={YellowWatchIcon}
                            />
                        }

                        label={formatTime(time)}

                    />
            }
        </Box >
    </>;
}

function MakePayment({ payEMIData, openMakePayment, handleCloseMakePayment, setValue }) {
    const style = { position: "relative", boxShadow: 24, borderRadius: "24px", width: "1043px", };
    const rowTextStyles = { color: "burlywood", fontWeight: '500', fontSize: '16px' }

    const [transactionModal, setTransactionModal] = useState({
        open: false,
        title: "Repayment",
        message: `is done successfully, For transaction see ` + <span style={{ textDecoration: 'underline' }}><Link to="/user/transactions" target="_blank" >My Transactions</Link></span> + ` page.`,
    })
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [openPayEMI, setOpenPayEMI] = useState(false);
    const [openPrepayment, setOpenPrepayment] = useState(false);
    const [openMinimumPrepayment, setOpenMinimumPrepayment] = useState(false);
    const [propData, setPropData] = useState();
    const [prepaymentData, setPrepaymentData] = useState('');
    const [miniPrepaymentData, setMiniPrepaymentData] = useState('');
    const [payableAmount, setPayableAmount] = useState(payEMIData?.emi_amount > 0 ? parseFloat(Math.ceil(payEMIData?.emi_amount * 100) / 100).toFixed(2) : 0);

    const [selectedOption, setSelectedOption] = useState('Pay EMI');
    const [selectedCurrency, setCurrencyOption] = useState('USDT');
    const [minimumPrepaymentAmt, setMinimumPrepaymentAmt] = useState(0);
    const [penaltyMinAmount, setPenaltyMinAmount] = useState(0);
    const [penaltyAmount, setPenaltyAmount] = useState(0);
    const [isMinimumPrepayment, setIsMinimumPrepayment] = useState();
    const [remainingPrincipalAmount, setRemainingPrincipalAmount] = useState(0);

    const [emiData, setEmiData] = useState([])

    /**
     * Handle change in payment type
     * @param {Event} event 
     */
    const handleOptionChange = (event) => { setSelectedOption(event.target.value) };

    /**
     * Handle change in payment type
     * @param {Event} event 
     */
    const handleCurrencyChange = (event) => { setCurrencyOption(event.target.value) };

    // Handle change in minimum Prepayment Amount
    useEffect(() => {
        if (selectedOption === "Pay Prepayment") {
            if (!prepaymentData) {
                getPrepaymentData()
            } else {
                if (prepaymentData) {
                    let penalty = 0;
                    setPayableAmount(parseFloat(Math.ceil((Number(prepaymentData?.totalAmount) / 1e18) * 100) / 100).toFixed(2))
                    prepaymentData?.penaltyAmount?.forEach((item) => {
                        penalty += Number(item) / 1e18
                    })
                    setPenaltyAmount(parseFloat(penalty).toFixed(2));
                }
            }
        } else if (selectedOption === "Pay Minimum Prepayment") {
            if (minimumPrepaymentAmt > 0) {
                getCalculateRemainingAmount(minimumPrepaymentAmt);
            } else {
                setPayableAmount(0);
                setPenaltyMinAmount(0);
            }
        } else {
            setPayableAmount(parseFloat(Math.ceil(payEMIData?.emi_amount * 100) / 100).toFixed(2))
        }
    }, [selectedOption, minimumPrepaymentAmt])

    // handle the case when minimum prepayment amount is null or zero then set payable amount to zero
    useEffect(() => {
        if (!minimumPrepaymentAmt && selectedOption === "Pay Minimum Prepayment") {
            setTimeout(() => {
                setPayableAmount(0);
                setPenaltyMinAmount(0);
            }, 1000);
        }
    }, [minimumPrepaymentAmt])

    // handle prepayment data
    useEffect(() => {
        if (prepaymentData) {
            setPayableAmount(parseFloat(Math.ceil((Number(prepaymentData?.totalAmount) / 1e18) * 100) / 100).toFixed(2))
            let penalty = 0;
            if (payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                prepaymentData?.penaltyAmount?.forEach((data) => {
                    data?.forEach((item, index) => {
                        penalty += Number(item) / 1e18
                    })
                })
            }
            else {
                prepaymentData?.penaltyAmount?.forEach((item) => {
                    penalty += Number(item) / 1e18
                })
            }
            setPenaltyAmount(parseFloat(penalty).toFixed(2));
        }
    }, [prepaymentData])

    useEffect(() => {
        if (miniPrepaymentData) {
            setPayableAmount(parseFloat(Number(miniPrepaymentData?.totalAmount) / 1e18).toFixed(2));
            let penalty = 0;
            miniPrepaymentData?.penaltyAmount?.forEach((item) => {
                penalty += Number(item) / 1e18
            })
            setPenaltyMinAmount(parseFloat(penalty).toFixed(2))
        }
    }, [miniPrepaymentData])

    // get prepayment config from blockchain
    useEffect(() => {
        async function getRepaymentConfig() {
            const config = await getRepaymentConfigDetail(payEMIData?.listing_id, payEMIData?.investment_type_id);
            setIsMinimumPrepayment(config[6])
        }
        // call the function
        getRepaymentConfig();
    }, [])

    /**
    * Api to get prepayment data
    */
    const getPrepaymentData = async () => {
        try {
            let getPrepayment = await calculatePrepaymentAmount(payEMIData);
            setPrepaymentData(getPrepayment)
        } catch (error) {
            console.log(error);
        }
    }

    /**
    * Api to get prepayment data
    */
    const getMinimumPrepayment = async (amount) => {
        try {
            let getMinimumPrepaymentData = await calculateMinimumPrepaymentAmount(payEMIData, amount);
            setMiniPrepaymentData(getMinimumPrepaymentData)
        } catch (error) {
            console.log(error);
        }
    }

    /**
    * Api to get prepayment data
    */
    const getCalculateRemainingAmount = async (amount) => {
        try {
            let remainingAmtData = await calculateRemainingAmount(payEMIData);
            if (remainingAmtData) {
                const remainingAmt = remainingAmtData?.principalAmount?.reduce((sum, item) => sum + parseFloat((Number(item) / 1e18).toFixed(2)), 0);
                if (parseFloat(amount) > parseFloat(remainingAmt)) {
                    setRemainingPrincipalAmount((parseFloat(remainingAmt)))
                } else {
                    setRemainingPrincipalAmount((parseFloat(remainingAmt)))
                    getMinimumPrepayment(minimumPrepaymentAmt)
                }
            } else {
                console.log('Data not found!');
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * handle pay button
     */
    const handleMakePayment = async () => {
        try {
            if (payEMIData?.category_name === CATEGORY_TYPE.WATCH || payEMIData?.category_name === CATEGORY_TYPE.GOLD || payEMIData?.category_name === CATEGORY_TYPE.ASSET) {
                payEMIData.investment_type_id = INVESTMENT_TYPE_ID.LOAN
            }
            if (selectedOption === "Pay EMI") {
                setOpenPayEMI(true);
                setPropData({ ...payEMIData })
            } else if (selectedOption === "Pay Prepayment") {
                setOpenPrepayment(true);
                setPropData({ ...payEMIData, prepayment_amount: payableAmount })
            } else if (selectedOption === "Pay Minimum Prepayment") {
                setOpenMinimumPrepayment(true);
                if (minimumPrepaymentAmt) {
                    setPropData({ ...payEMIData, amount: minimumPrepaymentAmt, min_prepayment_amount: payableAmount, penaltyMinAmount: penaltyMinAmount })
                }
            }
        } catch (error) {
            showSnackbar(error?.message, 'error')
        }
    }

    /**
    * Function to handle pay emi modal close
    */
    const handleClosePayEMI = () => {
        setOpenPayEMI(false);
    }

    /**
    * Function to handle prepayment modal close
    */
    const handleClosePrepayment = () => {
        setOpenPrepayment(false);
    }
    /**
    * Function to handle modal close
    */
    const handleCloseMinimumPrepayment = () => {
        setOpenMinimumPrepayment(false);
    }

    /**
    * Function call after transaction confirm
    */
    const confirmStake = async (data,assetData, amount, asset_id,user_id) => {
        handleCloseMakePayment();
        if (selectedOption === "Pay EMI") {
            setTransactionModal({ ...transactionModal, open: true, title: "Loan Installment" })
        } else if (selectedOption === "Pay Prepayment") {
            setTransactionModal({ ...transactionModal, open: true, title: "Prepayment" })
        } else if (selectedOption === "Pay Minimum Prepayment") {
            setTransactionModal({ ...transactionModal, open: true, title: "Minimum Prepayment" })
        }
        if (setValue) {
            setValue(0)
        }

        try {
            let userData = localStorage.getItem('user_data')
            userData = JSON.parse(userData);
            const gasFees = Number(data.gasUsed)
            let body = {
                amount: amount,
                sender_address: data.from,
                contract_address: data.to,
                transaction_hash: data.transactionHash,
                method_name: selectedOption === "Pay EMI" ? "payEMI" : selectedOption === "Pay Prepayment" ? "payPrepayment" : "payMinimumPrepayment",
                proposer_id: userData?.id,
                asset_id: asset_id,
                gas_fees: gasFees

            }
            await postApi("/user/createBlockchainTransaction", body);
        } catch (error) {
            console.log('error', error);
        }
        navigate('/user/transactions');
    };

    const tableHeaderStyles = {
        fontWeight: 'bold',
        // Additional styles for the header
    };

    useEffect(() => {
        if (payEMIData?.data) {
            let parseData = JSON.parse(payEMIData?.data);
            let tableData = []
            if (payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                let emiAmount = 0, penaltyAmount = 0;
                for (let i = 0; i < parseData?.monthDifference?.length; i++) {
                    let months = parseData?.monthDifference[i]
                    for (let j = 1; j < months?.length; j++) {
                        emiAmount += parseFloat(parseData?.principalAmount[i][j]) / 1e18 + parseFloat(parseData?.interestAmount[i][j]) / 1e18
                        penaltyAmount += parseFloat(parseData?.penaltyAmount[i][j]) / 1e18
                    }
                }
                tableData.push(
                    { emiAmount, penalty: penaltyAmount }
                )
            } else {
                let emiAmount = 0, penaltyAmount = 0;
                for (let i = 1; i < parseData?.monthDifference?.length; i++) {
                    emiAmount += (parseFloat(parseData?.principalAmount[i]) / 1e18 + parseFloat(parseData?.interestAmount[i]) / 1e18)
                    penaltyAmount += parseFloat(parseData?.penaltyAmount[i]) / 1e18
                }
                tableData.push(
                    { emiAmount: parseFloat(Math.ceil(emiAmount * 100) / 100).toFixed(2), penalty: parseFloat(Math.ceil(penaltyAmount * 100) / 100).toFixed(2) }
                )
            }
            setEmiData(tableData)
        }
    }, [payEMIData])

    return (
        <>
            {/* modal for pay emi, prepayment and minimum prepayment */}
            {openMakePayment ?
                <Modal
                    open={openMakePayment}
                    onClose={handleCloseMakePayment}
                    className="kyc-modal"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Box className="modal-body" sx={style}>
                        <Box className="headContent">
                            <Box className="logo">
                                <Avatar
                                    alt="Logo"
                                    src={NewLogo}
                                    variant="square"
                                    sx={{ width: 39, height: 90, objectFit: "contain" }}
                                />
                            </Box>
                        </Box>
                        <Box className="modalContentDone" >
                            <Box className="options-container" my={2} px={4}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="payment-method"
                                        name="payment-method"
                                        defaultValue="Pay EMI"
                                        row
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                    >
                                        <FormControlLabel
                                            value="Pay EMI"
                                            control={<Radio />}
                                            label="Pay EMI"
                                        />
                                        <FormControlLabel
                                            value="Pay Prepayment"
                                            control={<Radio />}
                                            label="Pay Prepayment"
                                        />
                                        {isMinimumPrepayment ?
                                            <FormControlLabel
                                                value="Pay Minimum Prepayment"
                                                control={<Radio />}
                                                label="Pay Minimum Prepayment"
                                            /> : null
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            <Box className="main-content loan " >
                                <Box className="votingContent">
                                    <Typography component="h1" mt={0} px={4} className="headText" textAlign={"center"}>{selectedOption}</Typography >
                                    {selectedOption === 'Pay Minimum Prepayment' ?
                                        <>
                                            <Box className='minimum-prepayment'>
                                                <Typography>Enter Amount: </Typography>
                                                <TextField
                                                    className='minimum-prepayment-text'
                                                    style={{ width: '200px' }}
                                                    placeholder='Enter Amount'
                                                    onChange={(e) => setMinimumPrepaymentAmt(Math.floor(parseFloat(e.target.value) || 0))}
                                                    InputProps={{
                                                        inputComponent: NumericFormatCustomDollar,
                                                        style: {
                                                            color: 'white'
                                                        }
                                                    }}
                                                    value={minimumPrepaymentAmt}
                                                    onKeyDown={(event) => {
                                                        if (event?.key === "-" || event?.key === "+" || event?.key === ".") {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    error={parseFloat(minimumPrepaymentAmt) > payEMIData?.total_amount}
                                                />
                                            </Box>
                                            <Typography className='loan-prepayment error-text'>{parseFloat(minimumPrepaymentAmt) > parseFloat(remainingPrincipalAmount) ? `Value cannot be greater than $${formatNumber(parseFloat(remainingPrincipalAmount))}` : ''}</Typography>

                                            <Divider sx={{ my: 1 }} />
                                            <Stack direction={'row'} justifyContent={'space-between'} px={15}>
                                                <Typography style={rowTextStyles}>Principal Amount</Typography>
                                                <Typography style={rowTextStyles}>${parseFloat(payableAmount - penaltyMinAmount) > 0 ? parseFloat(payableAmount - penaltyMinAmount).toFixed(2) : 0}</Typography>
                                            </Stack>
                                            <Stack direction={'row'} justifyContent={'space-between'} px={15}>
                                                <Typography style={rowTextStyles}>Penalty Amount</Typography>
                                                <Typography style={rowTextStyles}>${penaltyMinAmount}</Typography>
                                            </Stack>
                                        </>
                                        :
                                        <>
                                            {(selectedOption !== 'Pay Prepayment' && payableAmount > 0) ?
                                                <Stack spacing={2} px={15}>
                                                    {/* Table Header */}
                                                    <Stack direction="row" justifyContent="space-between">
                                                        {/* <Typography style={{ ...rowTextStyles, ...tableHeaderStyles }}>Offer</Typography> */}
                                                        <Typography style={{ ...rowTextStyles, ...tableHeaderStyles }}>EMI Amount</Typography>
                                                        {/* <Typography style={{ ...rowTextStyles, ...tableHeaderStyles }}>EMI Month</Typography> */}
                                                        <Typography style={{ ...rowTextStyles, ...tableHeaderStyles }}>Penalty</Typography>
                                                    </Stack>

                                                    {/* Table Rows */}
                                                    {emiData?.map((row, index) => (
                                                        <Stack key={index} direction="row" justifyContent="space-between">
                                                            {/* <Typography style={rowTextStyles}>{row.offer}</Typography> */}
                                                            <Typography style={rowTextStyles}>${row.emiAmount ? parseFloat(row.emiAmount).toFixed(2) : 0}</Typography>
                                                            {/* <Typography style={rowTextStyles}>{row.month}</Typography> */}
                                                            <Typography style={rowTextStyles}>${row.penalty}</Typography>
                                                        </Stack>
                                                    ))}
                                                </Stack>
                                                : null
                                            }

                                            {/* Divider */}
                                            <Divider sx={{ my: 2 }} />

                                            {selectedOption === 'Pay Prepayment' && payableAmount > 0 ?
                                                <>
                                                    <Stack direction={'row'} justifyContent={'space-between'} px={15}>
                                                        <Typography style={rowTextStyles}>Principal Amount</Typography>
                                                        <Typography style={rowTextStyles}>${parseFloat(payableAmount - penaltyAmount) > 0 ? parseFloat(payableAmount - penaltyAmount).toFixed(2) : 0}</Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} px={15}>
                                                        <Typography style={rowTextStyles}>Penalty Amount</Typography>
                                                        <Typography style={rowTextStyles}>${penaltyAmount}</Typography>
                                                    </Stack>
                                                </>
                                                :
                                                <Stack direction={'row'} justifyContent={'space-between'} px={15}>
                                                    <Typography style={rowTextStyles}>Amount (Amount + Penalty)</Typography>
                                                    <Typography style={rowTextStyles}>${payableAmount > 0 ? payableAmount : 0}</Typography>
                                                </Stack>
                                            }
                                        </>
                                    }

                                    <Box className='currency-align'>
                                        <Box className='investor-select-currency-type'>
                                            <Typography className='pay-using font-14'>
                                                Pay using
                                            </Typography>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    aria-label="payment-method"
                                                    name="payment-method"
                                                    defaultValue="USDT"
                                                    row
                                                    className='currency-options'
                                                    value={selectedCurrency}
                                                    onChange={handleCurrencyChange}
                                                >
                                                    {/* <FormControlLabel
                                                        value="RACE"
                                                        control={<Radio />}
                                                        label="RACE Token"
                                                    /> */}
                                                    <FormControlLabel
                                                        value="USDT"
                                                        control={<Radio />}
                                                        label="USDT"
                                                    />
                                                    <FormControlLabel
                                                        value="USDC"
                                                        control={<Radio />}
                                                        label="USDC"
                                                    />
                                                    <FormControlLabel
                                                        value="DAI"
                                                        control={<Radio />}
                                                        label="DAI"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                    </Box>

                                    <Box className='vote-btn-box' mt={2}>
                                        <Button className="wallet-button appr-btn" disabled={(selectedOption === 'Pay Minimum Prepayment' && parseFloat(minimumPrepaymentAmt) > payEMIData?.total_amount ? false : true) && payableAmount && parseFloat(payableAmount) > 0 ? false : true} onClick={() => handleMakePayment()}> <Box className="btn-icon" ><Done />Pay ${payableAmount}</Box></Button>

                                        <Button className="wallet-button rjct-btn" onClick={handleCloseMakePayment}><Box className="btn-icon" ><Close />Cancel</Box></Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal >
                : null
            }

            {/* Transaction successful status modal */}
            {
                transactionModal.open ?
                    <Modal
                        open={transactionModal.open}
                        onClose={() => { setTransactionModal({ ...transactionModal, open: false }) }}
                        className="kyc-modal"
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Box className="modal-body" sx={style}>
                            <Box className="headContent">
                                <Box className="logo">
                                    <Avatar
                                        alt="Logo"
                                        src={NewLogo}
                                        variant="square"
                                        sx={{ width: 39, height: 90, objectFit: "contain" }}
                                    />
                                </Box>
                                <Close className="close-btn" onClick={() => { setTransactionModal({ ...transactionModal, open: false }) }} />
                            </Box>
                            <Box className="modalContentDone" >
                                <Box className="main-content" >
                                    <Box className="DoneIcon"><Done className="doneIconSvg" /></Box>
                                    <Typography component="h1" className="headText">Payment Successful</Typography >
                                    <Typography component="p" className="sub-headText">{transactionModal?.title} {transactionModal?.message}</Typography >
                                    <Button onClick={() => { navigate('/user/transactions'); setTransactionModal({ ...transactionModal, open: false }); }} className="wallet-button">Return to Dashboard</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>
                    : null
            }

            {
                openPayEMI
                    ? <TransactionProgressModal identity={'pay-emi'} confirmStake={confirmStake} propData={{ ...propData, feeTokenType: selectedCurrency }} handleModalClose={handleClosePayEMI} openTransactionModal={openPayEMI} />
                    : null
            }

            {
                openPrepayment
                    ? <TransactionProgressModal identity={'prepayment'} confirmStake={confirmStake} propData={{ ...propData, feeTokenType: selectedCurrency }} handleModalClose={handleClosePrepayment} openTransactionModal={openPrepayment} />
                    : null
            }

            {
                openMinimumPrepayment
                    ? <TransactionProgressModal identity={'minimum-prepayment'} confirmStake={confirmStake} propData={{ ...propData, feeTokenType: selectedCurrency }} handleModalClose={handleCloseMinimumPrepayment} openTransactionModal={openMinimumPrepayment} />
                    : null
            }
        </>
    )

}