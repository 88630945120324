import { CONTRACTS } from "../../Web3/Contracts";

/**
 * Function return currency address for provided currency name
 */
export default function getCurrencyTypeAddress(name) {
    let address = ''
    switch (name) {
        case 'RACE':
            address = CONTRACTS.TOKEN
            break;
        case 'USDT':
            address = CONTRACTS.USDT
            break;
        case 'USDC':
            address = CONTRACTS.USDC
            break;
        case 'DAI':
            address = CONTRACTS.DAI
            break;
        default:
            address = CONTRACTS.USDT
            break;
    }
    return address
}