import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Box, Button, InputAdornment, IconButton, Typography, FormGroup, Checkbox, TextField, FormControl, FormControlLabel, RadioGroup, Radio, Stack, } from "@mui/material";
import { ArrowBack, Clear } from '@mui/icons-material';
import "./StaticModal.css";

export default function BrokerForm({ handleSubmitParent, goBack }) {
    const location = useLocation();
    const fragment = location.hash;
    const [listingSellingExperience, setListingSellingExperience] = useState("I have experience listing assets for sale.");
    const [assetsBrokered, setAssetsBrokered] = useState([]);
    const [error1, setError1] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [textFields, setTextFields] = useState({
        client_profile: { value: '', count: 0, checkError: false },
        additional_information: { value: '', count: 0, checkError: false },
    });

    const TypeOfAssetBrokered = ['NFT', 'Art', 'Watch Collection', 'Wine Collection', 'Automobile Collection', 'Real Estate', 'Renewable Energy', 'Private Equity, Venture Capital', 'Commodities']


    const handleCheckboxInterestChange = (item) => {
        // Handle other items being checked
        if (assetsBrokered.includes(item)) {
            // If the item was checked and is now unchecked, remove it from the selected items
            const updatedItems = assetsBrokered.filter(
                (checkedItem) => checkedItem !== item
            );
            setAssetsBrokered(updatedItems);
        } else {
            // If the item is checked, set it in the selected items
            setAssetsBrokered([...assetsBrokered, item]);
        }
    };

    useEffect(() => {
        if (fragment) {
            scrollToSection(fragment.replace("#", ""));
        }
    }, [fragment]);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const onSubmit = (data) => {
        let userData = '';
        // userData = data
        if (assetsBrokered.length > 0 && textFields.client_profile.count > 0 && textFields.additional_information.count > 0) {
            userData = {}
            userData.types_assets_brokered = assetsBrokered;
            userData.listing_selling_experience = listingSellingExperience;
            userData.broker_client_profile = textFields.client_profile.value;
            userData.additional_information = textFields.additional_information.value;

            userData.brokerage_firm_name = data?.brokerage_firm_name;
            userData.brokerage_experience = data?.brokerage_experience;
            userData.regions_of_operation = data?.countries_of_operation;
            userData.broker_license_number = data?.broker_license_number;
        }
        if (userData) {
            handleSubmitParent(userData);
        }
    };
    useEffect(() => {
        if (assetsBrokered.length === 0 && isSubmitted === true) {
            setError1(true);
        } else if (error1 && assetsBrokered.length > 0) {
            setError1(false);
        }
    }, [isSubmitted, assetsBrokered])

    /**
     * handleChange function for the text fields
     * @param {*} e
     * @param {string} fieldIdentifier - Identifier for the text field
     */
    const handleChangeText = (e, fieldIdentifier) => {
        const textData = e.target.value;

        if (textData.length <= 200) {
            setTextFields((prevFields) => ({
                ...prevFields,
                [fieldIdentifier]: { value: textData, count: textData.length, checkError: textData.length === 0 ? true : false },
            }));
        }
    };

    /**
     * Clear Text field on close icon click
     * @param {string} fieldIdentifier - Identifier for the text field
     */
    const handleClear = (fieldIdentifier) => {
        setTextFields((prevFields) => ({
            ...prevFields,
            [fieldIdentifier]: { value: '', count: 0, checkError: false },
        }));
    };

    return (
        <Box className="investor-modal-container" sx={{ ml: { md: '50px', sm: '40px', xs: '5px' }, maxHeight: '70vh', overflowY: 'auto', marginTop: { md: '-70px' } }} >
            <Box className="staticHomepages provider-details-form" sx={{ maxWidth: '730px', mx: 'auto', mt: 2, }}>
                <Box p={{ xs: 2, sm: 2 }} sx={{ maxWidth: "730px" }} mt={{ xs: 2, sm: 3.5 }}
                >
                    <Typography className="title text-blue-900 font-36" color={"#fff"} component={"h2"}>
                        Join us as a Broker
                    </Typography>
                    <Box mt={{ xs: 2, sm: 3.5 }} component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Typography className="font-18 text-white-900" mb={2}>
                            Please submit the information below so we can optimize your experience as a Broker on the RACE platform.
                        </Typography>

                        {/* Law Firm/Attorney Name */}
                        <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }}>
                            <Box
                                className="form-group label-field staticProposerModel" mt={2} sx={{ width: "fit-" }}>
                                <TextField
                                    label={'Brokerage Firm Name'}
                                    placeholder="Enter your Firm name"
                                    InputProps={{
                                        style: { color: "#fff" },
                                    }}
                                    InputLabelProps={{
                                        style: { color: "#fff" }
                                    }}
                                    name="brokerage_firm_name"
                                    {...register("brokerage_firm_name", {
                                        required: "Brokerage Firm Name is required.",
                                    })}
                                    error={Boolean(errors.brokerage_firm_name)}
                                    helperText={errors.brokerage_firm_name?.message}
                                />
                            </Box>
                        </Box>

                        {/* Assets Brokered */}
                        <Typography className="font-18 text-white-900" mt={3}>
                            Types of Assets Brokered (Select all that apply)
                        </Typography>
                        <Stack direction="row" spacing={0} flexWrap={'wrap'}>
                            {TypeOfAssetBrokered.map((item, index) => {
                                return (
                                    <FormGroup key={index}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={assetsBrokered.includes(item)}
                                                    onChange={() => handleCheckboxInterestChange(item)}
                                                    style={{ color: "#fff" }}
                                                />
                                            }
                                            label={item}
                                            style={{ color: "#fff" }}
                                        ></FormControlLabel>
                                    </FormGroup>
                                );
                            })}
                        </Stack>
                        {error1 === true && <Typography style={{ color: '#d32f2f', fontSize: '12px', margin: '3px 0px 0px 14px' }}>{'Types of Assets Brokered is required'}</Typography>}

                        {/* Years of Brokerage Experience */}
                        <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }}>
                            <Box
                                className="form-group label-field staticProposerModel"
                                mt={2}
                                sx={{ width: "fit-" }}
                            >
                                <TextField
                                    label={'Years of Brokerage Experience'}
                                    placeholder="How many years of experience do you have in brokerage?"
                                    InputProps={{
                                        style: { color: "#fff" },
                                    }}
                                    InputLabelProps={{
                                        style: { color: "#fff" }
                                    }}
                                    name="brokerage_experience"
                                    {...register("brokerage_experience", {
                                        required: "Years of Brokerage Experience is required.",
                                        pattern: {
                                            value: /^[0-9]*\.?[0-9]*$/, // Allows only numeric values with an optional dot for decimals
                                            message: "Please enter a valid number.",
                                        },
                                    })}
                                    error={Boolean(errors.brokerage_experience)}
                                    helperText={errors.brokerage_experience?.message}
                                />
                            </Box>
                        </Box>

                        {/* Regions or Countries of Operation */}
                        <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }}>
                            <Box
                                className="form-group label-field staticProposerModel"
                                mt={2}
                                sx={{ width: "fit-" }}
                            >
                                <TextField
                                    label={'Regions or Countries of Operation'}
                                    placeholder="List the regions or countries where you operate as a broker."
                                    InputProps={{
                                        style: { color: "#fff" },
                                    }}
                                    InputLabelProps={{
                                        style: { color: "#fff" }
                                    }}
                                    name="countries_of_operation"
                                    {...register("countries_of_operation", {
                                        required: "Regions or Countries of Operation is required.",
                                    })}
                                    error={Boolean(errors.countries_of_operation)}
                                    helperText={errors.countries_of_operation?.message}
                                />
                            </Box>
                        </Box>

                        {/* Broker License Number */}
                        <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }}>
                            <Box
                                className="form-group label-field staticProposerModel"
                                mt={2}
                                sx={{ width: "fit-" }}
                            >
                                <TextField
                                    label={'Broker License Number'}
                                    placeholder="If applicable, please provide your broker license number and issuing country or state."
                                    InputProps={{
                                        style: { color: "#fff" },
                                    }}
                                    InputLabelProps={{
                                        style: { color: "#fff" }
                                    }}
                                    name="broker_license_number"
                                    {...register("broker_license_number", {
                                        required: "Broker License Number is required.",
                                    })}
                                    error={Boolean(errors.broker_license_number)}
                                    helperText={errors.broker_license_number?.message}
                                />
                            </Box>
                        </Box>

                        {/*  Typical Client Profile */}
                        <Typography className="font-18 text-white-900" >
                            Typical Client Profile
                        </Typography>
                        <Box
                            className="form-group label-field staticProposerModel"
                            mt={2}
                            sx={{ width: "fit-" }}
                        >
                            <TextField
                                multiline
                                value={textFields.client_profile.value}
                                onChange={(e) => handleChangeText(e, 'client_profile')}
                                minRows={2}
                                placeholder="Describe the typical profile of clients you represent, e.g., institutional, high-net-worth individuals, retail, etc"
                                InputProps={{
                                    style: { color: "#fff" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClear('client_profile')}
                                                size="small"
                                            >
                                                <Clear className='multileClearText-btn' />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                            />
                            {(textFields.client_profile.checkError === true || (isSubmitted && textFields.client_profile.count === 0)) ? <Typography style={{ color: '#d32f2f', fontSize: '12px' }} padding={'4px 16px'}>{'Typical Client Profile is required'}</Typography> :
                                <Typography padding={'4px 16px'}>{textFields.client_profile.count}/200</Typography>
                            }

                        </Box>

                        {/* Listing and Selling Experience */}
                        <Box>
                            <Box>
                                <Typography
                                    className="font-18 text-white-900"
                                    whiteSpace={{ lg: "nowrap", md: "normal" }}
                                    mr={2}
                                    mt={3}
                                >
                                    Listing and Selling Experience
                                </Typography>
                                <FormControl sx={{ marginTop: "2px" }} >
                                    <RadioGroup
                                        className="darkCheckbox text-white-900"
                                        row
                                        sx={{ flexWrap: "nowrap", display: '-webkit-box' }}
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        defaultValue={"I have experience listing assets for sale."}
                                    >
                                        <FormControlLabel
                                            value="I have experience listing assets for sale."
                                            onChange={() => setListingSellingExperience("I have experience listing assets for sale.")}
                                            control={<Radio style={{ color: "#fff" }} />}
                                            label="I have experience listing assets for sale."
                                        />
                                        <FormControlLabel
                                            value="I have experience selling listed assets to buyers."
                                            onChange={() => setListingSellingExperience("I have experience selling listed assets to buyers.")}
                                            control={<Radio style={{ color: "#fff" }} />}
                                            label="I have experience selling listed assets to buyers."
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>

                        {/* Additional Information */}
                        <Typography className="font-18 text-white-900" mt={3}>
                            Additional Information
                        </Typography>
                        <Box
                            className="form-group label-field staticProposerModel"
                            mt={2}
                            sx={{ width: "fit-" }}
                        >
                            <TextField
                                placeholder="Any other information you wish to provide or questions about the RACE platform."
                                multiline
                                minRows={2}
                                value={textFields.additional_information.value}
                                onChange={(e) => handleChangeText(e, 'additional_information')}
                                InputProps={{
                                    style: { color: "#fff" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClear('additional_information')}
                                                size="small"
                                            >
                                                <Clear className='multileClearText-btn' />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                            />
                            {(textFields.additional_information.checkError === true || (isSubmitted && textFields.additional_information.count === 0)) ? <Typography style={{ color: '#d32f2f', fontSize: '12px' }} padding={'4px 16px'}>{'Additional Information is required'}</Typography> :
                                <Typography padding={'4px 16px'}>{textFields.additional_information.count}/200</Typography>
                            }
                        </Box>

                        {/* submit button */}
                        <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 6 }}>
                            <Button
                                fullWidth
                                className="btn-rounded btn-large btn-blue-600 btn-text-white"
                                type="submit"
                                onClick={() => setIsSubmitted(true)}
                            >
                                Submit and join waitlist
                            </Button>
                        </Box>
                        <Button sx={{
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            marginTop: '-25px',
                            '@media screen and (max-width: 600px)': {
                                // Styles for screens smaller than 600px (adjust the value as needed)
                                width: '100%', // Make the button full width on smaller screens
                                marginTop: '0', // Adjust margin top for smaller screens
                            },
                        }} onClick={goBack}><ArrowBack style={{ marginRight: '13px' }} /> <span>Go Back</span></Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
