//  nftBalanceOf.js
import { readContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} contract_address asset data
 * @returns user wallet 
 */
async function tokenUri(id) {
    try {
        // Now check for
        const uri = await readContract({
            address: CONTRACTS.BLUPRINT,
            abi: CONTRACT_ABI[CONTRACTS.BLUPRINT],
            functionName: "tokenURI",
            chainId: 6806,
            args: [
                id
            ]
        })
        return uri;
    } catch (error) {
        console.error('Error fetching tokens by index:', error);
        throw error; // propagate the error
    }
}

export { tokenUri };