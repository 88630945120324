// cancelOffer.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";

/**
 * Call cancelOffer method for investor to cancel the offer
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function cancelOffer(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        const offerDetails = assetData?.offerDetails;
        const listingId = offerDetails?.listing_id;
        const blockchainOfferId = offerDetails?.blockchainOfferId;

        let cancelOfferArgs = getCancelOfferArgs(offerDetails, address, listingId, blockchainOfferId);

        // Cancel offer by investor
        const { request: cancelOffer } = await prepareWriteContract(cancelOfferArgs);
        const { hash } = await writeContract(cancelOffer);

        if (hash) {
            const data = await waitForTransaction({ hash });
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(2);
                setTimeout(() => {
                    handleSuccess(assetData);
                    handleModalClose();
                    handleActiveStep(-1);
                }, 1000);
            } else {
                handleModalClose();
                handleActiveStep(-1);
                showSnackbar("Transaction Failed", 'error');
            }
        }
    } catch (error) {
        console.error('Error calling cancel offer:', error);
        throw error; // propagate the error
    }
}

function getCancelOfferArgs(offerDetails, address, listingId, blockchainOfferId) {
    const baseArgs = {
        account: address,
        chainId: 6806,
        functionName: "cancelOffer",
        args: [listingId, blockchainOfferId],
    };

    switch (offerDetails?.investment_type_id) {
        case INVESTMENT_TYPE_ID.LOAN:
            return { ...baseArgs, address: CONTRACTS.WATCH_DEBT_OFFER_ADDRESS, abi: CONTRACT_ABI[CONTRACTS.WATCH_DEBT_OFFER_ADDRESS] };
        case INVESTMENT_TYPE_ID.SALE:
            return { ...baseArgs, address: CONTRACTS.ART_SALE_MARKETPLACE, abi: CONTRACT_ABI[CONTRACTS.ART_SALE_MARKETPLACE] };
        case (offerDetails?.asset_data?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && 
              (offerDetails?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT || 
               offerDetails?.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT)):
            return {
                ...baseArgs,
                address: CONTRACTS.SPV_DEBT_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.SPV_DEBT_OFFER_ADDRESS],
                args: [listingId, offerDetails.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1, blockchainOfferId]
            };
        case (offerDetails?.asset_data?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE &&
              offerDetails?.capital_type_id === CAPITAL_TYPE_ID.EQUITY):
            return { ...baseArgs, address: CONTRACTS.SPV_EQUITY_OFFER_ADDRESS, abi: CONTRACT_ABI[CONTRACTS.SPV_EQUITY_OFFER_ADDRESS] };
        default:
            throw new Error("Invalid investment type or category");
    }
}

export { cancelOffer };
