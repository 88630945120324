// Registration.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { SP_CONTRACTS, SP_CONTRACT_ABI } from "../ServiceProviderWeb3/ServiceProviderContract"

/**
 * Call the accept method for client
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function AcceptOriginalOffer(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {

        let contract_address = SP_CONTRACTS.SERVICE_MARKETPLACE_PROVIDER;
        let functionName = "acceptOriginalOffer";
console.log("acceptOriginalOffer--- ", assetData);
        const { request: acceptoffer } = await prepareWriteContract({
            address: contract_address,
            abi: SP_CONTRACT_ABI[contract_address],
            account: address,
            chainId: 6806,
            functionName: functionName,
            args: [
                assetData?.blockchain_id // blockchain contract id
            ]
        })

        const { hash } = await writeContract(acceptoffer)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(2)
                setTimeout(() => {
                    handleSuccess(assetData, data)
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error in register user:', error);
        throw error; // propagate the error
    }
}

export { AcceptOriginalOffer };