//  nftBalanceOf.js
import { readContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} address user wallet address
 * @returns user wallet 
 */
async function nftBalanceOf(address) {
    try {
        // Now check for
        const balanceOf = await readContract({
            address: CONTRACTS.BLUPRINT,
            abi: CONTRACT_ABI[CONTRACTS['BLUPRINT']],
            account: address,
            functionName: "balanceOf",
            chainId: 6806,
            args: [
                address
            ]
        })
        return Number(balanceOf);
    } catch (error) {
        console.error('Error fetching allowance:', error);
        throw error; // propagate the error
    }
}

export { nftBalanceOf };