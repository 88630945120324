import React, { useState, useEffect, useRef } from "react";
import Carousel from "react-multi-carousel";
import { Box, Typography, Grid } from "@mui/material";
import { PendingActions } from "@mui/icons-material";
import { Link, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import PaymentsIcon from "@mui/icons-material/Payments";
import { GetApiParam } from "../../Api/Api";
import PendingIcon from "@mui/icons-material/Pending";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import DoneIcon from "@mui/icons-material/Done";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Charts from "../../Pages/Portfolio/Charts";
import { DicCard } from "../DICCard/DicCard";
import SetRTs from "../Modals/Components/SetStackDic";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";
import "./Overview.css";
import { useDispatch } from "react-redux";
// import{reduxLogout} from "../../features/auth/authSlice";
import { logout as reduxLogout } from "../../features/auth/authSlice";
import CommonBackdropLoader from "../../Components/Common/CommonBackdropLoader";

const Overview = () => {
  const sidebarOpen = useOutletContext();
  const location = useLocation()
  const navigate = useNavigate();
  const [assetData, setAssetData] = useState("");
  const [serverTime, setServerTime] = useState();
  const [serverDate, setServerDate] = useState();
  const [serverClockTime, setServerClock] = useState();
  const [committeeHeaderInfo, setCommitteeHeaderInfo] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalFirst, setOpenModalFirst] = useState(false);
  const localData = JSON.parse(localStorage.getItem("user_data_dic"));
  let userName = localData?.first_name;
  let memberId = localData?.member_id;
  const { showSnackbar } = useSnackbar();
  const serverTimeInterval = useRef(null)
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);


  // user is not login or user_data_dic not exist on local storage, then navigate user to main home page
  useEffect(() => {
    if (!localData) {
      navigate("/");
    }
  }, [localData, location]);

  // set modal open true , only when it is coming from dic login page 
  // useEffect(() => {
  //   // Check if location.state is available and if it's coming from the /login page
  //   if (location.state && location.state.from === '/login') {
  //     // setOpenModal(true);
  //     // replacing state so, modal not open on page reload
  //     // navigate(location.pathname, { replace: true });
  //   }
  // }, [location]);

  // Effect to handle popstate event for browser back button
  useEffect(() => {
    const handlePopstate = () => {
      // Always navigate to overview page when popstate event occurs
      navigate("/dic/overview")
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    async function getProjectData() {
      try {
        setLoading(true)
        if (localData.id) {
          const payloadData = {
            // "committee_member_id": localData.id
            id: localData.id,
            // only_available: 1
          };

          const res = await GetApiParam("/committee/getProject", payloadData);
          if (res) {
            setAssetData(res.data?.data);
            setServerTime(res.data?.server_time);
            setServerDate(res.data?.server_date);
            setTimer(res.data);
          }
          setLoading(false)

        } else {
          showSnackbar("Member id is missing.", "error");
          setLoading(false)

        }
      } catch (error) {
        console.log(error);
        setLoading(false)
      }
    }

    async function getCommiteeHeaderInfo() {
      try {
        const localData = JSON.parse(localStorage.getItem("user_data_dic"));
        const payloadData = {
          committee_member_id: localData.id ? localData.id : 5,
        };
        const res = await GetApiParam("/committee/headerInfo", payloadData);
        if (res.data.code === 200) {
          setCommitteeHeaderInfo(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    const clear = setTimeout(() => {
      getCommiteeHeaderInfo();
      getProjectData();
    }, 100);
    return () => clearTimeout(clear)
  }, [sidebarOpen]);

  // functionality for Timer
  function setTimer(res) {
    // initialize server clock
    if (res?.data?.length > 0) {
      const serverClockTime = new Date(res?.server_date);
      var timestamp = res?.server_time.split(":");
      serverClockTime.setHours(timestamp[0]);
      serverClockTime.setMinutes(timestamp[1]);
      serverClockTime.setSeconds(timestamp[2]);
      setServerClock(serverClockTime);
    }
  }

  useEffect(() => {
    serverTimeInterval.current = setInterval(() => {
      if (serverClockTime) {
        serverClockTime.setSeconds(serverClockTime.getSeconds() + 1);
        setServerClock(serverClockTime);
        const event = new CustomEvent("clocktime", {
          detail: serverClockTime,
          bubbles: true,
        });
        document.dispatchEvent(event);
      }
    }, 1000);

  }, [serverClockTime])

  // Product slider
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktopXXl: {
      breakpoint: { max: 3000, min: 2000 },
      items: 5,
    },
    desktopXl: {
      breakpoint: { max: 2000, min: 1400 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1400, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1023, min: 601 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  // handle user response 'confirm'
  const userResponse = (data) => {
    setOpenModal(false);
    setOpenModalFirst(true);
  };

  // handle model close
  const handleClose = () => {
    setOpenModal(false);
  };

  /**
   * Onclick function for closing Modal First
   */
  const handleModalCloseFirst = () => {
    setOpenModalFirst(false);
  };

  const style = {
    position: "relative",
    boxShadow: 24,
    borderRadius: "24px",
    width: "932px",
    '@media (max-width: 600px)': {
      width: '350px', // Set a different height for small screens
    },
    // Additional media queries for other screen sizes if needed
    '@media (min-width: 601px) and (max-width: 900px)': {
      // Custom styles for devices between 601px and 900px width
      width: '580px'
    },

    '@media (min-width: 901px) and (max-width: 1200px)': {
      // Custom styles for devices between 901px and 1200px width
      width: '750px'
    },

    // Specify the maximum width for large screens
    '@media (min-width: 1201px)': {
      maxWidth: "1043px",
      maxHeight: "687px",
    },
  };

  /**
   * Onclick function for Connecting Wallet
   */
  const handleConnectWallet = () => {
    // connect wallet here
    setOpenModalFirst(false);
  };

  /**
   * Onclick function for Buying Token
   */
  const handleRaceTocken = () => {
    setOpenModalFirst(false);
  };

  return (
    <>
      <Box className="myPorject-page overview-page" pt={4}>
        <Box className="myPorject-drafts-section" pb={4}>
          <Box>
            <Box className="memberId-text">
              Member ID: {memberId ? memberId : ""}
            </Box>
            <Box className="name-text">Hi {userName ? userName : ""} 👋🏼</Box>
          </Box>
          <Box className="top-bar">
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Box className="headIconBox">
                  <Box className="icon-box">
                    <AssignmentIndIcon className="head-icon" />
                  </Box>
                  <Box className="right-box">
                    <Box className="head-text">Assigned</Box>
                    <Box className="head-number">
                      {committeeHeaderInfo?.assigned ?? ""}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Box className="headIconBox">
                  <Box className="icon-box">
                    <PendingActions className="head-icon" />
                  </Box>
                  <Box className="right-box">
                    <Box className="head-text">Not Reviewed</Box>
                    <Box className="head-number">
                      {committeeHeaderInfo?.notReviewed ?? ""}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Box className="headIconBox">
                  <Box className="icon-box">
                    <PendingIcon className="head-icon" />
                  </Box>
                  <Box className="right-box">
                    <Box className="head-text">Reviewed</Box>
                    <Box className="head-number">
                      {committeeHeaderInfo?.Reviewed ?? ""}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Box className="headIconBox">
                  <Box className="icon-box">
                    <HowToVoteIcon className="head-icon" />
                  </Box>
                  <Box className="right-box">
                    <Box className="head-text">Pending Vote</Box>
                    <Box className="head-number">
                      {committeeHeaderInfo?.pendingVote ?? ""}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Box className="headIconBox">
                  <Box className="icon-box">
                    <DoneIcon className="head-icon" />
                  </Box>
                  <Box className="right-box">
                    <Box className="head-text">Completed</Box>
                    <Box className="head-number">
                      {committeeHeaderInfo?.Completed ?? ""}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Box className="headIconBox">
                  <Box className="icon-box">
                    <AttachMoneyIcon className="head-icon" />
                  </Box>
                  <Box className="right-box">
                    <Box className="head-text">Funded</Box>
                    <Box className="head-number">
                      {committeeHeaderInfo?.funded ?? ""}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={{ xs: 1, md: 2, lg: 3 }}>
            <Grid item xs={6} sm={4} md={3.5}>
              <Box className="pfIf-card">
                <Box className="pfIf-card-icon-box">
                  <Box className="pfIf-card-icon">
                    <PaymentsIcon />
                  </Box>
                </Box>
                <Box className="pfIf-card-title">0 RACE Token</Box>
                <Box className="pfIf-card-subtitle">
                  Total Reward Received
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3.5}>

              <Box className="pfIf-card">
                <Box className="pfIf-card-icon-box">
                  <Box className="pfIf-card-icon">
                    <PaymentsIcon />
                  </Box>
                </Box>
                <Box className="pfIf-card-title">00,000 USDC</Box>
                <Box className="pfIf-card-subtitle">Funded</Box>
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={4} md={5}>
              <Box
                sx={{
                  background: '#032744',
                  display: "block",
                  boxSizing: "border-box",
                  width: "100%",
                  height: "100%", // Set the height to 100%
                  maxHeight: '165px', // Set a maximum height if needed
                  borderRadius: '12px',
                  margin: "auto", // Center the chart within the Grid item
                  overflow: "hidden", // Adjust overflow as needed
                }}
              >
                <Charts />
              </Box>
            </Grid> */}
          </Grid >

          <Box className="mid-text-projectList" mb={3} mt={3}>
            <Box className="name-text">Open Projects</Box>
            <Box mt={1} pr={{ xs: 0, sm: 10, md: 10 }} display={{ xs: assetData?.length > 1 ? 'block' : 'none', sm: assetData?.length > 2 ? 'block' : 'none', md: assetData?.length > 3 ? 'block' : 'none' }}>
              <Link to="/dic/overviewprojectlist" className="seeAll">
                {" "}
                See All
              </Link>
            </Box>
          </Box>

          {
            assetData?.length > 0 ? (
              <Box className="draftsProducts-wrap">
                <Carousel
                  className="productThumb-slider space-24"
                  swipeable={false}
                  draggable={false}
                  responsive={responsive}
                >
                  {(() => {
                    const projects = [];
                    for (let i = 0; i < assetData?.length; i++) {
                      const galleryimg = [];
                      // Inserting gellery Images Into Slider
                      assetData[i]?.asset_gallery.map((val, ind) => {
                        if (val.title === "gellary") {
                          galleryimg.push(
                            `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`,
                          );
                        }
                      });
                      projects.push(
                        <Box className="slide-item">
                          <DicCard
                            id={assetData[i]?.id}
                            category_id={assetData[i]?.category_id}
                            title={assetData[i]?.asset_title}
                            subTitle={assetData[i]?.asset_address}
                            address={assetData[i]?.owner_information?.owner_address}
                            investType={
                              assetData[i]?.investment_type?.investment_name
                            }
                            isLoan={assetData[i]?.assets_category?.title}
                            isDraft={false}
                            // isClock={true}
                            isPending={
                              assetData[i]?.status === "Pending" ||
                                assetData[i]?.status === "inreview"
                                ? true
                                : false
                            }
                            isDelete={
                              assetData[i]?.status === "Rejected" ? true : false
                            }
                            isSaved={false}
                            imgSrc={galleryimg}
                            isSaveDeal={false}
                            link={`/dic/project-details-view/${assetData[i]?.id}`}
                            // investment_expiry_time={
                            //   assetData[i]?.investment_expiry_time
                            // }
                            server_date={serverDate}
                            server_time={serverTime}
                            projectProgress={assetData[i]?.project_process}
                            memberDetails={assetData[i]?.dicMemberData}
                            status={assetData[i].status}
                            from={"overview"}
                            assetDataDetail={assetData[i]}
                          />
                        </Box>
                      );
                    }
                    return projects;
                  })()}
                </Carousel>
                {/* </Slider> */}
              </Box>
            ) : (
              <Box>
                <Typography className="title" component={"h4"} >
                  No Projects Found
                </Typography>
              </Box>
            )
          }
        </Box >
        <SetRTs
          open={openModal}
          handleClose={handleClose}
          userResponse={userResponse}
        />
        {/* Wallet Connect Modal */}
        <SetRTs
          openWallet={openModalFirst}
          handleModalCloseFirst={handleModalCloseFirst}
        />
      </Box >

      <CommonBackdropLoader loading={loading} />
    </>
  );
};
export default Overview;
