import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Box, Typography, TextField, MenuItem, FormGroup, FormControl, FormControlLabel, RadioGroup, Radio, Stack, Button, Checkbox } from '@mui/material'
import { GetApi } from '../../Api/Api'
import { LoadingButton } from '@mui/lab'
import { ArrowBack } from '@mui/icons-material';
import countryList from "react-select-country-list";
import "./StaticModal.css"

export default function InvestorModal({ handleSubmitParent, goBack, ipKey }) {

  const [categoryData, setCategoryData] = useState();
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState('Risk Taker');
  const [checkedItems, setCheckedItems] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [preferredAmount, setPreferredAmount] = useState([])


  // Getting country name
  const selectedCountries = ['United States', 'United Kingdom', 'Canada', 'India', 'Singapore', 'Brazil'];
  const initialCountryList = countryList().getData();

  // arranging country according to client requirement 
  const customSort = (a, b) => {
    const indexA = selectedCountries.indexOf(a.label);
    const indexB = selectedCountries.indexOf(b.label);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1;
    }

    return a.label.localeCompare(b.label);
  };

  const sortedCountryList = initialCountryList.sort(customSort);
  // Getting country name- end

  //handle checkbox logic
  const handleCheckboxChange = (item) => {
    setCheckedItems((prevSelected) => {
      if (prevSelected.includes(item)) {
        return prevSelected.filter((type) => type !== item);
      } else {
        return [...prevSelected, item];
      }
    });

  };

  const isFormValid = checkedItems.length > 0;

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm(
    {
      defaultValues: {}
    }
  );
  const onSubmit = (data) => {
    if (checkedItems.length === 0) {
      setIsSubmitted(true)
    } else {
      let userData = "";
      userData = {
        investment_amount: data.asset_type,
        type_of_investor: userType,
        area_of_interest: checkedItems,
        country_of_residence: data.country_of_residence
      }

      if (userData) {
        handleSubmitParent(userData)
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    // Api to get categoryData
    async function getCategoryData() {
      try {
        const res = await GetApi("/proposer/getCategory/");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }

    async function getPreferredAmount() {
      try {
        const res = await GetApi("/user/getPrefferedInvestmentAmount");
        setPreferredAmount(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    getCategoryData();
    getPreferredAmount();
  }, [])

  return (
    <Box className="investor-modal-container" sx={{ ml: { md: '50px', sm: '40px', xs: '5px' }, overflowY: 'auto', marginTop: { md: '-60px' } }} >
      <Box sx={{ mx: 'auto', maxWidth: '730px' }} className='provider-details-form'>
        <Typography className='title text-blue-900 font-36' color={'#fff'} component={'h2'}>Join us as an investor</Typography>

        {/*Form starts from here*/}
        <Box mt={{ xs: 2, sm: 3.5 }} component="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete='on'
        >
          <Typography className='font-18 text-blue-900' color={'#fff'} mb={2}>Please submit the information below so we can optimize your experience as an investor on the RACE platform.</Typography>

          <Box mb={3} >
            <FormControl fullWidth >
              <TextField
                className='roles_select'
                InputLabelProps={{
                  // shrink: true,
                  style: { color: '#fff' }, // Set the label text color to white
                }}
                label="Country of Residence"
                id="outlined-select-country-native"
                select
                name='country_of_residence'
                {...register("country_of_residence", {
                  required: "Country of Residence is required.",
                })}
                error={Boolean(errors.country_of_residence)}
                helperText={errors.country_of_residence?.message}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Adjust the maximum height according to your needs
                        maxWidth: '50%',
                      },
                    },
                  },
                }}
                InputProps={{
                  style: { color: '#fff' }, // Set the input text color to white
                }}
                fullWidth
                onChange={() => clearErrors("country_of_residence")}
              >
                {
                  sortedCountryList?.map((item, index) => (
                    <MenuItem key={index} value={item?.label}>{item?.label}</MenuItem>
                  ))
                }
              </TextField>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }}>
            <Typography className='font-18 text-blue-900' color={'#fff'} whiteSpace={{ lg: 'nowrap', md: 'normal' }} mr={2}>Areas of interest<br></br></Typography>
            <Stack direction="row" spacing={{ xs: 0, md: 1 }} flexWrap={'wrap'} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>

              {
                categoryData?.map((item, index) => {
                  return <FormGroup className='font-14' key={index} sx={{ ml: { xs: 0 } }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedItems.includes(item?.title

                          )}
                          onChange={() => handleCheckboxChange(item?.title

                          )}
                          style={{ color: '#fff' }}
                        />
                      }
                      label={item.title} style={{ color: '#fff' }}></FormControlLabel>
                  </FormGroup>
                })

              }
            </Stack>
            {isSubmitted && !isFormValid && (
              <Typography variant='body2' style={{ color: '#d32f2f' }}>Area of interest is required</Typography>
            )}

          </Box>

          <Box display={{ md: 'block' }} alignItems={'center'} justifyContent={'space-between'} mb={2.5} mt={2}>
            <Typography className='font-18 text-blue-900' color={'#fff'} whiteSpace={{ lg: 'nowrap', md: 'normal' }} mr={2}>Type of Investor</Typography>
            <FormControl className='font-14' sx={{ display: 'flex', color: '#fff' }}>
              <RadioGroup className='darkCheckbox text-blue-900' sx={{ flexWrap: 'nowrap', color: '#fff', flexDirection: { xs: 'column', md: 'row' } }} aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" defaultValue={"Risk Taker"}>
                <FormControlLabel value="Risk Taker"
                  onChange={() => setUserType('Risk Taker')}
                  control={<Radio style={{ color: '#fff' }} />} label="Risk Taker" />
                <FormControlLabel value="Risk Neutral"
                  onChange={() => setUserType('Risk Neutral')}
                  control={<Radio style={{ color: '#fff' }} />} label="Risk Neutral" />
                <FormControlLabel value="Risk Averse"
                  onChange={() => setUserType('Risk Averse')}
                  control={<Radio style={{ color: '#fff' }} />} label="Risk Averse" />
              </RadioGroup>
            </FormControl>
          </Box>

          <Typography className='font-18 text-blue-900' color={'#fff'} mb={2}>Preferred Investment Amount (Optional)</Typography>
          <Box className="form-group label-field" mt={3.5}>
            {/* <FormControl fullWidth> */}
            {/* <InputLabel id="demo-simple-select-label">Asset Type</InputLabel> */}
            <TextField
              // className='dropdownIcon'
              className='roles_select'
              fullWidth
              label="Preferred Investment Amount"
              // placeholder='Select one from the dropdown below'
              select
              name="asset_type"
              {...register("asset_type", {
                required: "Preferred investment amount is required.",
              }
              )}
              error={Boolean(errors.asset_type)}
              helperText={errors.asset_type?.message}
              InputLabelProps={{
                style: { color: '#fff' }, // Set the label text color to white
              }}
              InputProps={{
                style: { color: '#fff' }, // Set the input text color to white

              }}
              onChange={() => clearErrors("asset_type")}
            >
              {
                preferredAmount?.map((item, index) => (
                  <MenuItem key={item.id} value={item.amount_range}>{item.amount_range}</MenuItem>
                ))
              }
            </TextField>
            {/* </FormControl> */}
          </Box>
          <Box className="btn-wrap">
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              fullWidth
              className="btn-rounded btn-large btn-blue-600 btn-text-white"
              type="submit"
              onClick={() => setIsSubmitted(true)}
            >
              Submit and join waitlist
            </LoadingButton>
          </Box>
          <Button sx={{ color: '#fff', display: 'flex', alignItem: 'center', marginRight: 'auto', marginLeft: 'auto', marginTop: '10px' }} onClick={goBack}><ArrowBack style={{ marginRight: '13px' }} /> <span>Go Back</span></Button>
        </Box>
      </Box>
    </Box >
  )
}


