import { format } from 'date-fns';

export const formatDate = (date, toFormat) => {
    if (toFormat === 'MMM d, yyyy') {
        return format(date, 'MMM d, yyyy');
    }
    if (toFormat === 'MMMM dd, yy') {
        return format(date, 'MMMM dd, yy');
    }
    if (toFormat === 'MMM dd, h:mm a') {
        const formatedDate = format(date, 'MMM dd');
        const time = new Date(date).toLocaleTimeString('en-US', {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        })
        return `${formatedDate}, ${time}`;
    }
    if (toFormat === 'MM/dd/yy - hh:mm a') {
        return format(date, 'MM/dd/yy - hh:mm a');
    }
    if (toFormat === 'dd/MM/yy - hh:mm a') {
        return format(date, 'dd/MM/yy - hh:mm a');
    }
    if (toFormat === 'dd/MM/yy - hh:mm:ss') {
        return format(date, 'dd/MM/yy - hh:mm:ss');
    }
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return `${formattedDate}`;
};

export const formatDateTime = (date) => {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedTime = `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)} ${ampm}`;

    return `${formattedDate} - ${formattedTime}`;
};

export const ccyFormat = (num) => {
    return `${Number(num).toFixed(2)}`;
}

export const checkNumberValue = (value) => {
    if (value === '' || isNaN(value)) return 0;
    return Number(value);
}

export async function fetchAndDownloadFile(url, filename) {
    try {
        const response = await fetch(`${process.env.REACT_APP_IMAGE_URL}${url}`);
        if (!response.ok) throw new Error('Network response was not ok');
        const blob = await response.blob();
        const downloadUrl = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(downloadUrl); // Clean up the object URL
    } catch (error) {
        console.error('Fetch error:', error);
    }
}

export const calculateNewSinceCreation = async (createdAt) => {
    const creationDate = new Date(createdAt);
    const currentDate = new Date();
    const millisecondsPerDay = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = currentDate - creationDate;

    // Check if the difference is within 24 hours
    if (differenceMs < millisecondsPerDay) {
        return 'new';
    } else {
        return 'old';
    }
};

export const calculateDaysSinceCreation = (createdAt) => {
    const creationDate = new Date(createdAt);
    const currentDate = new Date();
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const millisecondsPerHour = 1000 * 60 * 60;
    const millisecondsPerMinute = 1000 * 60;
    const millisecondsPerSecond = 1000;

    // Calculate the difference in milliseconds
    const differenceMs = currentDate - creationDate;

    // Check if the difference is within 24 hours
    if (differenceMs < millisecondsPerDay) {
        const hours = Math.floor(differenceMs / millisecondsPerHour);
        const minutes = Math.floor((differenceMs % millisecondsPerHour) / millisecondsPerMinute);
        const seconds = Math.floor((differenceMs % millisecondsPerMinute) / millisecondsPerSecond);
        if (hours === 0) {
            return `${minutes} minutes`;
        }
        return `${hours} hours, ${minutes} minutes`;
    } else {
        // Calculate days
        const daysSinceCreation = Math.floor(differenceMs / millisecondsPerDay);
        return `${daysSinceCreation} days `;
    }
};

export const formatDateRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const startFormatted = format(startDate, 'MMM d, yyyy');
    const endFormatted = format(endDate, 'MMM d, yyyy');

    return `${startFormatted} - ${endFormatted}`;
};

export const validAmountCheck = (amount) => {
    const regex = /^[0-9]+(\.[0-9]+)?$/;
    if (amount) {
        if (regex.test(amount)) {
            if (Number(amount) === 0) {
                return `Please enter valid amount`;
            }
        } else {
            return `Please enter valid amount`;
        }
    } else {
        return `Amount is required`;
    }
}

export function formatAmountInUS(number) {
    return number.toLocaleString('en-US');
}