import { useState, useEffect, useRef } from 'react';
import axiosInstance from '../Api/AuthServices';
import { useDispatch } from 'react-redux';
import { login as reduxLogin, logout as reduxLogout } from '../features/auth/authSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notifyError = (message) =>
    toast(<p style={{ fontSize: 16 }}>{message}</p>, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "colored",
        type: "error"
    });

const useAuth = () => {
    const dispatch = useDispatch();
    const [accessToken, setAccessToken] = useState(null);
    const interceptorsAttached = useRef(false);
    const notificationShown = useRef(false);
    const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""




    useEffect(() => {

        if (interceptorsAttached.current) return;

        const requestInterceptor = axiosInstance.interceptors.request.use(
            (config) => {
                const localToken = localStorage.getItem('token');
                if (localToken) {
                    config.headers.Authorization = `Bearer ${localToken}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        const responseInterceptor = axiosInstance.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;
                if ((error?.response?.status === 401 || error?.response?.status === 403) && !originalRequest._retry) {
                    originalRequest._retry = true;

                    if (!notificationShown.current) {
                        const message = error?.response?.status === 401
                            ? 'Your session has expired, please login again.'
                            : 'Your account has been deactivated.';
                        notifyError(message);
                        notificationShown.current = true;

                        setTimeout(() => {
                            dispatch(reduxLogout());
                            window.location.href = '/';
                            notificationShown.current = false;  // Reset the notification state
                        }, 5000);
                    }
                }
                return Promise.reject(error);
            }
        );

        interceptorsAttached.current = true;

        return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor);
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };

    }, [dispatch]);

    const login = async (loginData) => {
        dispatch(reduxLogin(loginData));
        setAccessToken(loginData?.token);
        if (loginData?.refreshToken) localStorage.setItem('refreshToken', loginData?.refreshToken);
        if (loginData?.remember_token) localStorage.setItem('rememberMeToken', loginData?.remember_token);
    };

    const logout = () => {
        setAccessToken(null);
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('rememberMeToken');
        dispatch(reduxLogout());
    };

    return {
        login,
        logout,
    };
};

export default useAuth;
