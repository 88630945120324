// repaymentConfigDetail.js

import { readContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} address user wallet address
 * @returns user wallet allowance
 */
async function repaymentConfigDetail(listing_id) {
    try {

        // Now check for allowance Tokens
        const repaymentConfig = await readContract({
            address: CONTRACTS.WATCH_REPAYMENT_ADDRESS,
            abi: CONTRACT_ABI[CONTRACTS.WATCH_REPAYMENT_ADDRESS],
            // account: address,
            functionName: "repaymentConfigDetail",
            chainId: 6806,
            args: [
                listing_id  // asset listing id
            ]
        })
        return repaymentConfig;
    } catch (error) {
        console.error('Error fetching repaymentConfig:', error);
        throw error; // propagate the error
    }
}

export { repaymentConfigDetail };
