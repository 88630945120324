import { Box, Typography, Chip, Stack, useMediaQuery, Grid, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CAPITAL_TYPE_ID, CAPITAL_TYPE_STATUS, CATEGORY_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../../constants";
import { GetApiParam } from "../../../Api/Api";
import { formatNumber } from "../../../Components/Common/USFormat";

const CapitalInfoInvestor = ({ assetId, assetData, capitalInfo, selectedCapitalId, detailsFor }) => {
  const isXs = useMediaQuery('(max-width:600px)');

  const [fundedAmount, setFundedAmount] = useState(0);
  const [totalFundedAmount, setTotalFundedAmount] = useState(0);
  const [fundedAmountClass1, setFundedAmountClass1] = useState(0);
  const [fundedAmountClass2, setFundedAmountClass2] = useState(0);

  useEffect(() => {
    const getFundedAmount = async () => {
      const resp = await GetApiParam('/invest/getAssetFundedAmount', { "asset_id": assetId })
      let fundedData = {}
      if (resp?.data?.code === 200) {
        fundedData = resp?.data?.data;
      }
      if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
        let amount = fundedData[`capital_${selectedCapitalId}_totalAmount`] ? fundedData[`capital_${selectedCapitalId}_totalAmount`] : 0;
        setFundedAmount(parseFloat(amount))
      } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
        // if (Object.keys(fundedData).length > 1) {
        //   let amount1 = fundedData[`capital_${1}_totalAmount`] ? fundedData[`capital_${1}_totalAmount`] : 0;
        //   let amount2 = fundedData[`capital_${2}_totalAmount`] ? fundedData[`capital_${2}_totalAmount`] : 0;
        //   setTotalFundedAmount(parseFloat(amount1) + parseFloat(amount2))
        // } else {
        //   let amount = fundedData[`capital_${selectedCapitalId}_totalAmount`] ? fundedData[`capital_${selectedCapitalId}_totalAmount`] : 0;
        //   setTotalFundedAmount(parseFloat(amount))
        // }
        if (assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
          setFundedAmount(parseFloat(resp?.data?.totalAmount ? (resp?.data?.totalAmount > assetData?.fractionalize_total_price ? assetData?.fractionalize_total_price : resp?.data?.totalAmount) : 0))
          setTotalFundedAmount(parseFloat(resp?.data?.totalAmount ? (resp?.data?.totalAmount > assetData?.fractionalize_total_price ? assetData?.fractionalize_total_price : resp?.data?.totalAmount) : 0))
        } else {
          setTotalFundedAmount(parseFloat(resp?.data?.totalAmount ? resp?.data?.totalAmount : 0))
          if (assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS) {
            if (capitalInfo?.length > 1) {
              let amountClass1 = fundedData[`capital_1_totalAmount`] ? fundedData[`capital_1_totalAmount`] : 0;
              setFundedAmountClass1(parseFloat(amountClass1))
              let amountClass2 = fundedData[`capital_2_totalAmount`] ? fundedData[`capital_2_totalAmount`] : 0;
              setFundedAmountClass2(parseFloat(amountClass2))
            } else {
              let amountClass1 = fundedData[`capital_1_totalAmount`] ? fundedData[`capital_1_totalAmount`] : 0;
              setFundedAmountClass1(parseFloat(amountClass1))
            }
          } else {
            let amount = fundedData[`capital_${selectedCapitalId}_totalAmount`] ? fundedData[`capital_${selectedCapitalId}_totalAmount`] : 0;
            setFundedAmount(parseFloat(amount))
          }
        }
      }
    }
    if (assetId) {
      getFundedAmount()
    }
  }, [selectedCapitalId])

  return (
    <Box className="pd-sidebar-priceOverview" >
      {assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ?
        <>
          {/* {detailsFor === 'proposer' ? <Divider /> : null} */}
          <Typography className='rc-detail-subtitle' variant='subtitle2' gutterBottom pt={1} >Fund Info</Typography>
          {/* <Box mt={2.5} justifyContent={"space-between"} display={"flex"} alignItems={"center"}>
            {
              <Box>
                <Chip className="pc-chip-capitalinfo" label={"Funded"} />
              </Box>
            }
          </Box> */}

          <Grid container spacing={2} justifyContent="space-between" mb={2} mt={1}>
            {detailsFor === 'proposer' ? null :
              <Grid item xs={12} sm={6} md={6}>
                <Typography className="title font-12 text-blueGray-400 text-capitalize" mt={0.3}>
                  Total Raise
                </Typography>
                <Typography className={"price font-24"}>
                  ${formatNumber(assetData?.fractionalize_total_price ? assetData?.fractionalize_total_price : 0)}
                </Typography>
              </Grid>
            }

            <Grid item xs={12} sm={6} md={6}>
              <Typography className="title font-12 text-blueGray-400 text-capitalize" mt={0.3}>
                Total Funded Amount
              </Typography>
              <Typography className={"price font-24"}>
                ${formatNumber(fundedAmount ? fundedAmount : 0)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Typography className="title font-12 text-blueGray-400 text-capitalize" mt={0.3}>
                Remaining Amount
              </Typography>
              <Typography className="price font-24">
                ${formatNumber((assetData?.fractionalize_total_price - totalFundedAmount) > 0 ? (assetData?.fractionalize_total_price - totalFundedAmount) : 0)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Typography className="title font-12 text-blueGray-400 text-capitalize" mt={0.3}>
                Min. Investment
              </Typography>
              <Typography className="price font-24">
                ${formatNumber(assetData?.fractionalize_minimum_investment ? assetData?.fractionalize_minimum_investment : 0)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Typography className="title font-12 text-blueGray-400 text-capitalize" mt={0.3}>
                Carried Interest
              </Typography>
              <Typography className="price font-24">
                {formatNumber(parseFloat(assetData?.carried_interest))}%
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Typography className="title font-12 text-blueGray-400 text-capitalize" mt={0.3}>
                Management Fees
              </Typography>
              <Typography className="price font-24">
                {formatNumber(parseFloat(assetData?.management_fees))}%
              </Typography>
            </Grid>

            {detailsFor === 'proposer' ? null :
              <Grid item xs={12} sm={6} md={6}>
                <Typography className="title font-12 text-blueGray-400 text-capitalize" mt={0.3}>
                  Initial Project Term
                </Typography>
                <Typography className="price font-24">
                  {parseInt(assetData?.fractionalize_duration_of_project)}
                  {parseInt(assetData?.fractionalize_duration_of_project) < 2 ? " Year" : " Years"}
                </Typography>
              </Grid>
            }

            <Grid item xs={12} sm={6} md={6}>
              <Typography className="title font-12 text-blueGray-400 text-capitalize" mt={0.3}>
                Fund Raising Period
              </Typography>
              <Typography className="price font-24">
                {parseInt(assetData?.investment_duration)}
                {parseInt(assetData?.investment_duration) < 2 ? " Day" : " Days"}
              </Typography>
            </Grid>
          </Grid>
          {detailsFor === 'proposer' ? <Divider /> : null}
        </>
        :
        <>
          {/* {detailsFor === 'proposer' ? <Divider /> : null} */}
          <Typography className='rc-detail-subtitle' variant='subtitle2' gutterBottom pt={1}>Capital Info</Typography>
          {
            assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS ?
              <Grid item xs={12} sm={6} md={6} mt={2}>
                <Typography
                  className="title font-12 text-blueGray-400 text-capitalize"
                  mt={0.3}
                >
                  Total Funded Amount
                </Typography>
                <Typography className={"price font-24"}>
                  ${formatNumber(totalFundedAmount ? totalFundedAmount : 0)}
                </Typography>
              </Grid> : null
          }
          {capitalInfo?.map((val, ind) => {
            // only show the capital info of the selected capital in fraction page
            if (val?.class) {
              return (val.class == parseInt(selectedCapitalId) || detailsFor === 'proposer') && (
                <>
                  <Box
                    mt={2.5}
                    justifyContent={"space-between"}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Box className='font-18'>{`Class ${val?.class == 1 ? 'A' : 'B'}`}</Box>
                    {
                      val?.status === CAPITAL_TYPE_STATUS.FUNDED && (
                        <Box>
                          <Chip className="pc-chip-capitalinfo" label={"Funded"} />
                        </Box>
                      )
                    }
                  </Box>
                  <>
                    <Grid container spacing={2} justifyContent="space-between" mb={2} mt={1}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          className="title font-12 text-blueGray-400 text-capitalize"
                          mt={0.3}
                        >
                          Total Raise
                        </Typography>
                        <Typography className={"price font-24"}>
                          ${formatNumber(val?.total_raise ? val?.total_raise : 0)}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          className="title font-12 text-blueGray-400 text-capitalize"
                          mt={0.3}
                        >
                          Total Funded Amount
                        </Typography>
                        <Typography className={"price font-24"}>
                          ${formatNumber(val.class == 1 ? fundedAmountClass1 : fundedAmountClass2)}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          className="title font-12 text-blueGray-400 text-capitalize"
                          mt={0.3}
                        >
                          Remaining Amount
                        </Typography>
                        <Typography className="price font-24">
                          ${formatNumber((assetData?.fractionalize_total_price - totalFundedAmount) ? (assetData?.fractionalize_total_price - totalFundedAmount) : 0)}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          className="title font-12 text-blueGray-400 text-capitalize"
                          mt={0.3}
                        >
                          Min. Investment
                        </Typography>
                        <Typography className="price font-24">
                          ${formatNumber(val?.minimum_investment ? val?.minimum_investment : 0)}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          className="title font-12 text-blueGray-400 text-capitalize"
                          mt={0.3}
                        >
                          Hurdle Rate
                        </Typography>
                        <Typography className="price font-24">
                          {val?.rate_of_return}%
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          className="title font-12 text-blueGray-400 text-capitalize"
                          mt={0.3}
                        >
                          LP Percentage
                        </Typography>
                        <Typography className="price font-24">
                          {parseFloat(val?.lp_percentage ? val?.lp_percentage : 0)}%
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                  {detailsFor === 'proposer' ? <Divider /> : null}
                </>
              );
            } else {
              return val.capital_type?.id === parseInt(selectedCapitalId) && (
                <>
                  <Box
                    mt={2.5}
                    justifyContent={"space-between"}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Box className='font-18'>{val.capital_type.capital_name}</Box>
                    {
                      val?.status === CAPITAL_TYPE_STATUS.FUNDED && (
                        <Box>
                          <Chip className="pc-chip-capitalinfo" label={"Funded"} />
                        </Box>
                      )
                    }
                  </Box>
                  <>
                    <Grid container spacing={2} justifyContent="space-between" mb={2} mt={1}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography className={"price font-24"}>
                          ${formatNumber(val?.total_raise ? val?.total_raise : 0)}
                        </Typography>
                        <Typography
                          className="title font-12 text-blueGray-400 text-capitalize"
                          mt={0.3}
                        >
                          Total Raise
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Typography className={"price font-24"}>
                          ${formatNumber(fundedAmount ? fundedAmount : 0)}
                        </Typography>
                        <Typography
                          className="title font-12 text-blueGray-400 text-capitalize"
                          mt={0.3}
                        >
                          Total Funded Amount
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Typography className="price font-24">
                          ${formatNumber((val?.total_raise - fundedAmount) ? (val?.total_raise - fundedAmount) : 0)}
                        </Typography>
                        <Typography
                          className="title font-12 text-blueGray-400 text-capitalize"
                          mt={0.3}
                        >
                          Remaining Amount
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Typography className="price font-24">
                          ${formatNumber(val?.minimum_investment ? val?.minimum_investment : 0)}
                        </Typography>
                        <Typography
                          className="title font-12 text-blueGray-400 text-capitalize"
                          mt={0.3}
                        >
                          Min. Investment
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <Typography className="price font-24">
                          {val?.rate_of_return}%
                        </Typography>
                        <Typography
                          className="title font-12 text-blueGray-400 text-capitalize"
                          mt={0.3}
                        >
                          Interest Rate
                        </Typography>
                      </Grid>

                      {val?.capital_type.id === CAPITAL_TYPE_ID.EQUITY && (
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography className="price font-24">
                            {val?.equity}%
                          </Typography>
                          <Typography
                            className="title font-12 text-blueGray-400 text-capitalize"
                            mt={0.3}
                          >
                            Equity
                          </Typography>
                        </Grid>
                      )}
                      {val?.capital_type.id !== CAPITAL_TYPE_ID.EQUITY && (
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography className="price font-24">
                            {parseInt(val?.capital_type.id === CAPITAL_TYPE_ID.SENIOR_DEBT ? val?.senior_duration : val?.junior_duration)} Years
                          </Typography>
                          <Typography
                            className="title font-12 text-blueGray-400 text-capitalize"
                            mt={0.3}
                          >
                            Duration of Debt
                          </Typography>
                        </Grid>
                      )}
                      {val?.capital_type.id !== CAPITAL_TYPE_ID.EQUITY && (
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography className="price font-24">
                            {parseInt(val?.capital_type.id === CAPITAL_TYPE_ID.SENIOR_DEBT ? val?.iop_senior : val?.iop_junior)} Months
                          </Typography>
                          <Typography
                            className="title font-12 text-blueGray-400 text-capitalize"
                            mt={0.3}
                          >
                            Interest-only Period
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </>
                </>
              );
            }
          })}
        </>
      }
    </Box>
  );
};

export default CapitalInfoInvestor;
