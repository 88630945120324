// approveMintAsset.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";

/**
 * Approve the tokens for spender contract
 * @param {String} address user wallet address
 * @param {Number} tokenId minted asset token id
 * @returns transaction data
 */
async function approveMintAsset(address, tokenId, escrowAddress) {
    try {
        // Now approve the asset 
        const { request: approveMintAsset } = await prepareWriteContract({
            address: CONTRACTS.NFT_ADDRESS,
            abi: CONTRACT_ABI[CONTRACTS.NFT_ADDRESS],
            account: address,
            chainId: 6806,
            functionName: "approve",
            args: [
                escrowAddress, // to (address)
                Number(tokenId) // tokenId
            ]
        })

        const { hash } = await writeContract(approveMintAsset);

        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            return data
        }
    } catch (error) {
        console.error('Error calling approve minted asset:', error);
        throw error; // propagate the error
    }
}

export { approveMintAsset };