// createTokenizeAsset.js
import { postApi, updateApi } from "../../Api/Api";
import { tokenizeNft } from "./tokenizeNft";

/**
 * Pay stake amount by DIC member to start review process
 * @param {String} address user wallet address
 * @param {Number} proposalId asset's blockchain id
 * @param {Number} stakeAmt amount to stake
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function createTokenizeAsset(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        let mintedAssetId = await tokenizeNft(address, assetData);
        if (mintedAssetId) {
            await updateApi('proposer/tokenizeAssetUpdate', { asset_id: assetData?.asset_id, mint_token_id: mintedAssetId, project_status: 7, user_id: assetData?.user_id });
            showSnackbar("Transaction Successful", 'success');
            handleActiveStep(3)
            setTimeout(() => {
                handleSuccess(mintedAssetId, assetData?.asset_id)
                handleModalClose();
                handleActiveStep(-1)
                return { mintedAssetId }
            }, 1000)
        } else {
            handleModalClose();
            handleActiveStep(-1)
            showSnackbar("Transaction Failed", 'error')
            return
        }

    } catch (error) {
        console.error('Error calling dic stake method:', error);
        throw error; // propagate the error
    }
}

export { createTokenizeAsset };