// checkAllowance.js

import { readContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import getCurrencyTypeAddress from "../Common/getCurrencyTypeAddress";

/**
 * Fetch user wallet allowance for given spender
 * @param {String} address user wallet address
 * @param {String} spenderAddress spender contract address
 * @returns user wallet allowance
 */
async function checkAllowance(address, spenderAddress, feeTokenType) {
    try {

        let token_address = getCurrencyTypeAddress(feeTokenType);

        // Now check for allowance Tokens
        const allowance = await readContract({
            address: token_address,
            abi: CONTRACT_ABI[token_address],
            account: address,
            functionName: "allowance",
            chainId: 6806,
            watch: true,
            args: [
                address,  // user wallet address
                spenderAddress  // spender contract address
            ]
        })
        return Number(allowance);
    } catch (error) {
        console.error('Error fetching allowance:', error);
        throw error; // propagate the error
    }
}

export { checkAllowance };
