import { Web3 } from "web3";
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from '../Contexts/SnackbarContext';
import { walletConnectSmallIcon, metamaskSmallIcon } from "../Components/Images/Images";
import { Avatar, Box, CircularProgress, Dialog, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useConnect, useAccount, useDisconnect, useNetwork } from 'wagmi'
import { KeyboardArrowRight, Wallet } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ConfirmWalletModal from '../Components/Common/ConfirmWalletModal';
import { postApi } from '../Api/Api';
import { race } from "./race-chain"

const WalletIcons = {
    'metamask': <Avatar alt="Metamask Icon" src={metamaskSmallIcon} sx={{ width: '24px', height: '24px' }} />,
    'walletconnect': <Avatar alt="wallet connect" src={walletConnectSmallIcon} sx={{ width: '24px', height: '24px' }} />,
}

function ConnectWalletInvestor({ open, handleCloseDialog, handleConnectedSuccess, userRegister, userLoginPortal, investorRegister }) {

    const { address, isConnected } = useAccount();
    const { connect, connectors, error, isLoading, pendingConnector } = useConnect();
    const { showSnackbar } = useSnackbar();
    const { disconnect } = useDisconnect();
    const [isAlreadyConnect, setIsAlreadyConnect] = useState(false);
    const [isCorrectChain, setIsCorrectChain] = useState(false);
    const [switchDone, setIsSwitchDone] = useState(false);
    const { chain } = useNetwork()

    //confirmation modal state
    const [openModal, setOpenModal] = useState(false);
    const localData = JSON.parse(localStorage.getItem("user_data"));
    const RACE_CHAIN_ID = race?.id; // Replace with your actual chain ID

    useEffect(() => { //runs when any wallet connection gives error
        if (error) {
            showSnackbar(error?.message, 'error');
            handleCloseDialog();
            disconnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])


    const handleConnect = async (connector) => {
        console.log('isConnected', isConnected);
        if (!isConnected) {
            connect({ connector })
        } else {
            setIsAlreadyConnect(!isAlreadyConnect)
            console.log('Already connected')
        }
    }

    useEffect(() => {
        if (userRegister) {
            setIsCorrectChain(true);
        } else {
            if (chain?.id != race?.id) {
                console.log('calling chain switch')
                handleSwitchChain();
                if (chain && race) {
                    showSnackbar('Network Mismatch', 'error')
                }
            } else {
                if (chain?.id == race?.id) {
                    console.log('switched')
                    setIsCorrectChain(true);
                }
            }
        }
    }, [chain]);

    /**
     * Handle a switch chain
     */
    const handleSwitchChain = async () => {
        // if (typeof window.ethereum === 'undefined') {
        //     showSnackbar('MetaMask is not installed. Please install MetaMask to proceed.', 'error');
        //     return;
        // }

        try {
            // Directly request MetaMask to switch to the custom chain
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{
                    chainId: Web3.utils.toHex(
                        parseInt(RACE_CHAIN_ID))
                }],
            });
            // handleCloseDialog()
            setIsSwitchDone(true);
        } catch (switchError) {
            if (switchError.code === 4902) {
                // This error code indicates that the chain has not been added to MetaMask
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: Web3.utils.toHex(
                                parseInt(RACE_CHAIN_ID)),
                            rpcUrls: race?.rpcUrls?.public?.http,
                            chainName: race?.name,
                            nativeCurrency: {
                                name: 'ETH',
                                symbol: 'ETH',
                                decimals: 18,
                            },
                            blockExplorerUrls: [race?.blockExplorers?.etherscan?.url],
                        }],
                    });
                    setIsSwitchDone(true);
                } catch (addError) {
                    console.error('Failed to add the chain:', addError);
                    handleCloseDialog()
                }
            } else {
                console.error('Failed to switch network:', switchError);
                handleCloseDialog()
            }
        }
    };

    useEffect(() => { //runs when wallet is connected
        if (open && isConnected && address && isCorrectChain) {
            handleCloseDialog();
            async function checkWallet() {
                let body = {};
                if (userRegister) {
                    body.wallet_address = address?.toLowerCase();
                } else {
                    body.user_id = localData?.id;
                }
                const checkRes = await postApi(`/user/checkWallet`, body);
                let walletData = checkRes?.data?.data;
                if (userRegister) {
                    if (checkRes?.data.code === 200) {
                        if (investorRegister) {
                            handleConnectedSuccess(address);
                        } else {
                            showSnackbar('This wallet is already associated with other account.', 'error');
                            disconnect()
                        }
                    } else {
                        handleConnectedSuccess(address);
                        showSnackbar('Wallet connected successfully', 'success');
                    }
                } else {
                    if (walletData?.wallet_address?.toLowerCase() == address?.toLowerCase()) {
                        handleConnectedSuccess(address);
                        showSnackbar('Wallet connected successfully', 'success');
                    } else if (walletData?.wallet_address) {
                        showSnackbar('This wallet is not associated with the account', 'error');
                        disconnect()
                    } else if (walletData && !walletData?.wallet_address) {
                        setOpenModal(true);
                    } else if (!walletData) {
                        showSnackbar('There was an error', 'error');
                    }
                }
            }
            if (userLoginPortal) {
                handleConnectedSuccess(address);
            } else {
                checkWallet();
            }
        }
    }, [isConnected, isAlreadyConnect, switchDone, isCorrectChain]);

    const handleUpdateSuccess = (acc) => {
        handleConnectedSuccess(acc, true);
    }

    return (
        <>
            <Dialog onClose={handleCloseDialog} open={open} className="connectWallet-popup investor" sx={{ zIndex: '1350' }}>
                <Box className="cwp-header" textAlign={'center'} py={2.5} px={3}>
                    <Typography component={'h4'}>Connect Your Wallet</Typography>
                    <Typography>Connecting your wallet is like “logging in” to Web3. Select your wallet from the options to get started.</Typography>
                    <Link style={{ textDecoration: 'underline' }} to='https://ethereum.org/en/wallets/find-wallet/#main-content' target="_blank" rel="noopener noreferrer">Learn more about wallets.</Link>
                </Box>
                <Box className="cwp-content" py={2}>
                    <List className="connectWallet-list">
                        {connectors.map((connector) => (
                            <React.Fragment key={connector.id}>
                                {
                                    connector.id === "injected" ? null :
                                        connector.ready &&
                                        <ListItem disableGutters
                                            secondaryAction={
                                                <>
                                                    {
                                                        isLoading &&
                                                        connector.id === pendingConnector?.id &&
                                                        <CircularProgress />
                                                    }
                                                </>
                                            }
                                        >
                                            <ListItemButton onClick={() => handleConnect(connector)}>
                                                <ListItemAvatar>
                                                    {WalletIcons[connector.name.toLowerCase()] ??
                                                        <Wallet sx={{ width: '32px', height: '32px', color: '#ffffff' }} />
                                                    }
                                                </ListItemAvatar>
                                                <ListItemText primary={connector.ready ? connector.name : 'loading'} />
                                                <KeyboardArrowRight style={{ color: '#fff' }} />
                                            </ListItemButton>
                                        </ListItem>
                                }
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            </Dialog>

            <ConfirmWalletModal openModal={openModal} setOpenModal={setOpenModal} onUpdateSuccess={handleUpdateSuccess} />
        </>
    )
}

ConnectWalletInvestor.protoTypes = {
    open: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    handleConnectedSuccess: PropTypes.func.isRequired,
    /**
     * if true then ask for confirmation to associate with the current logged in account after connecting wallet
     */
    askConfirmation: PropTypes.bool,
}

// ConnectWalletInvestor.defaultProps = {
//     askConfirmation: false,
// }

export default ConnectWalletInvestor
