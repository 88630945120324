import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Chip, Button, TableCell, Box, Paper, InputBase, TableRow, TableHead, TableContainer, Table, TableBody, Typography, MenuItem, ListItemText, ImageListItem, FormControl, InputLabel, Select } from '@mui/material';
import { ViewComfy, SwapVert, Search, PriorityHigh } from '@mui/icons-material';
import { TRANSACTION_STATUS, TRANSACTION_TYPE_DISPLAY } from '../../constants.js'
import { GetApi, GetApiParam } from '../../Api/Api.js';
import './Transactions.css'
import { formatNumber } from '../../Components/Common/USFormat.js';

/**
 * @param {string} date  Date String
 * @returns  {string} Formatted Date String in DD/MM/YYYY format
 */
const formatDate = (date) => {
    let d = new Date(date);
    let formattedDate = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
    return formattedDate;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
}));


const sortByOptionsOffers = ['Project Name', 'Amount'];


const TRANSACTION_STATUS_TEXT = {
    [TRANSACTION_STATUS.PENDING]: <Chip sx={{ marginTop: 2, marginBottom: 2, color: '#13202D', bgcolor: '#DAC88A', borderRadius: '8px' }} label='Payment Pending' />,
    [TRANSACTION_STATUS.SUCCESS]: <Chip sx={{ marginTop: 2, marginBottom: 2, color: '#13202D', bgcolor: '#34D399', borderRadius: '8px' }} label='Payment Processed' />,
    [TRANSACTION_STATUS.FAILED]: <Chip sx={{ marginTop: 2, marginBottom: 2, color: '#13202D', bgcolor: '#EF4444', borderRadius: '8px' }} label='Payment Failed' />,
}
const MyTransactions = ({ setLoading }) => {

    const [transactionData, setTransactionData] = useState([])
    const [transactionDataBack, setTransactionDataBack] = useState([])
    const [filterCategoryId, setFilterCategoryId] = useState(0);
    const [searchString, setSearchString] = useState('');
    const [sortBy, setSortBy] = useState('Default');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [categoryData, setCategoryData] = useState(); // category data

    // getting user local storage data
    let local_user_data = localStorage.getItem('user_data');
    let User = JSON.parse(local_user_data);

    /**
     * Getting transaction statement for user
     * @param {Object} params 
     */
    const getAllTransaction = async (params) => {
        try {
            setLoading(true)
            let res = await GetApiParam('/transaction/getStatement', params);
            if (res?.data?.data && res?.data?.data?.length) {
                let filterNonZero = res?.data?.data.filter(item => parseFloat(item.amount) > 0)
                setTransactionData(filterNonZero)
                setTransactionDataBack(filterNonZero)
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    useEffect(() => {
        // if (User?.id) {
        //     setTimeout(() => {
        //         getAllTransaction({ user_id: User.id });
        //     }, 100);
        // }
        /**
        * Api to get Category Type Data
        */
        const getCategoryData = async () => {
            try {
                const res = await GetApi("/proposer/getCategory/");
                setCategoryData(res?.data?.data);
            } catch (error) {
                console.log(error);
            }
        }
        getCategoryData()
    }, [])

    /**
    * Searches in the data with "title"
    * @param {string} toSearch 
    * @returns {void}
    */
    const searchInData = (toSearch) => {
        setSearchString(toSearch)
        if (toSearch.length > 2) {
            setTransactionData(transactionDataBack.filter(o => o.asset_data?.asset_title.toLowerCase().includes(toSearch?.trim().toLowerCase())))
        } else if (toSearch.length <= 2) {
            if (User?.id) {
                getAllTransaction({ user_id: User.id });
            }
        }
    }

    /**
     * Handle sort by 
     * @param {*} event 
     */
    const handleSortByChange = (event) => {
        setSortBy(event.target.value);
        let value = event.target.value;
        if (value === 'Status') {
            setTransactionData([...transactionData].sort((a, b) => a.status.localeCompare(b.status)))
        }
        else if (value === 'Project Name') {
            setTransactionData([...transactionData].sort((a, b) => a.asset_data?.asset_title
                .localeCompare(b.asset_data?.asset_title)))
        }
        else if (value === 'Amount') {
            setTransactionData([...transactionData].sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount)))
        }
        else {
            setTransactionData(transactionData)
        }
    }


    const handleStartDateChange = (event) => {
        const newStartDate = event.target.value;

        setStartDate(newStartDate);
        // Update the maximum allowed date for the start date input
        document.getElementById('end-date-input').setAttribute('min', newStartDate);
    };

    const handleEndDateChange = (event) => {
        const newEndDate = event.target.value;

        setEndDate(newEndDate);
        // Update the maximum allowed date for the start date input
        document.getElementById('start-date-input').setAttribute('max', newEndDate);
    };

    /**
     * Handle change in category filter
     * @param {Event} event 
     */
    const handleChangeCategory = (event) => {
        setFilterCategoryId(event.target.value)
    };

    // when startDate, 
    useEffect(() => {
        if (startDate && endDate && filterCategoryId && User?.id) {
            getAllTransaction({ user_id: User.id, startDate: startDate, endDate: endDate, category_id: filterCategoryId })
        }
        else if (startDate && endDate && User?.id) {
            getAllTransaction({ user_id: User.id, startDate: startDate, endDate: endDate })
        }
        else if (filterCategoryId && User?.id) {
            getAllTransaction({ user_id: User.id, category_id: filterCategoryId })
        } else {
            if (User?.id) {
                setTimeout(() => {
                    getAllTransaction({ user_id: User.id });
                }, 100);
            }
        }
    }, [startDate, endDate, filterCategoryId])

    /**
     * 
     * @param {Integer or decimal} newValue 
     * @returns formatted value
     */
    const decimalFormat = (newValue) => {
        if (
            !Number.isInteger(newValue) &&
            newValue.toString().split(".")[1].length > 3
        ) {
            // If the number has more than two decimal places, apply toFixed(2)
            return newValue.toFixed(3);
        } else {
            return newValue
        }
    }
    return (
        <>
            <Box className="filter-panel" mt={4}>
                <Box className="fp-left">
                    <Box className="heading-left">
                        <Typography component={"h6"}>
                            My Transactions
                        </Typography>
                    </Box>
                </Box>

                <Box className="fp-right">
                    <Box className="filters-group-wrap" >
                        {/* Search bar filter */}
                        <Box className="filterSearch-form">
                            <Box component={"span"} className="search-icon">
                                <Search />
                            </Box>
                            <InputBase
                                // ref={searchBarRef}
                                className="filterSearch"
                                placeholder={"Search in transactions"}
                                value={searchString}
                                onChange={(e) => searchInData(e.target.value)}
                                inputProps={{ "aria-label": "search in transactions" }}
                            />
                        </Box>
                    </Box>

                    {/* Date range */}
                    {/*<Box className='DateRange' display={'flex'} >
                        <Box className="DatePicker" >
                            <TextField
                                id="start-date-input"
                                label="Start Date"
                                type="date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                InputLabelProps={{
                                    shrink: true, // This prop makes the label shrink when the input has content
                                }}
                            />
                        </Box>
                        <Box className="DatePicker">
                            <TextField
                                id='end-date-input'
                                label="End Date"
                                type="date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                InputLabelProps={{
                                    shrink: true, // This prop makes the label shrink when the input has content
                                }}
                            />
                        </Box>
                    </Box> */}

                    {/* Category filter */}
                    <Box className="select-group" ml={1.5}>
                        <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                            <InputLabel id="demo-controlled-open-select-label">
                                Category
                                <ViewComfy />
                            </InputLabel>
                            <Select
                                labelId="demo-controlled-open-select-label"
                                onChange={handleChangeCategory}
                                value={filterCategoryId}
                                label="Category"
                                variant='outlined'
                            >
                                <MenuItem value={0}>All</MenuItem>
                                {
                                    categoryData?.length && categoryData?.map((value, ind) => {
                                        return (
                                            <MenuItem key={ind} value={value.id}>
                                                <ListItemText>{value.title === "Asset" ? "Luxury Cars" : value.title}</ListItemText>
                                                <Typography className="menu-icon">
                                                    <ImageListItem>
                                                        <img
                                                            src={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                            srcSet={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                            alt={value.category_icon?.original_name}
                                                            loading="lazy"
                                                        />
                                                    </ImageListItem>
                                                </Typography>
                                            </MenuItem>
                                        );
                                    })

                                }
                            </Select>
                        </FormControl>
                    </Box>


                    {/* Sorting  */}
                    <Box className="select-group" ml={1.5}>
                        <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                            <InputLabel id="demo-controlled-open-select-label">
                                Sort By
                                <SwapVert />
                            </InputLabel>
                            <Select
                                labelId="demo-controlled-open-select-label"
                                value={sortBy}
                                onChange={handleSortByChange}
                                label="Sort By"
                                variant='outlined'
                            >
                                <MenuItem value={'Default'}>Default</MenuItem>
                                {
                                    sortByOptionsOffers?.map((value, ind) => {
                                        return (
                                            <MenuItem key={ind} value={value}>
                                                <ListItemText>{value}</ListItemText>
                                            </MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>

                    </Box>

                </Box>

            </Box>
            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>
                        <TableRow>
                            <StyledTableCell width={'25%'} align="left">Project Name</StyledTableCell>
                            <StyledTableCell width={'11%'} align="left">Amount</StyledTableCell>
                            <StyledTableCell width={'12%'} align="center">Date</StyledTableCell>
                            <StyledTableCell width={'13%'} align="center">Payment Type</StyledTableCell>
                            <StyledTableCell width={'13%'} align="center">Status</StyledTableCell>
                            <StyledTableCell width={'13%'} align="center">Transfer Process</StyledTableCell>
                            <StyledTableCell width={'13%'} align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactionData?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.asset_data?.asset_title}${i}`}>
                                <StyledTableCell component="th" scope="row" align="left">
                                    <Link to={`/project-details/${row.asset_data.id}`}>{row.asset_data?.asset_title}</Link>
                                </StyledTableCell>
                                <StyledTableCell align="left">${formatNumber(parseFloat(row.amount))}</StyledTableCell>
                                <StyledTableCell align="center">{formatDate(row.acceptedAt ? row.acceptedAt : row.createdAt)}</StyledTableCell>
                                <StyledTableCell align="center" className='payment-type-color'>{row?.is_minimum_prepayment ? TRANSACTION_TYPE_DISPLAY.LOAN_MIN_PREPAYMENT :
                                    row?.is_prepayment ? TRANSACTION_TYPE_DISPLAY.LOAN_PREPAYMENT : TRANSACTION_TYPE_DISPLAY[row?.transaction_type]}</StyledTableCell>
                                <StyledTableCell align="center">{TRANSACTION_STATUS_TEXT[row.status]}</StyledTableCell>
                                <StyledTableCell align="center">{(row?.add_asset_owner_id != User?.id && row?.user_id === User?.id) ? <Chip sx={{ color: "black", bgcolor: "Orange", borderRadius: "8px", }} label={"Debit"} /> : <Chip sx={{ color: "white", bgcolor: "#059669", borderRadius: "8px", }} label={"Credit"} />}</StyledTableCell>
                                <StyledTableCell align='center'><Button startIcon={<PriorityHigh />} className='status-button'>Dispute</Button></StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    )
}

export default MyTransactions

