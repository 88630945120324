import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, IconButton, CircularProgress } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import SliderCardProser from "../../../Components/Common/SliderCardProser";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import NumericFormatCustomDollar from "../../../Components/Common/NumericFormatCustomDollar";
import NumericFormatCustom from "../../../Components/Common/NumericFormatCustom";
import "./Components/Questionnaire.css";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import { INVESTMENT_TYPE_ID } from "../../../constants";

export default function SetPriceArtParcel(props) {
    const { showSnackbar } = useSnackbar()

    // Destructure props to obtain necessary functions and data
    const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress, setLoading } = props;

    // current proposal data
    let propData = getProposalData()
    let currentProgress = getProgress();

    const [parcel_total_price, setParcelTotalPrice] = useState(propData?.parcel_total_price ? propData?.parcel_total_price : 0);
    var grid_row = (propData?.number_of_rows) ? propData?.number_of_rows : 1;
    var grid_col = (propData?.number_of_columns) ? propData?.number_of_columns : 1;

    // state to hold max limit for project price
    const [isMaxLimitExceeded, setIsMaxLimitExceeded] = useState(false);
    const [inBetweenRange, setInBetweenRange] = useState(false);
    const [maxLimit, setMaxLimit] = useState(0);
    const [nextMaxLimit, setNextMinLimit] = useState(0);
    const [preMinLimit, setPreMaxLimit] = useState(0);

    // calculating number of parcel
    var defaultParcelCount = grid_row * grid_col + 1
    if (propData?.number_of_parcel) {
        defaultParcelCount = propData?.number_of_parcel
    } else {
        switch (propData?.selectedCategory) {
            case 'collections':
            case 'energy':
            case 'real-estate':
                defaultParcelCount = 5
                break;
            case 'gold':
            case 'watch':
                defaultParcelCount = 10
                break;
            default:
                defaultParcelCount = 1

        }
    }

    const [number_of_parcel, setNumberOfParcels] = useState(defaultParcelCount);
    const [minimun_investment_per_parcel, setMIPerParcels] = useState(propData?.minimun_investment_per_parcel ? propData?.minimun_investment_per_parcel : 0);
    const [number_of_columns, setArtColumns] = useState(propData?.number_of_columns ? propData?.number_of_columns : 1)
    const [number_of_rows, setArtRows] = useState(propData?.number_of_rows ? propData?.number_of_rows : 1)

    // updating minimum investment per parcel
    useEffect(() => {
        if (number_of_rows * number_of_columns > 0) {
            setMIPerParcels(decimalFormatter(parseFloat(parcel_total_price) / parseFloat(number_of_rows * number_of_columns)))
        } else {
            setMIPerParcels(decimalFormatter(parseFloat(parcel_total_price)))
        }
    }, [number_of_rows, number_of_columns, parcel_total_price])

    // updating total parcels
    useEffect(() => {
        if (number_of_rows * number_of_columns > 0) {
            setNumberOfParcels(number_of_rows * number_of_columns);
            // if (parcel_total_price) {
            //     const newMinimum = parseFloat(parcel_total_price / (number_of_columns * number_of_rows)).toFixed(2);
            //     const newPrice = (newMinimum * (number_of_columns * number_of_rows)).toFixed(2);
            //     setParcelTotalPrice(newPrice)
            // }
        } else {
            setNumberOfParcels(0)
        }
    }, [number_of_rows, number_of_columns])


    /**
    * Function to handle change parcel type + $500
    * @param {String} type 
    */
    const changeParcelTotalPrice = (type) => {
        if (type === 'inc') {
            setParcelTotalPrice(prevCount => prevCount ? (parseFloat(prevCount) + 500) : 500);
        } else {
            setParcelTotalPrice(prevCount => (parseFloat(prevCount) >= 500 ? parseFloat(prevCount) - 500 : prevCount));
        }
    }

    /**
    * Function to handle change row by 1
    * @param {String} type 
    */
    const changeArtRows = (type) => {
        if (type === 'inc') {
            setArtRows(prevCount => prevCount ? (parseInt(prevCount) + 1) : 1);
            setNumberOfParcels((number_of_rows + 1) * number_of_columns);
        } else {
            setArtRows(prevCount => (parseInt(prevCount) >= 2 ? parseInt(prevCount) - 1 : prevCount));
            setNumberOfParcels(((number_of_rows > 1) ? (number_of_rows - 1) : number_of_rows) * number_of_columns);
        }

    }

    /**
    * Function to handle  change column by 1
    * @param {String} type 
    */
    const changeArtColumns = (type) => {
        if (type === 'inc') {
            setArtColumns(prevCount => prevCount ? (parseInt(prevCount) + 1) : 1);
            setNumberOfParcels(number_of_rows * (number_of_columns + 1));
        } else {
            setArtColumns(prevCount => (parseInt(prevCount) >= 2 ? parseInt(prevCount) - 1 : prevCount));
            setNumberOfParcels(number_of_rows * ((number_of_columns > 1) ? (number_of_columns - 1) : number_of_columns));
        }
    }

    /**
    * Function to handle "Next" button
    */
    const handleClick = () => {
        if (propData?.number_of_columns != number_of_columns || propData?.number_of_rows != number_of_rows || propData?.parcel_total_price != parcel_total_price || propData?.cover_photo_changed) {
            propData.parcelsDetails = []
        }
        propData.parcel_total_price = parcel_total_price;
        propData.number_of_columns = number_of_columns;
        propData.number_of_rows = number_of_rows;
        propData.number_of_parcel = number_of_columns * number_of_rows;
        propData.minimun_investment_per_parcel = minimun_investment_per_parcel;

        setLoading(true)
        setTimeout(() => {
            updateProposalData(propData);
            handleNext();
        }, 1000)
    }


    /**
    * Function to handle "Save and Exit"
    */
    const SaveAndExit = () => {
        if (propData?.number_of_columns != number_of_columns || propData?.number_of_rows != number_of_rows || propData?.parcel_total_price != parcel_total_price || propData?.cover_photo_changed) {
            propData.parcelsDetails = []
        }
        propData.parcel_total_price = parcel_total_price;
        propData.number_of_columns = number_of_columns;
        propData.number_of_rows = number_of_rows;
        propData.number_of_parcel = number_of_columns * number_of_rows;
        propData.minimun_investment_per_parcel = minimun_investment_per_parcel;

        updateProposalData(propData);
        saveProposal();
    }

    /**
    * Function to Integer and two decimal value
    */
    const decimalFormatter = (value) => {
        // Check if the value has more than two decimal places
        if (Number.isInteger(value)) {
            // If the value is an integer or has at most two decimal places, return it as is
            return value;
        } else {
            // If the value has more than two decimal places, apply toFixed(2)
            return value.toFixed(2);
        }
    }

    useEffect(() => {
        if (parcel_total_price) {
            // checking for max range
            let checkMaxLimitExceeded = true, checkMaxLimit = 0, checkPreMaxLimit = 0, checkNextMinLimit = 0;
            let stackAmtData = propData?.stackAmtData;
            stackAmtData?.filter(item => item.investment_type_id === INVESTMENT_TYPE_ID.PARCEL)
            let projectAmt = parseFloat(parcel_total_price)
            if (stackAmtData?.length) {
                stackAmtData?.forEach((dataItem) => {
                    checkPreMaxLimit = checkMaxLimit
                    checkNextMinLimit = dataItem?.min_amount
                    checkMaxLimit = dataItem?.max_amount
                    if (parseFloat(dataItem?.min_amount) <= parseFloat(projectAmt) && parseFloat(projectAmt) <= parseFloat(dataItem?.max_amount)) {
                        checkMaxLimitExceeded = false
                    }
                })
                setIsMaxLimitExceeded(checkMaxLimitExceeded)
                setMaxLimit(checkMaxLimit)
                setNextMinLimit(checkNextMinLimit)
                setPreMaxLimit(checkPreMaxLimit)
            }

            if (!checkMaxLimitExceeded) {
                setInBetweenRange(false)
            }
            // check for max range exceeded
            if (checkMaxLimitExceeded && checkMaxLimit > 0 && (parseFloat(checkPreMaxLimit) < parseFloat(projectAmt) && parseFloat(projectAmt) < parseFloat(checkNextMinLimit))) {
                showSnackbar(`You cannot create a project in range from $${checkPreMaxLimit} to $${checkNextMinLimit}`, 'warning');
                setInBetweenRange(true)
                setIsMaxLimitExceeded(false)
            } else if (checkMaxLimitExceeded && checkMaxLimit > 0) {
                showSnackbar(`You cannot create a project that exceeds the set range configuration $${checkMaxLimit}`, 'error');
            } else if (checkMaxLimitExceeded && checkMaxLimit == 0) {
                showSnackbar(`Range configuration is not set for the asset type`, 'error');
            }
        }
    }, [parcel_total_price])

    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-left">
                            {/* left-title */}
                            <Box className="quests-left-title">
                                <Typography component="h1" className="title" >
                                    Now, set your price
                                </Typography>
                            </Box>
                            {/* Logo */}
                            <Box className="auth-logo">
                                <Link to="/">
                                    <Box component="img" src={NewLogo} alt="Race logo" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-right  quests-right-contents-box">
                            <Box textAlign={"right"}>
                                <Box component="span" className="icon" ml={1}>
                                    <Button onClick={SaveAndExit} disabled={
                                        (parcel_total_price !== "" && number_of_parcel !== "" && minimun_investment_per_parcel !== "" && number_of_columns > 0 && number_of_rows > 0 && parcel_total_price > 0 && number_of_columns <= 10 && number_of_rows <= 10 && !isMaxLimitExceeded && !inBetweenRange)
                                            ? false : true} className="btn-rounded btn-text-white btn-blue-600">
                                        Save and Exit
                                    </Button>
                                </Box>
                            </Box>
                            <Box className="qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                                <Box className="questionnaire5">
                                    <Box className="CategoryBox">
                                        <Grid container direction="row">
                                            <Grid item xs={1} sm={1} md={2} lg={2.5}></Grid>
                                            <Grid item xs={10} sm={10} md={8} lg={7} className="priceDetailsImage">
                                                <SliderCardProser
                                                    height={"65%"}
                                                    image={(propData?.asset_coverphoto_view?.startsWith("proposer/uploads/")) ? `${process.env.REACT_APP_IMAGE_URL}${propData?.asset_coverphoto_view}` : propData?.asset_coverphoto_view}
                                                    title={propData?.artProjectDetails?.art_name}
                                                />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={2} lg={2.5}></Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box>
                                    <Grid container spacing={{ xs: 1, md: 1, sm: 1 }} my={2}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Box textAlign={"center"} my={2}>
                                                <Typography fontWeight={"500"} color="black">
                                                    {"Sale Price"}
                                                </Typography>
                                                <Box className="NS-sale-details" my={1}>
                                                    <Box className='NS-sale-details-icon'>
                                                        <IconButton onClick={() => { changeParcelTotalPrice('dec') }} className="NS-sale-details-icon-btn">
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </Box>
                                                    <Box>
                                                        <TextField
                                                            id="outlined-number"
                                                            type="text"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={
                                                                {
                                                                    inputComponent: NumericFormatCustomDollar,
                                                                }}
                                                            value={parcel_total_price}
                                                            size="small"
                                                            helperText={"USD"}
                                                            onChange={(e) => setParcelTotalPrice(parseFloat(e.target.value))}
                                                            onKeyDown={(event) => {
                                                                if (event?.key === '-' || event?.key === '+') {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box className='NS-sale-details-icon'>
                                                        <IconButton onClick={() => { changeParcelTotalPrice('inc') }} className="NS-sale-details-icon-btn">
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <Box textAlign={"center"} my={2}>
                                                <Typography fontWeight={"500"} color="black">
                                                    Number of Rows
                                                </Typography>
                                                <Box className="NS-sale-details" my={1}>
                                                    <Box className='NS-sale-details-icon'>
                                                        <IconButton onClick={() => { changeArtRows('dec') }} className="NS-sale-details-icon-btn">
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </Box>
                                                    <Box>
                                                        <TextField
                                                            id="outlined-number"
                                                            type="text"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={
                                                                {
                                                                    inputComponent: NumericFormatCustom,
                                                                }}
                                                            helperText={'Number of Rows'}
                                                            value={number_of_rows}
                                                            size="small"
                                                            onChange={(e) => { setArtRows(e.target.value); }}
                                                            onKeyDown={(event) => {
                                                                if (event?.key === '-' || event?.key === '+' || event.key === '.') {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box className='NS-sale-details-icon'>
                                                        <IconButton
                                                            onClick={() => { changeArtRows('inc'); }} className="NS-sale-details-icon-btn">
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Typography sx={{ color: 'red', fontSize: '10px', textAlign: 'center', marginTop: '-8px' }}
                                            >{Boolean(number_of_rows === '') ? "Row is required." : Boolean(number_of_rows == 0) ? 'Row can not be 0.' : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <Box textAlign={"center"} my={2}>
                                                <Typography fontWeight={"500"} color="black">
                                                    Number of Columns
                                                </Typography>
                                                <Box className="NS-sale-details" my={1}>
                                                    <Box className='NS-sale-details-icon'>
                                                        <IconButton onClick={() => { changeArtColumns('dec') }} className="NS-sale-details-icon-btn">
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </Box>
                                                    <Box>
                                                        <TextField
                                                            id="outlined-number"
                                                            type="text"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{ pattern: '[1-9]*' }}
                                                            InputProps={
                                                                {
                                                                    inputComponent: NumericFormatCustom,
                                                                }}
                                                            value={number_of_columns}
                                                            helperText="Number of Columns"
                                                            size="small"
                                                            onChange={(e) => setArtColumns(e.target.value)}
                                                            onKeyDown={(event) => {
                                                                if (event?.key === '-' || event?.key === '+' || event.key === '.') {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box className='NS-sale-details-icon'>
                                                        <IconButton onClick={() => { changeArtColumns('inc') }} className="NS-sale-details-icon-btn">
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Typography sx={{ color: 'red', fontSize: '10px', textAlign: 'center', marginTop: '-8px' }}
                                            >{Boolean(number_of_columns === '') ? "Column is required." : Boolean(number_of_columns == 0) ? 'Column can not be 0.' : ''}
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <Box textAlign={"center"} my={2}>
                                                <Typography fontWeight={"500"} color="black">
                                                    Number of Parcels
                                                </Typography>
                                                <Typography fontSize={"12px"} color={"#94A3B8"}>
                                                    This is the total number of parcels.
                                                </Typography>
                                                <Typography fontSize={"12px"}>
                                                    {"Dummy"}
                                                </Typography>
                                            </Box>
                                            <Box className="NS-sale-details-noPM" mb={2}>
                                                <TextField
                                                    disabled
                                                    id="outlined-number"
                                                    type="text"
                                                    className="disabledTextField"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={number_of_parcel}
                                                    size="small"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <Box textAlign={"center"} my={2}>
                                                <Typography fontWeight={"500"} color="black">
                                                    Minimum Investment per Parcel
                                                </Typography>
                                                <Typography fontSize={"12px"} color={"#94A3B8"}>
                                                    This is the minimum amount investors can purchase a parcel at
                                                </Typography>
                                            </Box>
                                            <Box className="NS-sale-details-noPM" mb={2}>
                                                <TextField
                                                    disabled
                                                    id="outlined-number"
                                                    type="text"
                                                    className="disabledTextField"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        inputComponent: NumericFormatCustomDollar,
                                                    }}
                                                    value={minimun_investment_per_parcel ? minimun_investment_per_parcel : 0}
                                                    size="small"
                                                // onChange={(e) => setMIPerParcelsArt(e.target.value)}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box textAlign={"center"} my={2}>
                                        <Typography fontSize={"18px"} color={"black"}>
                                            You’ll be able to edit individual parcel prices in the
                                            next step
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography sx={{ color: 'red', fontSize: '11px', textAlign: 'center', marginTop: 2.5 }}>{Boolean(number_of_columns > 10 || number_of_rows > 10) ? "Row or Column cannot exceeded to 10." : isMaxLimitExceeded ? `You cannot create the project above $${maxLimit}` : inBetweenRange ? `You cannot create a project in range from $${preMinLimit} to $${nextMaxLimit}` : ""}</Typography>
                            </Box>
                            <Box>
                                <Box className="questionnaire-progress">
                                    <LinearProgressCompo value={currentProgress} />
                                </Box>
                                <Box
                                    sx={{ display: "flex", justifyContent: "space-between" }}
                                    mt={3}
                                >
                                    <Box textAlign={"left"} className="quests6-btn-box">
                                        <Button
                                            className="btn-rounded back-btn"
                                            onClick={handleback}
                                        >
                                            Back
                                        </Button>
                                    </Box>
                                    <Box textAlign={"right"} className="quests-btn-box">
                                        <Button
                                            disabled={
                                                (parcel_total_price !== "" && number_of_parcel !== "" && minimun_investment_per_parcel !== "" && number_of_columns > 0 && number_of_rows > 0 && parcel_total_price > 0 && number_of_columns <= 10 && number_of_rows <= 10 && !isMaxLimitExceeded && !inBetweenRange)
                                                    ? false : true}
                                            onClick={handleClick}
                                            endIcon={<KeyboardArrowRightIcon />}
                                            className="btn-rounded btn-text-white btn-blue-600"
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
