// payMinimumPrepayment.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";
import convertToDecimal from "../Common/convertToDecimal";
import convertToSixDecimal from "../Common/convertToSixDecimal";
import getCurrencyTypeAddress from "../Common/getCurrencyTypeAddress";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Call repayment method to repay the emi
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function payMinimumPrepayment(address, assetData, user_id, asset_id, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // set active step to 2
        handleActiveStep(2)

        let contractAddress = CONTRACTS.WATCH_REPAYMENT_ADDRESS;
        let args = [
            assetData?.listing_id, // listing id / ipfs code id Integer
            convertToDecimal(assetData?.amount), // offer amount / offeredAmount
        ]
        if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
            contractAddress = CONTRACTS.SPV_DEBT_REPAYMENT_ADDRESS
        } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            contractAddress = CONTRACTS.WATCH_REPAYMENT_ADDRESS
            if (asset_id === 4) {
                contractAddress = CONTRACTS.WATCH_REPAYMENT_ADDRESS_OLD
            }
            args = [
                assetData?.listing_id, // listing id / ipfs code id Integer
                getCurrencyTypeAddress(assetData?.feeTokenType),// _feeToken
                getCurrencyTypeDecimal(assetData?.feeTokenType, assetData?.amount), //  // offer amount / offeredAmount
                getCurrencyTypeDecimal(assetData?.feeTokenType, assetData?.penaltyMinAmount), //  penaltyAmount
            ]
        }

        // make offer by investor
        const { request: payMinimumPrepaymentAmount } = await prepareWriteContract({
            address: contractAddress,
            abi: CONTRACT_ABI[contractAddress],
            account: address,
            chainId: 6806,
            functionName: "payMinimumPrepaymentAmount",
            args: args
        })
        console.log(payMinimumPrepaymentAmount);
        const { hash } = await writeContract(payMinimumPrepaymentAmount)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(3)
                setTimeout(() => {
                    handleSuccess(data,assetData ,assetData?.amount, asset_id, user_id )
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error calling minimum prepayment:', error);
        throw error; // propagate the error
    }
}

export { payMinimumPrepayment };