import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, IconButton, InputAdornment, Paper, Stack, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { GetApiParam } from '../../../Api/Api';
import { Info } from '@mui/icons-material';
import { formatNumber } from '../../../Components/Common/USFormat';
import NumericFormatCustom from '../../../Components/Common/NumericFormatCustom';
import '../Investor.css';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: '#05131B',
    borderRadius: '8px',
  },
  '& .MuiInputBase-input.MuiFilledInput-input': {
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '16px',
  },
  '& .MuiFilledInput-underline:before': {
    display: 'none',
  },
  '& .MuiFilledInput-underline:after': {
    display: 'none',
  },
  '& .MuiFilledInput-underline:hover:before': {
    display: 'none',
  },
  '& .MuiFilledInput-underline:hover:after': {
    display: 'none',
  },
}));

const Collateral = forwardRef(({ assetId, assetTitle, loanPrice, loanROI, loanIop, loanDuration, changeAmount, changeDuration, changeInterestRateLoan, minimum_investment, state, disableCondition }, ref) => {
  const navigate = useNavigate();
  const [loanAmount, setLoanAmount] = useState('');
  const [loanTerm, setLoanTerm] = useState(loanDuration);
  const [interestRate, setInterestRate] = useState(loanROI);
  const [timer, setTimer] = useState(null);
  const [loanAmountError, setLoanAmountError] = useState({ error: false, message: '' });
  const [interestError, setInterestError] = useState({ error: false, message: '' });
  const [remainingAmount, setRemainingAmount] = useState('');
  const [tooltipOpen, setTooltipOpen] = useState({ 1: false, 2: false, 3: false });
  const isMobileOrTab = useMediaQuery('(max-width: 1024px)');

  // handling updated data
  const [updatedData, setUpdatedData] = useState({
    loan_duration_from: parseInt(loanIop + 1),
    loan_roi_from: "1"
  });

  useEffect(() => {
    setLoanTerm(loanDuration);
    // setInterestRate(loanROI);
  }, [loanDuration]);

  const handleBackBtn = () => {
    // navigate(-1);
    navigate(`/project-details/${state?.assetId}`, { replace: true })
  };

  const changeLongTerm = (type) => {
    if (type === 'inc') {
      setLoanTerm(prevCount => ((prevCount < loanDuration) ? prevCount + 1 : prevCount));
    } else {
      setLoanTerm(prevCount => ((prevCount - 1 && (prevCount > updatedData.loan_duration_from)) > 0 ? prevCount - 1 : prevCount));
    }
  };

  const changeInterestRate = (type) => {
    if (type === 'inc') {
      setInterestRate(prevCount => prevCount ? (parseFloat(prevCount) + 0.25 <= loanROI ? parseFloat(parseFloat(prevCount) + 0.25).toFixed(2) : prevCount) : loanROI);
    } else {
      setInterestRate(prevCount => prevCount ? ((parseFloat(prevCount) - 0.25) >= updatedData.loan_roi_from ? parseFloat(parseFloat(prevCount) - 0.25).toFixed(2) : prevCount) : updatedData.loan_roi_from);
    }
  };

  const handleChangeInterest = (event) => {
    const inputValue = event.target.value;

    // Regular expression to match numbers within the range 0 to 100
    const isValidInput = /^\d*\.?\d{0,2}$/.test(inputValue);

    if ((isValidInput || inputValue === '')) {
      if (inputValue?.endsWith('.')) {
        setInterestError({ error: true, message: 'Please enter a valid interest rate' });
      } else if (parseFloat(inputValue) < parseFloat(updatedData.loan_roi_from) || loanROI < parseFloat(inputValue)) {
        setInterestError({ error: true, message: `Please enter an interest rate within the range of ${parseFloat(updatedData.loan_roi_from)}% to ${loanROI}%` });
      } else { // no error
        setInterestError({ error: false, message: '' });
      }
      setInterestRate(inputValue);
    }
  };

  const handleChangeAmount = (event) => {
    const inputValue = event.target.value;
    // Regular expression to match numbers within the range 0 to 100
    const isValidInput = /^\d*\.?\d{0,2}$/.test(inputValue);

    if ((isValidInput || inputValue === '')) {
      setLoanAmount(inputValue);
    }
  };

  const handleMouseDown = (type, callback) => {
    // Start incrementing the count when the button is pressed
    setTimer(setInterval(() => {
      callback(type);
    }, 100));
  };

  const handleMouseUp = () => {
    // Stop incrementing the count when the button is released
    clearInterval(timer);
    setTimer(null);
  };

  useEffect(() => {
    changeAmount(loanAmount);
  }, [loanAmount]);

  useEffect(() => {
    changeDuration(loanTerm);
  }, [loanTerm, loanDuration]);

  useEffect(() => {
    changeInterestRateLoan(interestRate);
  }, [interestRate, loanROI]);

  useEffect(() => {
    const getSuggestData = async () => {
      const res = await GetApiParam("/committee/getSuggestReview", { "asset_id": assetId });
      if (res?.data?.data[0]) {
        setUpdatedData(res?.data?.data[0]);
      }
    };
    const getRemainingAmount = async () => {
      const resp = await GetApiParam('/invest/getAssetFundedAmount', { "asset_id": assetId });
      if (resp?.data?.code === 200) {
        setRemainingAmount(resp?.data?.totalAmount);
      }
    };
    if (assetId) {
      getSuggestData();
      getRemainingAmount();
    }
  }, [assetId]);

  // Expose the child function through the ref
  useImperativeHandle(ref, () => ({
    getData() {
      if (validationCheck(loanAmount) && validationInterestRate(interestRate)) {
        return { offer_amount: loanAmount, loan_duration: loanTerm, loan_roi: interestRate };
      } else {
        return false;
      }
    }
  }));

  const validationCheck = (amount) => {
    let value = parseFloat(amount);
    let check = `${amount}`
    let newAmount = remainingAmount ? parseFloat(loanPrice) - parseFloat(remainingAmount) : loanPrice;
    if (isNaN(value)) {
      setLoanAmountError({ error: true, message: 'Amount is required' });
      return false;
    } if (check.endsWith('.')) {
      setLoanAmountError({ error: true, message: 'Please enter a valid amount' });
      return false;
    } else if (value === 0) {
      setLoanAmountError({ error: true, message: 'Amount Should be greater than zero' });
      return false;
    } else if (value > newAmount) {
      if (remainingAmount != loanAmount) {
        setLoanAmountError({ error: true, message: `You can only offer the remaining amount` });
      } else {
        setLoanAmountError({ error: true, message: `Amount should be less than $${formatNumber(parseFloat(newAmount))}` });
      }
      return false;
    } else if (value < minimum_investment) {
      setLoanAmountError({ error: true, message: `Minimum investment amount is $${formatNumber(parseFloat(minimum_investment))}` });
      return false;
    } else { // no error
      setLoanAmountError({ error: false, message: '' });
      return true;
    }
  };

  const validationInterestRate = (rate) => {
    let check = `${rate}`;
    if (isNaN(parseFloat(rate))) {
      setInterestError({ error: true, message: 'Interest rate is required' });
      return false;
    } else if (check && check?.endsWith('.')) {
      setInterestError({ error: true, message: 'Please enter a valid interest rate' });
      return false;
    } else if (parseFloat(check) < parseFloat(updatedData.loan_roi_from) || loanROI < parseFloat(check)) {
      setInterestError({ error: true, message: `Please enter an interest rate within the range of ${parseFloat(updatedData.loan_roi_from)}% to ${loanROI}%` });
      return false;
    }
    else { // no error
      setInterestError({ error: false, message: '' });
      return true;
    }
  };

  /**
   * Tooltip Close function
   * @param {*} tooltipId 
   */
  const handleTooltipClose = (tooltipId) => {
    if (isMobileOrTab) {
      setTooltipOpen(prevState => ({
        ...prevState,
        [tooltipId]: false
      }));
    }
  };

  /**
   * Tooltip Open Function
   * @param {*} tooltipId 
   */
  const handleTooltipOpen = (tooltipId) => {
    if (isMobileOrTab) {
      setTooltipOpen(prevState => ({
        ...prevState,
        [tooltipId]: true
      }));
    }
  };


  useEffect(() => {
    if (!isMobileOrTab) {
      setTooltipOpen(false);
    }
  }, [isMobileOrTab]);

  return (
    <Paper elevation={3} sx={{ p: 2 }} className='rc-basic-container'>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
        <IconButton onClick={handleBackBtn} sx={{ color: '#ffffff' }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='h6' sx={{ flexGrow: 1 }}>Lend for {assetTitle}</Typography>
      </Stack>
      <Box component={'hr'} className='rc-hr' />
      <Typography variant='subtitle2' sx={{ mt: 2, mb: 2 }} gutterBottom>Loan Amount</Typography>
      <Box className="payment-enter-investor loan-type">
        <CustomTextField
          onChange={(event) => { handleChangeAmount(event); validationCheck(event.target.value); }}
          required
          name='loanAmount'
          value={loanAmount}
          disabled={disableCondition ? true : false}
          hiddenLabel
          type='text'
          placeholder="Amount"
          variant='filled'
          error={loanAmountError.error}
          helperText={loanAmountError.message ? loanAmountError.message : `Full amount $${remainingAmount ? formatNumber(parseFloat(loanPrice) - parseFloat(remainingAmount)) : formatNumber(loanPrice)}`}
          fullWidth
          InputProps={{
            inputComponent: NumericFormatCustom,
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon sx={{ color: '#C5E4FF' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  onClick={() => { setLoanAmount(remainingAmount ? parseFloat(loanPrice) - parseFloat(remainingAmount) : loanPrice); validationCheck(remainingAmount ? parseFloat(loanPrice) - parseFloat(remainingAmount) : loanPrice) }}
                  className="rc-tertiary-btn btn-golden"
                  variant="filled"
                  sx={{ height: '27px', marginRight: '11px' }}
                  disabled={disableCondition ? true : false}
                >
                  Max
                </Button>
                <Tooltip
                  classes={{ tooltip: 'custom-tooltip', arrow: 'custom-arrow' }}
                  PopperProps={{ style: { maxWidth: '200px', maxHeight: '130px' } }}
                  title={<span className='font-14'>You can submit an offer between the minimum investment to the max loan amount requested</span>}
                  placement='bottom'
                  arrow
                  open={isMobileOrTab ? tooltipOpen[1] : undefined}
                  onClose={() => handleTooltipClose(1)}
                >
                  <Info
                    style={{ color: 'white' }}
                    onClick={() => handleTooltipOpen(1)}
                    onMouseOver={isMobileOrTab ? undefined : () => handleTooltipOpen(1)}
                    onMouseOut={isMobileOrTab ? undefined : () => handleTooltipClose(1)}
                  />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          sx={{ input: { color: 'white !important' } }}
          FormHelperTextProps={{ sx: { color: 'white' } }}
        // onInput={(event) => {
        //   event.target.value = event.target.value.replace(/\D/g, "");
        // }}
        />
      </Box>

      <Grid container spacing={{ xs: 1 }} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography
            variant='subtitle2'
            sx={{
              mt: 2, mb: 2, display: 'flex', alignItems: "center",
              gap: '8px'
            }}
            gutterBottom
          >
            Loan Duration
            <Tooltip
              classes={{ tooltip: 'custom-tooltip', arrow: 'custom-arrow' }}
              PopperProps={{ style: { maxWidth: '200px', maxHeight: '130px' } }}
              title={<span className='font-14'>Select the duration of your loan offer</span>}
              placement='bottom'
              arrow
              open={isMobileOrTab ? tooltipOpen[2] : undefined}
              onClose={() => handleTooltipClose(2)}
            >
              <Info
                style={{ color: 'white' }}
                onClick={() => handleTooltipOpen(2)}
                onMouseOver={isMobileOrTab ? undefined : () => handleTooltipOpen(2)}
                onMouseOut={isMobileOrTab ? undefined : () => handleTooltipClose(2)}
              />
            </Tooltip>
          </Typography>
          <Box className="rc-number-increment-wrapper">
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <IconButton className='rc-icon-fab-secondary duration-interest'
                disabled={(disableCondition || parseInt(updatedData.loan_duration_from) == parseInt(loanDuration)) ? true : false}
                onMouseDown={() => handleMouseDown('dec', changeLongTerm)} onMouseUp={handleMouseUp} onClick={() => { changeLongTerm('dec'); }}><RemoveIcon /></IconButton>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant='subtitle2' sx={{ flexGrow: 1 }}>{loanTerm} Months</Typography>
                <Typography variant='caption' sx={{ flexGrow: 1 }}>Min: {parseInt(updatedData.loan_duration_from)} Months, Max: {loanDuration} Months</Typography>
              </Box>
              <IconButton
                disabled={(disableCondition || parseInt(updatedData.loan_duration_from) === parseInt(loanDuration)) ? true : false}
                className='rc-icon-fab-secondary duration-interest' onMouseDown={() => handleMouseDown('inc', changeLongTerm)} onMouseUp={handleMouseUp} onClick={() => { changeLongTerm('inc'); }}><AddIcon /></IconButton>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography
            variant='subtitle2'
            sx={{
              mt: 2, mb: 2, display: 'flex', alignItems: "center",
              gap: '8px'
            }}
            gutterBottom
          >
            Interest Rate
            <Tooltip
              classes={{ tooltip: 'custom-tooltip', arrow: 'custom-arrow' }}
              PopperProps={{ style: { maxWidth: '200px', maxHeight: '130px' } }}
              title={<span className='font-14'>Select the interest rate you prefer for your loan offer</span>}
              placement='bottom'
              arrow
              open={isMobileOrTab ? tooltipOpen[3] : undefined}
              onClose={() => handleTooltipClose(3)}
            >
              <Info
                style={{ color: 'white' }}
                onClick={() => handleTooltipOpen(3)}
                onMouseOver={isMobileOrTab ? undefined : () => handleTooltipOpen(3)}
                onMouseOut={isMobileOrTab ? undefined : () => handleTooltipClose(3)}
              />
            </Tooltip>
          </Typography>
          <Box className="rc-number-increment-wrapper">
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <IconButton
                disabled={(disableCondition || parseFloat(updatedData.loan_roi_from) == loanROI) ? true : false}
                className='rc-icon-fab-secondary duration-interest' onMouseDown={() => handleMouseDown('dec', changeInterestRate)} onMouseUp={handleMouseUp} onClick={() => { changeInterestRate('dec'); }} ><RemoveIcon /></IconButton>
              <Box sx={{ textAlign: 'center' }} className='investor-ror-custom-field'>
                <TextField
                  size='small'
                  // InputProps={{
                  //   inputComponent:
                  //     NumericFormatCustomPercent,
                  // }}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "white" },
                  }}
                  inputProps={{
                    style: {
                      color: "white",
                      height: '23px',
                      width: '128px',
                      fontSize: '14px',
                      fontWeight: '500',
                      padding: '0px 10px 0px',
                      textAlign: 'center',
                    },
                  }}
                  disabled={(disableCondition || parseFloat(updatedData.loan_roi_from) == loanROI) ? true : false}
                  autoComplete='off'
                  value={interestRate}
                  onChange={handleChangeInterest}
                />
                {/* <Typography variant='subtitle2' sx={{ flexGrow: 1 }}>{interestRate}%</Typography> */}
                <Typography variant='caption' sx={{ flexGrow: 1 }}>Min: {parseFloat(updatedData.loan_roi_from)}%, Max: {loanROI}%</Typography>
              </Box>
              <IconButton
                disabled={(disableCondition || parseFloat(updatedData.loan_roi_from) == loanROI) ? true : false}
                className='rc-icon-fab-secondary duration-interest' onMouseDown={() => handleMouseDown('inc', changeInterestRate)} onMouseUp={handleMouseUp} onClick={() => { changeInterestRate('inc'); }}><AddIcon /></IconButton>
            </Stack>
          </Box>
          <Typography className='custom-error-investor-page'>{interestError.error ? interestError.message : ""}</Typography>
        </Grid>
      </Grid>
    </Paper >
  );
});

Collateral.propTypes = {
  assetId: PropTypes.string.isRequired,
  assetTitle: PropTypes.string.isRequired,
  loanPrice: PropTypes.number.isRequired,
  loanROI: PropTypes.number.isRequired,
  loanIop: PropTypes.number.isRequired,
  loanDuration: PropTypes.number.isRequired,
  changeAmount: PropTypes.func.isRequired,
  minimum_investment: PropTypes.number.isRequired,
};

export default Collateral;
