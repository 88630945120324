import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

export default function AssetDetails({ details, editable }) {
  return (
    <>
      <Box className="pd-sidebar-assetDetails">
        <Stack
          className="pd-sidebar-heading"
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className="title" component={"h4"}>
           Asset Details
          </Typography>
        </Stack>
        <Box className="pd-sidebar-detailsList" mt={0.5}>
          {details?.map((val, ind) => {
            return (
              <Stack
                className="detailsList"
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
                key={ind}
              >
                <Typography className="title">{val.title}</Typography>
                <Typography className="subtitle">{val.value}</Typography>
              </Stack>
            );
          })}
        </Box>
      </Box>
    </>
  );
}

AssetDetails.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  editable: PropTypes.bool,
};
