import axiosInstance from './AuthServices';
import axios from 'axios';

const storeTokenFromResponse = (response) => {
  // getting token from every api response
  const token = response.headers['authorization'] || response.headers['Authorization'];
  if (token) {
    localStorage.setItem('token', token);
  }
  return response;
};

export const postApi = async (url, body) => {
  return await axiosInstance.post(url, body).then(storeTokenFromResponse).catch((error) => error);
};

export const updateApi = async (url, body) => {
  return await axiosInstance.put(url, body).then(storeTokenFromResponse).catch((error) => error);
};

export const GetApi = async (url) => {
  return await axiosInstance.get(url).then(storeTokenFromResponse).catch((error) => error);
};

export const GetApiParam = async (url, body) => {
  return await axiosInstance.get(url, { params: body }).then(storeTokenFromResponse).catch((error) => error);
};

export const googleUserInfo = async (url, access_token, headers) => {
  return await axios.get(`${url}${access_token}`, { headers }).then(res => res).catch((error) => error);
};

export const deleteApi = async (url) => {
  return await axiosInstance.delete(url).then(storeTokenFromResponse).catch((error) => error);
};

export const assetDeleteApi = async (url) => {
  return await axiosInstance.delete(url).then(storeTokenFromResponse).catch((error) => error);
};

export const assetUpdateParcelApi = async (url, body) => {
  return await axiosInstance.put(url, body).then(storeTokenFromResponse).catch((error) => error);
};
