// changingOffer.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";
import convertToDecimal from "../Common/convertToDecimal";
import convertToSixDecimal from "../Common/convertToSixDecimal";
import getCurrencyTypeAddress from "../Common/getCurrencyTypeAddress";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Call changingOffer method for investor to edit the offer data
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Number} offerAmount offer amount 
 * @param {Number} offeredPercentage offer percentage 
 * @param {Number} offeredDuration offer duration 
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function changingOffer(address, assetData, offerAmount, offeredPercentage, offeredDuration, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // Set active step to 2
        handleActiveStep(2);

        const token_address = getCurrencyTypeAddress(assetData?.feeTokenType);
        const amount = getCurrencyTypeDecimal(assetData?.feeTokenType, offerAmount);

        const changingOfferArgs = getChangingOfferArgs(assetData, address, amount, offeredPercentage, offeredDuration,token_address,offerAmount);

        const { request: changingOffer } = await prepareWriteContract(changingOfferArgs);
        const { hash } = await writeContract(changingOffer);

        if (hash) {
            const data = await waitForTransaction({ hash });
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(3);
                setTimeout(() => {
                    handleSuccess(assetData);
                    handleModalClose();
                    handleActiveStep(-1);
                    return { data };
                }, 1000);
            } else {
                handleModalClose();
                handleActiveStep(-1);
                showSnackbar("Transaction Failed", 'error');
            }
        }
    } catch (error) {
        console.error('Error calling changing offer:', error);
        throw error; // propagate the error
    }
}

function getChangingOfferArgs(assetData, address, amount, offeredPercentage, offeredDuration,token_address,offerAmount) {
    const baseArgs = {
        account: address,
        chainId: 6806,
        functionName: "changingOffer",
    };

    const listingId = assetData?.offerDetails?.listing_id;
    const blockchainOfferId = assetData?.offerDetails?.blockchainOfferId;

    switch (assetData?.investment_type_id) {
        case INVESTMENT_TYPE_ID.LOAN:
            return {
                ...baseArgs,
                address: CONTRACTS.WATCH_DEBT_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.WATCH_DEBT_OFFER_ADDRESS],
                args: [
                    listingId,
                    blockchainOfferId,
                    amount,
                    token_address,
                    Math.round(offeredPercentage * 100), // offer rate of interest / offeredPercentage
                    Number(offeredDuration), // offer loan duration / offeredDuration
                ],
            };
        case INVESTMENT_TYPE_ID.SALE:
            return {
                ...baseArgs,
                address: CONTRACTS.ART_SALE_MARKETPLACE,
                abi: CONTRACT_ABI[CONTRACTS.ART_SALE_MARKETPLACE],
                args: [
                    listingId,
                    blockchainOfferId,
                    convertToDecimal(offerAmount), // offer amount / offeredAmount
                ],
            };
        case (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE &&
              (assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ||
               assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT)):
            return {
                ...baseArgs,
                address: CONTRACTS.SPV_DEBT_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.SPV_DEBT_OFFER_ADDRESS],
                args: [
                    listingId,
                    assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1, // debtTypeId
                    blockchainOfferId,
                    convertToDecimal(offerAmount), // offer amount / offeredAmount
                    Math.round(offeredPercentage * 100), // offer percentage / percentage
                    Number(offeredDuration), // offer duration / duration
                ],
            };
        case (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE &&
              assetData?.offerDetails?.capital_type_id === CAPITAL_TYPE_ID.EQUITY):
            return {
                ...baseArgs,
                address: CONTRACTS.SPV_EQUITY_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.SPV_EQUITY_OFFER_ADDRESS],
                args: [
                    listingId,
                    blockchainOfferId,
                    convertToDecimal(offerAmount), // offer amount / offeredAmount
                    Math.round(assetData?.editedData[1]?.value * 100),
                ],
            };
        default:
            throw new Error("Invalid investment type or category");
    }
}

export { changingOffer };
