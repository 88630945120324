import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Box, Grid, Typography, Button, Avatar, Tooltip, List, ListItem, Checkbox, ListItemText, IconButton, Modal, Switch, CircularProgress, Backdrop } from "@mui/material";
import { KeyboardArrowRight, Cancel, Upload, RemoveRedEye } from '@mui/icons-material';

// Import custom components for this page
import { CircleCheckIcon, NewLogo } from '../Components/Images/Images'
import LinearProgressCompo from "../Pages/ProjectProposer/Questionnaire/Components/LinearProgressCompo";
import { GetApi, deleteApi, postApi, updateApi } from '../Api/Api';
import "../Pages/ProjectProposer/Questionnaire/Components/Questionnaire.css"
import { CATEGORY_TYPE_ID } from "../constants";

export default function UploadAgreementDocuments(props) {

    const location = useLocation();
    const stateData = location.state;

    const navigate = useNavigate();

    // Initialize hooks with default values for managing uploaded, deleted, and file documents
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [toggleState, setToggleState] = useState(false);
    const [view, setView] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [file, setFile] = useState("");
    const [type, setType] = useState("");
    const [defaultSigning, setDefaultSigning] = useState('');
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [viewedTemplates, setViewedTemplates] = useState([]);
    const [loading, setLoading] = useState(false)

    // const localData =
    const localData = localStorage.getItem("user_data")
        ? JSON.parse(localStorage.getItem("user_data"))
        : ""; //Getting  user data from local storage

    // Fetching all asset categories from server
    useEffect(() => {
        async function fetchTemplates() {
            try {
                if (stateData.investment_type_id && stateData.category_id) {
                    const res = await GetApi(`/docusign/getDefaultTemplates?investment_type_id=${stateData.investment_type_id}&category_id=${stateData.category_id}`);
                    if (res?.data?.data) {
                        setDefaultSigning(res?.data?.data);
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchTemplates();

        setUploadedDocuments(stateData?.asset_signing_document ? stateData?.asset_signing_document : [])
        setSelectedTemplates(stateData?.selected_templates ? stateData?.selected_templates : [])
        setToggleState(stateData?.asset_signing_document ? stateData?.asset_signing_document.length > 0 : false)
    }, []);

    /**
     * Function to handle changes in toggle button
     */
    const handleToggleChange = () => {
        setToggleState(!toggleState);
    };

    /**
     * Function to handle checkbox change
     * @param {Object} templateData
     */
    const handleCheckboxChange = (templateData) => {
        const isSelected = selectedTemplates.some((template) => template.templateId === templateData.templateId);

        if (isSelected) {
            setSelectedTemplates((prevSelection) =>
                prevSelection.filter((template) => template.templateId !== templateData.templateId)
            );
        } else {
            setSelectedTemplates((prevSelection) => [...prevSelection, { name: templateData.name, templateId: templateData.templateId, status: 0, document_id: null }]);
        }
    };
    /**
     * Function to handle uploaded documents. Filters only PDF files and updates the 'uploadedDocuments' state.
     * @param {Array} acceptedFiles - Array of accepted files.
     */
    const onDrop = useCallback(async acceptedFiles => {
        const pdfFilesArray = acceptedFiles.filter(file => file.type === 'application/pdf');
        /**
     * Uploading documents trough looping (using promise.all)
     */
        const uploadPromises = pdfFilesArray.map(async (file, index) => {
            const uploadDocData = new FormData();
            uploadDocData.append('asset_signing_document', file);
            uploadDocData.append("asset_id", stateData.assetId);
            uploadDocData.append("user_id", localData?.id);
            uploadDocData.append('type', 'document_updated')
            try {
                return postApi(`/proposer/assetResources/`, uploadDocData);
            } catch (error) {
                console.error("Error uploading document or fetching data:", error);
            }
        });
        const results = await Promise.all(uploadPromises);
        if (results) {
            const res = await GetApi(`/proposer/getAssetDoc/${stateData.assetId}`);
            if (res.data.data) {
                let signingDoc = res?.data?.data?.asset_document;
                if (signingDoc?.length) {
                    signingDoc = signingDoc?.filter((items) => items.title === 'asset_signing_document')
                    setUploadedDocuments(signingDoc);
                } else {
                    setUploadedDocuments([])
                }
            }
        }
    }, [uploadedDocuments]);

    // useDropzone hook configuration to handle file drop
    const { getRootProps, open, getInputProps } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: {
            "application/pdf": [".pdf"],
        },
        multiple: false,
        onDrop
    });

    /**
    * Function to handle deleted documents. If in edit mode, updates the 'deletedDoc' state for existing documents.
    * @param {Object} file - The file to be deleted.
    */
    const handleDeleteImage = async (file) => {
        if (file.id) {
            const res = await deleteApi(`/proposer/assetResources/${file.id}`);
            // Filter out the deleted image from the 'uploadedDocuments' state
            if (res.data.code === 200) {
                const newUploadedImages = uploadedDocuments.filter((image) => image?.images.path !== file?.images.path);
                setUploadedDocuments(newUploadedImages);
            }
        } else {
            const newUploadedImages = uploadedDocuments.filter((image) => image.path !== file.path);
            setUploadedDocuments(newUploadedImages);
        }
    };

    /**
    * Function to handle the "Save and Exit" button click. Updates 'props' with the current state of uploaded and deleted documents, and saves the proposal data.
    */
    const SaveAndExit = async () => {
        const res = await updateApi(`/proposer/templateUpdate/${stateData.assetId}`, { template_ids: selectedTemplates });
        if (res.data.code === 200) {
            navigate(`/project-details/${stateData.assetId}`, { replace: true })
        }
    }

    /**
     * Function to back to details page
     */
    const handleBack = () => {
        navigate(`/project-details/${stateData.assetId}`, { replace: true })
    }

    // preparing to show uploaded docs
    const files = uploadedDocuments?.map((file) => (
        <Box className="showUploadedDocuments">
            <Box key={file?.images?.path} className="showUploadedDocuments-wrap">
                <Box className="fileName">
                    {file?.images?.path?.includes("proposer/uploads/") || file?.images?.path?.includes("https://race25-live-uploads.s3.eu-central-2.amazonaws.com/") || file?.images?.path?.includes("https://race25-uploads-docs.s3.eu-central-2.amazonaws.com/") || file?.images?.path?.includes('https://local-racetest-images.s3.eu-central-2.amazonaws.com/') ? file?.images?.original_name : file.name}
                </Box>
                <Cancel onClick={() => handleDeleteImage(file)} className="removeIcon" />
            </Box>
        </Box >
    ))

    /**
     * open modal to display doc content
     * @param {Array} documentdata 
     * @returns {void}
     */
    const viewDoc = async (documentdata, mode, index) => {
        setLoading(true);

        try {
            // Check if the PDF has been viewed before
            const isViewed = viewedTemplates?.filter((item) => item?.templateId === documentdata?.templateId);

            if (isViewed.length) {
                setFile(isViewed[0]?.templateUrl);
                setType('pdf');

                // PDF has been viewed before, no need to fetch again
                setLoading(false);
                setOpenModal(true);
                setView(true);
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/docusign/viewTemplate?templateId=${documentdata?.templateId}`);

            const templateBuffer = await response.arrayBuffer();
            const templateBlob = new Blob([templateBuffer], { type: 'application/pdf' });
            const templateUrl = URL.createObjectURL(templateBlob);
            setFile(templateUrl);
            setType('pdf');

            // Mark the template as viewed and store in local state
            setViewedTemplates(prevViewedTemplates => [
                ...prevViewedTemplates,
                { templateId: documentdata.templateId, templateUrl }
            ]);

            setOpenModal(true);
            setView(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }


    };
    /**
    * Onclose function for Modal
    * @returns void
    */
    const handleClose = () => {
        setOpenModal(false);
        setView(false);
    };

    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    };

    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-left" >
                            {/* left-title */}
                            <Box className="quests-left-title">
                                <Typography component="h1" className="title">
                                    {/* Upload signing documents */}
                                    Upload Investor Signature Packet
                                </Typography >
                            </Box >
                            {/* Logo */}
                            <Box className="auth-logo" >
                                <Link to="/">
                                    <Box component="img" src={NewLogo} alt="Race logo" />
                                </Link>
                            </Box >
                        </Box >
                    </Grid >
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-right quests-right-contents-box">
                            <Box textAlign={"right"}>
                            </Box>
                            <Box className="qsn-middle-content" sx={{ pt: 3, mb: 2.5 }}>
                                <Box className='proposer-signing-documents'>
                                    <Typography color={'black'} className="font-28">Select Template</Typography>
                                    {!defaultSigning && stateData.category_id !== CATEGORY_TYPE_ID.FUNDS ? (
                                        <Box textAlign="center" my={6}>
                                            <CircularProgress />
                                            <Typography variant="body2" color="primary" mt={2}>
                                                Loading Templates...
                                            </Typography>
                                        </Box>) :
                                        (stateData.category_id === CATEGORY_TYPE_ID.FUNDS || defaultSigning?.length === 0) ?
                                            (stateData.category_id === CATEGORY_TYPE_ID.FUNDS ?
                                                null : <Typography className='text-black' mt={2}>
                                                    No Templates Available
                                                </Typography>
                                            )
                                            :
                                            <List>
                                                {defaultSigning?.map((templateData) => (
                                                    <ListItem key={templateData.templateId} disablePadding>
                                                        <Checkbox
                                                            checked={selectedTemplates.some((template) => template.templateId === templateData.templateId)}
                                                            onChange={() => handleCheckboxChange(templateData)}
                                                        />
                                                        <ListItemText primary={templateData.name} />
                                                        <IconButton
                                                            aria-label="View"
                                                            size="large"
                                                            onClick={() => viewDoc(templateData)}
                                                            style={{ marginRight: '10px' }}
                                                        >
                                                            <RemoveRedEye />
                                                        </IconButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                    }
                                </Box>
                                {stateData.category_id === CATEGORY_TYPE_ID.FUNDS ?
                                    <>
                                        {/* <Box>
                                            <Typography className="text-black font-18" textAlign={'center'}>OR</Typography>
                                        </Box> */}
                                        <Box mb={toggleState ? 1.5 : 4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
                                            {stateData.category_id === CATEGORY_TYPE_ID.FUNDS ?
                                                <Typography className="text-black font-18">
                                                    Please upload the investment package for the project (if the documents are not prepared yet you can proceed to next step and upload it during the Decentralized Investment Committee review stage)
                                                </Typography> :
                                                <Typography className="text-black font-22">
                                                    Upload signing documents for custom templates
                                                </Typography>}
                                            <Switch
                                                checked={toggleState}
                                                onChange={handleToggleChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </Box>
                                    </> : null}
                                {toggleState &&
                                    <>
                                        <Box mb={4} mt={2} className="Upload-documents">
                                            <Box className='upload-box' sx={{
                                                background: "#F1F5F9",
                                                borderRadius: "10px"
                                            }}>
                                                <Box  {...getRootProps({
                                                    className: "dropzone",
                                                })}>
                                                    <input {...getInputProps()} />
                                                    <Box className='upload-box-box-ts'>
                                                        <Box sx={{ padding: '15px 0px' }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Box>
                                                                    <Upload className='upload-box-box-icon' />
                                                                </Box>
                                                                <Box className=''>
                                                                    <Box className='upload-box-box-title'>Drag File here to upload</Box>
                                                                    <Box className='upload-box-box-subtitle'>or click the button to browse</Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ marginRight: '10px' }}>
                                                            <Button className='btn-rounded btn-blueGray-200' onClick={open} startIcon={<Upload />}>
                                                                Browse Files
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box className='upload-box-box-fm'>
                                                    {/* <Box className='upload-box-box-doc'>Upload Required Documents</Box> */}
                                                </Box>
                                                {files !== "" ? (
                                                    <Box
                                                        sx={{
                                                            paddingBottom: "16px",
                                                            overflow: "hidden",
                                                            margin: "10px 16px 10px 16px"
                                                        }}
                                                    >
                                                        {files?.map((fileName, ind) => {
                                                            return (<Box display={"flex"} alignItems={"center"} mt={1} key={ind}>
                                                                <Avatar
                                                                    src={CircleCheckIcon}
                                                                    alt="Icon"
                                                                    sx={{ width: 24, height: 24 }}
                                                                />
                                                                <Box
                                                                    className="dD-file-name text-black"
                                                                    ml={1}
                                                                >
                                                                    {fileName}
                                                                </Box>
                                                            </Box>
                                                            )
                                                        })
                                                        }
                                                    </Box>
                                                ) : (
                                                    ""
                                                )}
                                            </Box>
                                        </Box >
                                    </>
                                }
                                {/* <Box className='text-black font-16'>
                                    *You can select templates or upload the documents for custom templates.
                                </Box> */}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                                <Box textAlign={"left"} className='quests6-btn-box'>
                                    <Button className='btn-rounded back-btn' onClick={handleBack}>
                                        Back
                                    </Button>
                                </Box>
                                <Box textAlign={"right"} className='quests-btn-box'>
                                    <Button onClick={SaveAndExit} className='btn-rounded btn-text-white btn-blue-600'>
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box >
                    </Grid >
                </Grid >
            </Box >

            {/* modal code */}
            <Grid>
                {
                    loading &&
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="primary" />
                    </Backdrop>
                }
                {(view && !loading && type && file) && (
                    <Modal
                        className="projectDocumentsModal"
                        open={openModal}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        fullWidth={true}
                    >
                        <Box sx={styleModal}>
                            {/* <iframe
                title="PDF Viewer"
                src={file}
                style={{ width: "100%", height: "100%" }}
                className="pdf-viewer-modal"
              ></iframe> */}
                            <object
                                data={file}
                                type="application/pdf"
                                style={{ width: '100%', height: '100%', borderRadius: '12px' }}
                            >
                                <p style={{ textAlign: 'center' }}>
                                    Your browser does not support PDFs. <a href={file} target="_blank" style={{ color: 'lightgreen' }}>Download the PDF</a>.
                                </p>
                            </object>
                        </Box>
                    </Modal>
                )}
            </Grid>
        </Box >
    );
}




