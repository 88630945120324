import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';

const HomeAboutRace = () => {
    const raceContent = [
        {
            text: "RACE™️ is a full stack, EVM-compatible layer two protocol",
        },
        {
            text: "Allows vetted assets-such as trade finance, private equity, ESG, real estate, and fine art-to be tokenized and listed",
        },
        {
            text: "Features interoperable non-custodial wallets and decentralized investment committees",
        },
        {
            text: "⁠Aims to integrate financially savvy, non-crypto-native investors",
        },
        {
            text: "Governed by users and a decentralized investment committee",
        },
        {
            text: "RACE™ designed as a global tokenized infrastructure and decentralized Real World Asset (RWA) marketplace",
        },
        {
            text: "⁠Assets are digitized through the RACE™️ ATB layer, transforming them into financial instruments",
        },
        {
            text: "Provides access to assets previously inaccessible to many global investors",
        }
    ];

    return (
        <div>
            <Typography className='font-45 about-race-home'>
                About RACE
            </Typography>
            <Box className="about-animation-main">
                <Grid container spacing={3}>
                    {raceContent.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Box className="about-animation-wrapper">
                                <Typography className="about-animation-text">
                                    {item.text}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
    );

};

export default HomeAboutRace;