// completeVote.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";

/**
 * Call the voteOnProposal method for DIC to complete vote
 * @param {String} address user wallet address
 * @param {Number} proposalId asset's blockchain id
 * @param {Boolean} dicVoteType approve or reject
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function completeVote(address, proposalId, dicVoteType, showSnackbar, handleActiveStep, handleModalClose, handleSuccess, assetData) {
    try {
        let contract_address = CONTRACTS.DIC_PROPOSAL_LOAN;
        if (assetData?.category_id === CATEGORY_TYPE_ID.ART && assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            contract_address = CONTRACTS.DIC_PROPOSAL_SALE
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.ART && assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
            contract_address = CONTRACTS.DIC_PROPOSAL_PARCEL
        } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            contract_address = CONTRACTS.DIC_PROPOSAL_LOAN
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
            contract_address = CONTRACTS.DIC_PROPOSAL_CLOSE_FUND
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
            contract_address = CONTRACTS.DIC_PROPOSAL_OPEN_FUND
        }
        console.log(contract_address);
        const { request: voteOnProposal } = await prepareWriteContract({
            address: contract_address,
            abi: CONTRACT_ABI[contract_address],
            account: address,
            chainId: 6806,
            functionName: "voteOnProposal",
            args: [
                proposalId, // asset's blockchain id
                dicVoteType // dic approve or reject status
            ]
        })

        const { hash } = await writeContract(voteOnProposal);

        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                handleActiveStep(2);
                showSnackbar("Transaction Successful", 'success')
                setTimeout(() => {
                    handleSuccess()
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error fetching balance:', error);
        throw error; // propagate the error
    }
}

export { completeVote };