import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  FormControl,
  ImageListItem,
  InputLabel,
  ListItemText,
  Menu,
  Select,
  Typography,
} from "@mui/material";
import { GetApiParam } from "../../Api/Api";
import { MenuItem } from "@mui/material";
import { GetApi } from "../../Api/Api";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import Stack from "@mui/material/Stack";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import BarChartIcon from "@mui/icons-material/BarChart";
import "./Project.css";
export default function Filter({
  FilteredData,
  defaultFilter,
  defaultSearchString,
  defaultSortBy,
  onSortChange,
  sortByOptions,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [suggestions, setSuggestions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const searchBarRef = useRef(null); //search bar
  const [filterValues, setFilterValues] = useState({
    category_id: 0,
    investment_type_id: 0,
    investment_val: null,
  });
  const [sortBy, setSortBy] = useState("Default");
  const [categoryData, setCategoryData] = useState(); //category data
  const [investmentTypeData, setInvestmenTypeData] = useState([]); //investment data
  const [investVal, setInvestVal] = useState([]); //min investment

  const [anchorElSort, setAnchorElSort] = React.useState(null); //sort
  const open = Boolean(anchorElSort);

  var localData = JSON.parse(localStorage.getItem("user_data_dic"));

  useEffect(() => {
    getCategoryData();
    getInvestMentTypeData();
  }, []);

  // Category filter ---------------------

  /**
   * Api to get Category Type Data
   */
  async function getCategoryData() {
    try {
      const res = await GetApi("/proposer/getCategory/");
      setCategoryData(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleChangeCategory = (event) => {
    setFilterValues({
      ...filterValues,
      category_id: event.target.value,
      committee_member_id: localData.id,
    });
  };

  // Investment Type Filter --------------

  const handleChangeInvestmentType = (event) => {
    setFilterValues({
      ...filterValues,
      investment_type_id: event.target.value,
      committee_member_id: localData.id,
    });
  };

  /**
   * Api to get investment Type Data
   */
  async function getInvestMentTypeData() {
    try {
      const res = await GetApi("/proposer/investmentType/");
      setInvestmenTypeData(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }

  // Sorting -----------------------------

  const handleSortChange = (value) => {
    // if page is search then filter the data
    setSortBy(value);
  };

  const handleClick = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElSort(null);
  };

  const firstRenderRef = useRef(true);

  // Setting filteration acccording to input
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    // setting payload for filteration
    let filterDataPayload = { ...filterValues };
    if (filterDataPayload.category_id === 0)
      delete filterDataPayload.category_id;
    if (filterDataPayload.investment_type_id === 0)
      delete filterDataPayload.investment_type_id;
    if (filterDataPayload.investment_val === null)
      delete filterDataPayload.investment_val;

    //  filter the data
    getFilterData(filterDataPayload);
  }, [filterValues]);

  /**
   *
   * @param {*} filterPayload payload for API filteration  empty payload will return all data
   * calls FilteredData to set data in parent component
   *
   */
  const getFilterData = async (filterPayload) => {
    try {
      const res = await GetApiParam(
        "/committee/getAssignedAssets",
        filterPayload
      );

      if (res?.data?.data) {
        FilteredData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box>
        <Stack
          justifyContent={"space-between"}
          spacing={2}
          sx={{ flexDirection: { xs: "column", sm: "column", md: "row" } }}
        >
          <Typography variant="h4" className="completed-project-text">
            Completed Projects
          </Typography>
          <Stack sx={{ flexDirection: { xs: "column", sm: "row", md: "row" } }}>
            <Box
              className="select-group"
              ml={{ xs: 0, sm: 1.5, md: 1.5 }}
              sx={{ marginTop: { xs: "20px", sm: "10px" } }}
            >
              <FormControl
                className="select-rounded"
                sx={{ minWidth: "150px" }}
                size="small"
              >
                <InputLabel id="demo-controlled-open-select-label">
                  Category
                  <ViewComfyIcon />
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  value={filterValues.category_id}
                  onChange={handleChangeCategory}
                  label="Category"
                  variant="outlined"
                  className="filter-btn"
                >
                  <MenuItem value={0}>All</MenuItem>
                  {categoryData?.map((value, ind) => {
                    return (
                      <MenuItem key={ind} value={value.id} className="filter-btn">
                        <ListItemText>{value.title === "Asset" ? "Luxury Cars" : value.title}</ListItemText>
                        <Typography className="menu-icon">
                          <ImageListItem>
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                              srcSet={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                              alt={value.category_icon?.original_name}
                              loading="lazy"
                            />
                          </ImageListItem>
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              className="select-group"
              ml={{ xs: 0, sm: 1.5, md: 1.5 }}
              sx={{ marginTop: { xs: "15px", sm: "10px" } }}
            >
              <FormControl
                className="select-rounded"
                sx={{ minWidth: 190 }}
                size="small"
              >
                <InputLabel id="demo-controlled-open-select-label">
                  Investment Type
                  <BarChartIcon />
                </InputLabel>

                <Select
                  labelId="demo-controlled-open-select-label"
                  value={filterValues.investment_type_id}
                  label="Investment Type"
                  onChange={handleChangeInvestmentType}
                >
                  <MenuItem value={0}>All</MenuItem>
                  {investmentTypeData?.map((value, ind) => {
                    return (
                      <MenuItem key={ind} value={value.id}>
                        {value.investment_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              className="dropdown-menu-wrap"
              ml={{ xs: 0, sm: 1.5, md: 1.5 }}
              sx={{ marginTop: { xs: "10px", sm: "10px" } }}
            >
              <Button
                endIcon={<SwapVertIcon />}
                // onClick={() => sortByName()}
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="btn-rounded btn-outline"
                sx={{ width: { xs: "100%" } }}
              >
                Sort By: {sortBy}
              </Button>
              <Menu
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorEl={anchorElSort}
                open={open}
                onClose={handleClose}
                className="dropdown-menu filterCategory-menu"
              >
                {sortByOptions.map((value, ind) => {
                  return (
                    <MenuItem
                      key={ind}
                      selected={sortBy === value ? true : false}
                      onClick={() => {
                        handleSortChange(value);
                        setAnchorElSort(null); // done seperately to close the menu after click
                      }}
                    >
                      {value}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

Filter.propTypes = {
  FilteredData: PropTypes.func,
  defaultFilter: PropTypes.object,
  defaultSearchString: PropTypes.string,
  defaultSortBy: PropTypes.string,
  onSortChange: PropTypes.func,
  sortByOptions: PropTypes.arrayOf(PropTypes.string),
};

Filter.defaultProps = {
  sortByOptions: ["Name"],
};
