import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Box, Grid, Typography, Button, Avatar, Tooltip, List, ListItem, Checkbox, ListItemText, IconButton, Modal, Switch, CircularProgress, Backdrop } from "@mui/material";
import { KeyboardArrowRight, Cancel, Upload, RemoveRedEye } from '@mui/icons-material';

// Import custom components for this page
import { CircleCheckIcon, NewLogo } from '../../../Components/Images/Images'
import LinearProgressCompo from "./Components/LinearProgressCompo";
import { GetApi } from "../../../Api/Api";
import "./Components/Questionnaire.css"

export default function UploadSigningDocuments(props) {
  // Destructuring props to get functions and data required for the component
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress, EditMode } = props;

  // Retrieve current proposal data and progress from the parent component
  var propData = getProposalData();
  let currentProgress = getProgress();

  // Initialize hooks with default values for managing uploaded, deleted, and file documents
  const [uploadedDocuments, setUploadedDocuments] = useState(propData?.asset_signing_document ? propData?.asset_signing_document : []);
  const [deletedDoc, setDeletedDoc] = useState(propData?.asset_signing_document_ids ? propData?.asset_signing_document_ids : []);
  const [docFiles, setDocFiles] = useState(propData?.asset_signing_document_file ? propData?.asset_signing_document_file : []);
  const [toggleState, setToggleState] = useState(propData?.asset_signing_document ? propData?.asset_signing_document.length > 0 : false);
  const [view, setView] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [defaultSigning, setDefaultSigning] = useState('');
  const [selectedTemplates, setSelectedTemplates] = useState(propData?.selected_templates ? propData?.selected_templates : []);
  const [viewedTemplates, setViewedTemplates] = useState([]);
  const [loading, setLoading] = useState(false)

  // Fetching all asset categories from server
  useEffect(() => {
    async function fetchTemplates() {
      try {
        if (propData.investment_type_id) {
          const res = await GetApi(`/docusign/getDefaultTemplates?investment_type_id=${propData.investment_type_id}&category_id=${propData.category_id}`);
          if (res?.data?.data) {
            setDefaultSigning(res?.data?.data);
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchTemplates();
  }, []);

  /**
   * Function to handle changes in toggle button
   */
  const handleToggleChange = () => {
    setToggleState(!toggleState);
  };

  /**
   * Function to handle checkbox change
   * @param {Object} templateData
   */
  const handleCheckboxChange = (templateData) => {
    const isSelected = selectedTemplates.some((template) => template.templateId === templateData.templateId);

    if (isSelected) {
      setSelectedTemplates((prevSelection) =>
        prevSelection.filter((template) => template.templateId !== templateData.templateId)
      );
    } else {
      setSelectedTemplates((prevSelection) => [...prevSelection, { name: templateData.name, templateId: templateData.templateId, status: 0, document_id: null }]);
    }
  };
  /**
   * Function to handle uploaded documents. Filters only PDF files and updates the 'uploadedDocuments' state.
   * @param {Array} acceptedFiles - Array of accepted files.
   */
  const onDrop = useCallback(acceptedFiles => {
    const pdfFilesArray = acceptedFiles.filter(file => file.type === 'application/pdf');
    // let dataDetail = [...uploadedDocuments, ...pdfFilesArray];
    setUploadedDocuments(pdfFilesArray);
  }, [uploadedDocuments]);

  // useDropzone hook configuration to handle file drop
  const { getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [".pdf"],
    },
    multiple: false,
    onDrop
  });

  /**
  * Function to handle deleted documents. If in edit mode, updates the 'deletedDoc' state for existing documents, and updates 'docFiles'.
  * @param {Object} file - The file to be deleted.
  */
  const handleDeleteImage = (file) => {
    if (EditMode && file) {
      const newDocFile = docFiles.filter((docFile) => {
        setDeletedDoc([...deletedDoc, docFile.id]);
        return docFile.image !== file;
      });
      setDocFiles(newDocFile);
    }

    // Filter out the deleted image from the 'uploadedDocuments' state
    const newUploadedImages = uploadedDocuments.filter((image) => image.path !== file.path);
    setUploadedDocuments(newUploadedImages);
  };

  /**
   * Function to handle the "Next" button click. Updates 'propData' with the current state of uploaded and deleted documents, and proceeds to the next step.
   */
  const handleClick = () => {
    // if (uploadedDocuments?.length && toggleState) {
      propData.asset_signing_document = uploadedDocuments;
    // }
    propData.selected_templates = selectedTemplates;
    if (EditMode) {
      propData.asset_signing_document_ids = deletedDoc;
    }

    // Update the main page and proceed to the next step
    updateProposalData(propData);
    handleNext();
  }

  /**
  * Function to handle the "Save and Exit" button click. Updates 'propData' with the current state of uploaded and deleted documents, and saves the proposal data.
  */
  const SaveAndExit = () => {
    // Update 'propData' with the current state of uploaded and deleted documents
    // if (uploadedDocuments?.length && toggleState) {
      propData.asset_signing_document = uploadedDocuments;
    // }
    propData.selected_templates = selectedTemplates;
    if (EditMode) {
      propData.asset_signing_document_ids = deletedDoc;
    }

    // Update the main page and save the proposal data
    updateProposalData(propData);
    saveProposal();
  }

  /**
  * Function to handle the "Back" button click. Updates 'propData' with the current state of uploaded documents and goes back to the previous step.
  */
  const handleBack = () => {
    // Update 'propData' with the current state of uploaded documents
    propData.asset_signing_document = uploadedDocuments;

    // Update the main page and go back to the previous step
    updateProposalData(propData);
    handleback();
  }

  // preparing to show uploaded docs
  const files = uploadedDocuments?.map((file) => (
    <Box className="showUploadedDocuments">
      <Box key={file.path} className="showUploadedDocuments-wrap">
        <Box className="fileName">
          {file?.path?.includes("proposer/uploads/") || file?.path?.includes("https://race25-live-uploads.s3.eu-central-2.amazonaws.com/") || file?.path?.includes("https://race25-uploads-docs.s3.eu-central-2.amazonaws.com/") || file?.path?.includes('https://local-racetest-images.s3.eu-central-2.amazonaws.com/') ? file.original_name : file.name}
        </Box>
        <Cancel onClick={() => handleDeleteImage(file)} className="removeIcon" />
      </Box>
    </Box >
  ))

  /**
   * open modal to display doc content
   * @param {Array} documentdata 
   * @returns {void}
   */
  const viewDoc = async (documentdata, mode, index) => {
    setLoading(true);

    try {
      // Check if the PDF has been viewed before
      const isViewed = viewedTemplates?.filter((item) => item?.templateId === documentdata?.templateId);

      if (isViewed.length) {
        setFile(isViewed[0]?.templateUrl);
        setType('pdf');

        // PDF has been viewed before, no need to fetch again
        setLoading(false);
        setOpenModal(true);
        setView(true);
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/docusign/viewTemplate?templateId=${documentdata?.templateId}`);

      const templateBuffer = await response.arrayBuffer();
      const templateBlob = new Blob([templateBuffer], { type: 'application/pdf' });
      const templateUrl = URL.createObjectURL(templateBlob);
      setFile(templateUrl);
      setType('pdf');

      // Mark the template as viewed and store in local state
      setViewedTemplates(prevViewedTemplates => [
        ...prevViewedTemplates,
        { templateId: documentdata.templateId, templateUrl }
      ]);

      setOpenModal(true);
      setView(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }


  };
  /**
  * Onclose function for Modal
  * @returns void
  */
  const handleClose = () => {
    setOpenModal(false);
    setView(false);
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" >
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Upload Investor Signature Packet
                </Typography >
              </Box>
              <Box className="auth-logo" >
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box >
            </Box >
          </Grid >
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  <Button onClick={SaveAndExit} disabled={(propData?.selectedCategory == 'funds' ? true : ((uploadedDocuments.length) || (selectedTemplates.length))) ? false : true} className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                <Box className='proposer-signing-documents'>
                  <Typography color={'black'} className="font-28"> Select Template </Typography>
                  {!defaultSigning && propData?.selectedCategory != 'funds' ? (
                    <Box textAlign="center" my={6}>
                      <CircularProgress />
                      <Typography variant="body2" color="primary" mt={2}>
                        Loading Templates...
                      </Typography>
                    </Box>) :
                    (propData?.selectedCategory == 'funds' || propData?.selectedCategory == 'funds' && defaultSigning?.length === 0) ?
                      (propData?.selectedCategory == 'funds' ? null :
                        <Typography className='text-black' mt={2}>
                          No Templates Available
                        </Typography>)
                      :
                      <List>
                        {defaultSigning?.map((templateData) => (
                          <ListItem key={templateData.templateId} disablePadding>
                            <Checkbox
                              checked={selectedTemplates.some((template) => template.templateId === templateData.templateId)}
                              onChange={() => handleCheckboxChange(templateData)}
                            />
                            <ListItemText primary={templateData.name} />
                            <IconButton
                              aria-label="View"
                              size="large"
                              onClick={() => viewDoc(templateData)}
                              style={{ marginRight: '10px' }}
                            >
                              <RemoveRedEye />
                            </IconButton>
                          </ListItem>
                        ))}
                      </List>
                  }
                </Box>
                {propData?.selectedCategory == 'funds' ?
                  <>
                    {/* <Box>
                      <Typography className="text-black font-18" textAlign={'center'}>OR</Typography>
                    </Box> */}
                    <Box mb={toggleState ? 1.5 : 4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
                      {propData?.selectedCategory == 'funds' ?
                        <Typography className="text-black font-18">
                          Please upload the investment package for the project (if the documents are not prepared yet you can proceed to next step and upload it during the Decentralized Investment Committee review stage)
                        </Typography> :
                        <Typography className="text-black font-22">
                          Upload signing documents for custom templates
                        </Typography>}

                      <Switch
                        checked={toggleState}
                        onChange={handleToggleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </Box>
                  </>
                  : null
                }
                {toggleState &&
                  <>
                    <Box mb={4} mt={2} className="Upload-documents">
                      <Box className='upload-box' sx={{
                        background: "#F1F5F9",
                        borderRadius: "10px"
                      }}>
                        <Box  {...getRootProps({
                          className: "dropzone",
                        })}>
                          <input {...getInputProps()} />
                          <Box className='upload-box-box-ts'>
                            <Box sx={{ padding: '15px 0px' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box>
                                  <Upload className='upload-box-box-icon' />
                                </Box>
                                <Box className=''>
                                  <Box className='upload-box-box-title'>Drag File here to upload</Box>
                                  <Box className='upload-box-box-subtitle'>or click the button to browse</Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box sx={{ marginRight: '10px' }}>
                              <Button className='btn-rounded btn-blueGray-200' onClick={open} startIcon={<Upload />}>
                                Browse Files
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                        <Box className='upload-box-box-fm'>
                          {/* <Box className='upload-box-box-doc'>Upload Required Documents</Box> */}
                          {/* <Box className='upload-box-box-type'>
                        You need to upload or check at least 1 document to submit the project.
                      </Box> */}
                        </Box>
                        {files !== "" ? (
                          <Box
                            sx={{
                              paddingBottom: "16px",
                              overflow: "hidden",
                              margin: "10px 16px 10px 16px"
                            }}
                          >
                            {files?.map((fileName, ind) => {
                              return (<Box display={"flex"} alignItems={"center"} mt={1} key={ind}>
                                <Avatar
                                  src={CircleCheckIcon}
                                  alt="Icon"
                                  sx={{ width: 24, height: 24 }}
                                />
                                <Box
                                  className="dD-file-name text-black"
                                  ml={1}
                                >
                                  {fileName}
                                </Box>
                              </Box>
                              )
                            })
                            }
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box >
                  </>
                }
                {/* <Box className='text-black font-16'>
                  *You can select templates or upload the documents for custom templates.
                </Box> */}
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                  <Box textAlign={"left"} className='quests6-btn-box'>
                    <Button className='btn-rounded back-btn' onClick={handleBack}>
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"} className='quests-btn-box'>
                    {/* <Tooltip title={((uploadedDocuments.length) || (selectedTemplates.length)) ? "" : "You need to select a template to proceed."} className="p-flow-next-btn-tooltip"> */}
                    <span>
                      <Button disabled={(propData?.selectedCategory == 'funds' ? true : ((uploadedDocuments.length) || (selectedTemplates.length))) ? false : true} onClick={handleClick} endIcon={<KeyboardArrowRight />} className='btn-rounded btn-text-white btn-blue-600'>
                        Next
                      </Button>
                    </span>
                    {/* </Tooltip> */}
                  </Box>
                </Box>
              </Box >
            </Box >
          </Grid >
        </Grid >
      </Box >

      {/* modal code */}
      <Grid>
        {
          loading &&
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="primary" />
          </Backdrop>
        }
        {(view && !loading && type && file) && (
          <Modal
            className="projectDocumentsModal"
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
          >
            <Box sx={styleModal}>
              {/* <iframe
                title="PDF Viewer"
                src={file}
                style={{ width: "100%", height: "100%" }}
                className="pdf-viewer-modal"
              ></iframe> */}
              <object
                data={file}
                type="application/pdf"
                style={{ width: '100%', height: '100%', borderRadius: '12px' }}
              >
                <p style={{ textAlign: 'center' }}>
                  Your browser does not support PDFs. <a href={file} target="_blank" style={{ color: 'lightgreen' }}>Download the PDF</a>.
                </p>
              </object>
            </Box>
          </Modal>
        )}
      </Grid>
    </Box >
  );
}




