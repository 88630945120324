import React from 'react';
import { Typography, Box } from "@mui/material";
import "./SliderCard.css";

const SliderCard = (props) => {
    return (
        <>
            <Box className="hc-thumbnail" sx={{ cursor: props?.from === "SubCategory" ? "default" : 'pointer' }}>
                <Box className="hc-img-wrap" >
                    <Box className="hc-img" sx={{ backgroundImage: `url(${props.image})` }}></Box>
                </Box>
                <Box className="hc-content" px={1.8} pt={{ xs: 0.5, sm: 1 }}>
                    <Typography className="hc-title" sx={{ color: props?.color }} >{props.title === "Asset" ? "Luxury Cars" :props.title }</Typography>
                </Box>
            </Box>
        </>
    )
}
export default SliderCard