import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useGoogleLogin } from "@react-oauth/google";
import LoadingButton from '@mui/lab/LoadingButton';
import { InputAdornment, IconButton, Box, Grid, Typography, TextField, FormControlLabel, Checkbox, Button, Stack, Avatar } from "@mui/material";
import { Visibility, VisibilityOff, ArrowForward, ArrowBack } from '@mui/icons-material';
import { postApi, googleUserInfo } from "../../Api/Api";
import { LogoInvestor, googleColorFullIcon } from "../../Components/Images/Images";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { useSocket } from "../../Contexts/SocketContext";
import { decryptStringData, encryptData } from "../../Components/Common/Utils";
import useAuth from "../../Contexts/useAuth";
import Cookies from "js-cookie";

// let history = window.history;
let getUrl = process.env.REACT_APP_ACCESS_TOKEN;

export default function InvestorLogin() {

    const { login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const stateVal = location.state;
    const socket = useSocket();
    const { showSnackbar } = useSnackbar();
    const [user, setUser] = useState();
    const [loading, setLoading] = useState({ email: false, google: false, wallet: false }); // loading state for login buttons of google, wallet and normal login
    const [fadeIn, setFadeIn] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        setFadeIn(true);
        const rememberedEmail = Cookies.get("rememberedEmail");
        const rememberedPassword = Cookies.get("rememberedPassword");
        if (rememberedEmail && rememberedPassword) {
            const rememberEmail = decryptStringData(rememberedEmail).replace(/"/g, '')
            const rememberPass = decryptStringData(rememberedPassword).replace(/"/g, '')
            setValue("email", rememberEmail);
            setValue("password", rememberPass);
            setRememberMe(true);
        } else {
            setRememberMe(false);
        }
    }, []);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    /**
     * Login form of email submit
     * @param {*} data  login form data
     */
    const onSubmit = async (data) => {

        const body = { credentials: createEncryptedCredentials(data?.email, data?.password) };

        setLoading({ ...loading, email: true })
        try {
            const response = await postApi(`/user/login/`, body);
            if (response) {
                if (response?.data?.code === 200) {
                    // dispatch(userLogin(response?.data?.data));
                    await login(response?.data?.data);
                    socket.emit("setup", response?.data?.data.id);
                    socket.emit("refresh-unread", response?.data?.data.id);

                    socket.emit("sp-setup", response?.data?.data.id);
                    socket.emit("sp-refresh-unread", response?.data?.data.id);
                    localStorage.setItem("token", response.data.data.token);
                    window.history.replaceState({}, "", "/");

                    if (rememberMe) {
                        Cookies.set("rememberedEmail", encryptData(data.email), { expires: 30 });
                        Cookies.set("rememberedPassword", encryptData(data.password), { expires: 30 });
                    } else {
                        Cookies.remove("rememberedEmail");
                        Cookies.remove("rememberedPassword");
                    }

                    // conditionally redirecting to service provider and the race website
                    if (stateVal?.from === "ServiceModule") {
                        if (stateVal?.type === "Client") {
                            navigate("/service-proposer")
                        } else {
                            navigate("/service-provider")
                        }
                    } else {
                        navigate("/", { state: { from: "/login" }, replace: true });
                    }
                } else { // something went wrong user or backend side
                    showSnackbar(response?.data?.message, "error");
                    setLoading({ ...loading, email: false });
                    if (response?.data?.code === 404) {
                        const stateVal = {
                            data: {
                                "email": response?.data?.data.email,
                                "first_name": response?.data?.data.first_name,
                                "id": response?.data?.data.id
                            }
                        }
                        navigate("/otp-verification", { state: stateVal })
                    }
                }
            }
        } catch (error) {
            setLoading({ ...loading, email: false });
        }
    };

    /**
    * Google login 
    */
    const googleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => setUser(tokenResponse),
    });

    useEffect(() => {
        let access_token = user?.access_token;
        if (access_token) {
            let headers = {
                Authorization: `Bearer ${access_token}`,
                Accept: "application/json",
            };
            googleUserInfo(getUrl, access_token, headers)
                .then((res) => {
                    setLoading({ ...loading, google: true })
                    handleGoogleLogin(res?.data, access_token)
                })
                .catch((err) => console.log(err));
        }
    }, [user]);

    /**
     * Login with Google Auth
     */
    const handleGoogleLogin = async (user_profile, google_access_token) => {
        const body = {
            first_name: user_profile?.given_name,
            last_name: user_profile?.family_name,
            email: user_profile?.email,
            google_id: user_profile?.id,
            login_type: "google",
            email_verified: user_profile?.verified_email ? 1 : 0,
            google_access_token: google_access_token,
        };
        postApi(`/user/`, body).then(async (res) => {
            // setLoading({ ...loading, google: false })
            // dispatch(userLogin(res?.data?.data));
            if (res?.data?.data) {
                await login(res?.data?.data);
                socket.emit("setup", res?.data?.data.id);
                window.history.replaceState({}, "", "/");

                // conditionally redirecting to service provider and the race website
                if (stateVal?.from === "ServiceModule") {
                    if (stateVal?.type === "Client") {
                        navigate("/service-proposer")
                    } else {
                        navigate("/service-provider")
                    }
                } else {
                    navigate("/", { state: { from: "/login" }, replace: true });
                }
            } else {
                if (res?.data?.code === 201) {
                    showSnackbar(res?.data?.message, 'error')
                } else {
                    showSnackbar('Failed to login', 'error')
                }
            }
        });
        // google login end
    }

    /**
     * Encrypt email and password
     * @param {string} email 
     * @param {string} pass 
     * @returns {string} encrypted + concatenated credentials
     */
    const createEncryptedCredentials = (email, pass) => {
        const emailEncrypted = encryptData(email);
        const passEncrypted = encryptData(pass);
        // concatenated credentials separated by " ; " 
        const concatenated = `${emailEncrypted};${passEncrypted}`
        return concatenated;
    }

    /**
       * Function for remember Me
       * @param {*} event 
       */
    const handleRememberMeChange = (event) => {
        setRememberMe(event.target.checked);
    };

    useEffect(() => {
        function scrollTop() {
            document.documentElement.scrollTop = 0;
        }
        scrollTop()
    }, [])
    return (
        <>
            <Box className="login-page auth-page">
                <Box className="auth-section-investor">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box className="auth-left-investor">
                                <Box className="auth-logo-investor">
                                    <Link to="/">
                                        <Box component="img" src={LogoInvestor} alt="Race logo" className="logo-investor" />
                                    </Link>
                                </Box>
                                <Box className='investor'>
                                    <Typography className="title">
                                        Welcome back
                                    </Typography>
                                    <Typography className={`subtitle ${fadeIn ? 'fade-in' : ''}`} mt={'30px'}>
                                        Global Access to Previously Illiquid Assets
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box className="auth-right-investor ">
                                <Box className="signup-option">
                                    <Box className="top-option">
                                        <Box textAlign={"left"} >
                                            <Link to="/onboarding">
                                                <ArrowBack />
                                            </Link>
                                        </Box>
                                        <Box textAlign={"right"} className="alreadyAc-in">
                                            New to RACE? Sign up{" "}
                                            <Link component="a" to="/register">
                                                here
                                            </Link>{" "}
                                            <Box component="span" className="icon" ml={1}>
                                                <ArrowForward />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography className="sign-in-text">Sign in to your account</Typography>
                                    </Box>
                                    <Box className="">
                                        <Box
                                            component="form"
                                            onSubmit={handleSubmit(onSubmit)}
                                            mb={'30px'}
                                            className="investor-register-label"
                                        >
                                            <Box className="investor-input-email" mb={'22px'}>
                                                <TextField
                                                    type="email"
                                                    id="outlined-required"
                                                    label="Email Address"
                                                    name="email"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    placeholder="Enter your email address"
                                                    {...register("email", {
                                                        required: "Email address is required.",
                                                        pattern: {
                                                            value:
                                                                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                            message: "Please enter valid email address.",
                                                        },
                                                    })}
                                                    autoComplete="on"  // this line for auto-fill
                                                    error={Boolean(errors.email)}
                                                    helperText={errors.email?.message}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: '#686873',
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box className="investor-input-email" mb={'30px'}>
                                                <TextField
                                                    id="outlined-required"
                                                    label="Password"
                                                    variant="standard"
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="password"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    placeholder="Enter your password"
                                                    {...register("password", {
                                                        required: "Password is required.",
                                                    })}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" className="investor-password">
                                                                <IconButton
                                                                    aria-label="password"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    autoComplete="on"  // this line for auto-fill
                                                    error={Boolean(errors.password)}
                                                    helperText={errors.password?.message}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: '#686873',
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Stack
                                                direction={{ xs: "column", sm: "row" }}
                                                useFlexGap
                                                flexWrap="wrap"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                mb={'30px'}
                                            >
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            checked={rememberMe}
                                                            onChange={handleRememberMeChange}
                                                            style={{ color: '#fff' }} />}
                                                        label="Remember Me"
                                                        sx={{ mr: 0 }}
                                                        className="checkbox-rememberme"
                                                    />
                                                </Box>
                                                <Box className="checkbox-forget">
                                                    <Link component="a" to="/forgot-password">
                                                        Forgot Password?
                                                    </Link>
                                                </Box>
                                            </Stack>
                                            <Box className="btn-wrap">
                                                <LoadingButton
                                                    loading={loading.email}
                                                    loadingPosition="start"
                                                    startIcon={<></>}
                                                    variant="contained"
                                                    type="submit"
                                                    fullWidth
                                                    className="btn-rounded btn-large btn-blue-600 btn-text-white"
                                                >
                                                    Sign In
                                                </LoadingButton>
                                            </Box>
                                        </Box>

                                        <Box className="or-divider" mb={'30px'}>
                                            or
                                        </Box>
                                        <Box className="signup-btns" mb={'30px'}>
                                            <Button
                                                variant="contained"
                                                component="a"
                                                to="/"
                                                fullWidth
                                                className="blueLite-btn"
                                                sx={{ mr: 1.5 }}
                                                startIcon={<Avatar alt="Google Icon" src={googleColorFullIcon} sx={{ width: '24px', height: '24px' }} />}
                                                onClick={() => googleLogin()}
                                            >
                                                Sign In with Google
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}


