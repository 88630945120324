// makeOffer.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CAPITAL_TYPE_ID, CATEGORY_TYPE, CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";
import convertToDecimal from "../Common/convertToDecimal";
import getCurrencyTypeAddress from "../Common/getCurrencyTypeAddress";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Prepares the arguments for the contract function based on assetData.
 * @param {String} address 
 * @param {Number} offerAmount 
 * @param {Object} assetData 
 * @returns {Object} makeOfferArgs 
 */
function getMakeOfferArgs(address, offerAmount, assetData) {
    let makeOfferArgs = {};
    const baseArgs = {
        account: address,
        chainId: 6806,
        listingId: assetData?.listing_id,
    };

    if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
        if (assetData?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT || assetData?.capital_type_id === CAPITAL_TYPE_ID.JUNIOR_DEBT) {
            makeOfferArgs = {
                ...baseArgs,
                address: CONTRACTS.SPV_DEBT_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.SPV_DEBT_OFFER_ADDRESS],
                functionName: "investOnDebt",
                args: [
                    baseArgs.listingId,
                    assetData?.capital_type_id === CAPITAL_TYPE_ID.SENIOR_DEBT ? 0 : 1,
                    convertToDecimal(offerAmount),
                ]
            };
        } else if (assetData?.capital_type_id === CAPITAL_TYPE_ID.EQUITY) {
            makeOfferArgs = {
                ...baseArgs,
                address: CONTRACTS.SPV_EQUITY_OFFER_ADDRESS,
                abi: CONTRACT_ABI[CONTRACTS.SPV_EQUITY_OFFER_ADDRESS],
                functionName: "investOnEquity",
                args: [
                    baseArgs.listingId,
                    convertToDecimal(offerAmount),
                ]
            };
        }
    } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
        if (assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
            makeOfferArgs = assetData?.payment_method == 'fiat'
                ? {
                    ...baseArgs,
                    address: CONTRACTS.FUNDS_CLOSE_INVESTMENT,
                    abi: CONTRACT_ABI[CONTRACTS.FUNDS_CLOSE_INVESTMENT],
                    functionName: "investUsingFiat",
                    args: [
                        baseArgs.listingId,
                        convertToDecimal(offerAmount)
                    ]
                }
                : {
                    ...baseArgs,
                    address: CONTRACTS.FUNDS_CLOSE_INVESTMENT,
                    abi: CONTRACT_ABI[CONTRACTS.FUNDS_CLOSE_INVESTMENT],
                    functionName: "investOnFund",
                    args: [
                        baseArgs.listingId,
                        getCurrencyTypeAddress(assetData?.feeTokenType),
                        getCurrencyTypeDecimal(assetData?.feeTokenType, offerAmount)
                    ]
                };
        } else {
            makeOfferArgs = {
                ...baseArgs,
                address: CONTRACTS.FUNDS_OPEN_INVESTMENT,
                abi: CONTRACT_ABI[CONTRACTS.FUNDS_OPEN_INVESTMENT],
                functionName: "investOnFund",
                args: [
                    baseArgs.listingId,
                    [assetData?.class == 1 ? 0 : 1],
                    getCurrencyTypeAddress(assetData?.feeTokenType),
                    [getCurrencyTypeDecimal(assetData?.feeTokenType, offerAmount)]
                ]
            };
        }
    } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
        makeOfferArgs = {
            ...baseArgs,
            address: CONTRACTS.WATCH_DEBT_OFFER_ADDRESS,
            abi: CONTRACT_ABI[CONTRACTS.WATCH_DEBT_OFFER_ADDRESS],
            functionName: "makeOffer",
            args: [
                baseArgs.listingId,
                getCurrencyTypeDecimal(assetData?.feeTokenType, offerAmount),
                Number(Math.round(assetData?.loan_roi * 100)),
                Number(assetData?.loan_duration),
                getCurrencyTypeAddress(assetData?.feeTokenType),
            ]
        };
    } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
        makeOfferArgs = {
            ...baseArgs,
            address: CONTRACTS.ART_SALE_MARKETPLACE,
            abi: CONTRACT_ABI[CONTRACTS.ART_SALE_MARKETPLACE],
            functionName: "makeOffer",
            args: [
                baseArgs.listingId,
                getCurrencyTypeDecimal(assetData?.feeTokenType, offerAmount),
                getCurrencyTypeAddress(assetData?.feeTokenType),
                getCurrencyTypeDecimal(assetData?.feeTokenType, assetData?.depositAmount),
            ]
        };
    }
    return makeOfferArgs;
}

/**
 * Call makeOffer method to create offer on asset
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Number} stakeAmt amount to stake
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function makeOffer(address, assetData, offerAmount, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        if (assetData?.payment_method !== 'fiat') {
            handleActiveStep(2);
        }

        if (!assetData?.listing_id) {
            showSnackbar("Listing ID is required.", 'error');
            handleModalClose();
            handleActiveStep(-1);
            return;
        }

        const makeOfferArgs = getMakeOfferArgs(address, offerAmount, assetData);
        
        // make offer by investor
        const { request: makeOffer } = await prepareWriteContract(makeOfferArgs);
        const { hash } = await writeContract(makeOffer);

        if (hash) {
            const data = await waitForTransaction({ hash });
            if (data.status == "success") {
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(3);
                setTimeout(() => {
                    handleSuccess(data, assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE ? assetData?.depositAmount : offerAmount, assetData?.asset_id);
                    handleModalClose();
                    handleActiveStep(-1);
                    return { data };
                }, 1000);
            } else {
                handleModalClose();
                handleActiveStep(-1);
                showSnackbar("Transaction Failed", 'error');
                return;
            }
        }
    } catch (error) {
        console.error('Error fetching balance:', error);
        throw error; // propagate the error
    }
}

export { makeOffer };
