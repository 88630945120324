// releasedAsset.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";

/**
 * Determine the contract address based on assetData.
 * @param {Object} assetData 
 * @returns {String} contract address
 */
function getContractAddress(assetData) {
    if (assetData.category_id === CATEGORY_TYPE_ID.WATCH && assetData.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
        return CONTRACTS.WATCH_MARKETPLACE;
    } else if (assetData.category_id === CATEGORY_TYPE_ID.ART) {
        if (assetData.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            return CONTRACTS.ART_SALE_MARKETPLACE;
        } else if (assetData.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
            return CONTRACTS.ART_PARCEL_MARKETPLACE;
        }
    } else if (assetData.category_id === CATEGORY_TYPE_ID.ASSET && assetData.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
        return CONTRACTS.WATCH_MARKETPLACE;
    } else if (assetData.category_id === CATEGORY_TYPE_ID.FUNDS) {
        if (assetData.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
            return CONTRACTS.FUNDS_CLOSE_MARKETPLACE;
        } else if (assetData.asset_sub_category === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS) {
            return CONTRACTS.FUNDS_OPEN_MARKETPLACE;
        }
    }
    return ""; // Return an empty string if no contract address is found
}

/**
 * Call the withdrawalAsset method to transfer the asset to the investor and money to the proposer
 * @param {String} address user wallet address
 * @param {Object} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function releasedAsset(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        const contractAddress = getContractAddress(assetData);
        if (!contractAddress) {
            showSnackbar("Invalid contract address.", 'error');
            return;
        }

        console.log('Contract_Address', contractAddress, assetData);

        const { request: withdrawalAsset } = await prepareWriteContract({
            address: contractAddress,
            abi: CONTRACT_ABI[contractAddress],
            account: address,
            chainId: 6806,
            functionName: "releasedAsset",
            args: [
                assetData?.listing_id, // listing id 
            ]
        });

        const { hash } = await writeContract(withdrawalAsset);
        if (hash) {
            const data = await waitForTransaction({ hash });
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success');
                handleActiveStep(2);
                setTimeout(() => {
                    handleSuccess(assetData);
                    handleModalClose();
                    handleActiveStep(-1);
                    return { data };
                }, 1000);
            } else {
                handleModalClose();
                handleActiveStep(-1);
                showSnackbar("Transaction Failed", 'error');
                return;
            }
        }
    } catch (error) {
        console.error('Error in calling withdraw amount sale:', error);
        throw error; // propagate the error
    }
}

export { releasedAsset };
