// assetListing.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE, SUB_CATEGORY_TYPE_ID } from "../../constants";
import convertToDecimal from "../Common/convertToDecimal";

/**
 * Create a listing for asset 
 * @param {String} address user wallet address
 * @param {Object} assetData asset data
 * @param {Object} assetDetails asset details
 * @param {Object} parcelNFTs parcel nfts data
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @param {Number} tokenId minted token id of asset for listing
 * @returns data or error
 */
async function assetListing(address, assetData, assetDetails, parcelNFTs, showSnackbar, handleActiveStep, handleModalClose, handleSuccess, tokenId) {
    try {
        let investType = assetData?.investType;
        let category_id = assetDetails?.category_id

        // updating proposal id
        let proposalId = assetDetails?.blockchain_id;
        let listingArgs = {};
        if (investType && investType === INVESTMENT_TYPE.LOAN) {
            listingArgs = {
                address: CONTRACTS.WATCH_MARKETPLACE,
                abi: CONTRACT_ABI[CONTRACTS.WATCH_MARKETPLACE],
                account: address,
                chainId: 6806,
                functionName: "listing",
                args: [
                    proposalId,// project blockchain id
                    CONTRACTS.NFT_ADDRESS, // NFT address where asset is minted
                    Number(tokenId) // minted token id for asset
                ]
            }
        } else if (investType && investType === INVESTMENT_TYPE.SALE && category_id === CATEGORY_TYPE_ID.ART) {
            listingArgs = {
                address: CONTRACTS.ART_SALE_MARKETPLACE,
                abi: CONTRACT_ABI[CONTRACTS.ART_SALE_MARKETPLACE],
                account: address,
                chainId: 6806,
                functionName: "listing",
                args: [
                    proposalId,
                    CONTRACTS.NFT_ADDRESS, // NFT contract address
                    Number(tokenId) // minted token id for asset
                ]
            }
        } else if (investType && investType === INVESTMENT_TYPE.PARCEL && category_id === CATEGORY_TYPE_ID.ART) {
            let parcelNftName = [], parcelNFTPrice = [], parcelNFTURI = [];
            let parcelData = assetDetails?.parcelData;
            if (parcelData && parcelData?.length) {
                parcelData?.forEach((parcel) => {
                    parcelNFTPrice.push(convertToDecimal(parcel?.price))
                    parcelNftName.push(parcel?.title)
                })
            }
            if (parcelNFTs && parcelNFTs?.length) {
                parcelData?.forEach((parcel_url, index) => {
                    let parcel_cid = parcelNFTs?.find((parcel) => parcel.name.split('#')[1] == index + 1)
                    parcelNFTURI.push(parcel_cid?.document_url)
                })
            }
            listingArgs = {
                address: CONTRACTS.ART_PARCEL_MARKETPLACE,
                abi: CONTRACT_ABI[CONTRACTS.ART_PARCEL_MARKETPLACE],
                account: address,
                chainId: 6806,
                functionName: "listing",
                args: [
                    proposalId,
                    CONTRACTS.NFT_ADDRESS, // NFT contract address
                    Number(tokenId), // minted token id for asset
                    {
                        tokenName: assetDetails?.asset_title,
                        tokenSymbol: 'P',
                        parcelName: parcelNftName, // parcel name array
                        parcelURI: parcelNFTURI, // parcel nft url array
                        parcelPrice: parcelNFTPrice, // parcel price array
                    }
                ]
            }
        } else if (category_id && category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {

            listingArgs = {
                address: CONTRACTS.SPV_MARKETPLACE,
                abi: CONTRACT_ABI[CONTRACTS.SPV_MARKETPLACE],
                account: address,
                chainId: 6806,
                functionName: "listing",
                args: [
                    proposalId,
                    CONTRACTS.NFT_ADDRESS, // NFT contract address
                    Number(tokenId) // minted token id for asset
                ]
            }
        } else if (category_id && category_id === CATEGORY_TYPE_ID.FUNDS && assetDetails?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {

            listingArgs = {
                address: CONTRACTS.FUNDS_CLOSE_MARKETPLACE,
                abi: CONTRACT_ABI[CONTRACTS.FUNDS_CLOSE_MARKETPLACE],
                account: address,
                chainId: 6806,
                functionName: "listing",
                args: [
                    proposalId,
                    CONTRACTS.NFT_ADDRESS, // NFT contract address
                    Number(tokenId) // minted token id for asset
                ]
            }
        } else if (category_id && category_id === CATEGORY_TYPE_ID.FUNDS) {

            listingArgs = {
                address: CONTRACTS.FUNDS_OPEN_MARKETPLACE,
                abi: CONTRACT_ABI[CONTRACTS.FUNDS_OPEN_MARKETPLACE],
                account: address,
                chainId: 6806,
                functionName: "listing",
                args: [
                    proposalId,
                    CONTRACTS.NFT_ADDRESS, // NFT contract address
                    Number(tokenId) // minted token id for asset
                ]
            }
            console.log("listingArgs", listingArgs);
        }

        // if all ipfs data has been created then call listing contract
        const { request: listing } = await prepareWriteContract(listingArgs)

        const { hash } = await writeContract(listing)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(4)
                setTimeout(() => {
                    handleSuccess()
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error calling asset listing:', error);
        throw error; // propagate the error
    }
}

export { assetListing };